import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "services/auth";
import { addDamageFeedbackAction, getDamageDetailAction, updateDamageStatusAction } from "store/actions/damageActions";
import { CustomCalander, CustomDropDown, CustomRatings, CustomTime } from "shared/AllInputs";
import formValidation from "utils/validations";
import { getPropertyLandlordAction } from "store/actions/landlordActions";
import { reformatDateString, showFormErrors } from "utils/commonFunctions";
import { getHandymanListByServiceTypeAction } from "store/actions/handymanServiceAction";
import { addMaintenanceCommentAction, deleteMaintenanceCommentAction, getMaintenanceCommentsListAction, handleDamageAction, updateMaintenanceCommentAction } from "store/actions/maintenanceActions";
import { setMaintenanceReqSlice } from "store/slices/maintenanceSlice";
import { showToastAction } from "store/slices/commonSlice";
import { Tooltip } from "primereact/tooltip";

const MaintenanceContainer = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()
    const { profile } = useSelector((state) => state?.user)
    const [damageData, setDamageData] = useState({
        damageCause: "",
        damageType: "",
        damageDescription: "",
        images: [],
    })
    const [propertyData, setPropertyData] = useState({
        id: "",
        propertyID: "",
        landlordName: "",
        tenantsName: "",
        address: "",
        movingDate: "",
        maturityType: "",
        rent: "",
        status: "",
        damageHandle: "",
        damageFeedback: "",
        fixedImages: "",
        ratings: "",
    })
    const [landlordData, setLandlordData] = useState("")
    const [handymanList, setHandymanList] = useState([])
    const [selectedItem, setSelectedItem] = useState("")
    const [responseData, setResponseData] = useState({
        whoHandle: "",
        // cost: "",
        response: "",
        expectedDate: "",
        startTime: "",
        endTime: "",
        damageHandle: "",
    })
    const [isResponseModal, setIsResponseModal] = useState(false)
    const [allDamages, setAllDamages] = useState([])
    const [loading, setLoading] = useState(false)
    const [isAddDamage, setIsAddDamage] = useState(false)
    const [commentData, setCommentData] = useState({
        comment: "",
        rating: "",
        images: [],
    })
    const [isAddFeedback, setIsAddFeedBack] = useState(false)
    const [comments, setComments] = useState([])
    const whoHandleOpt = [
        { name: "Landlord", value: "Landlord" },
        { name: "Company", value: "Company" },
    ]

    const responseOpt = [
        { name: "Yes", value: "Yes" },
        { name: "No", value: "No" },
    ]

    useEffect(() => {
        if (!profile?.company) {
            let info = getUserInfo()
            // dispatch(setUserProfile(info))
        }
    }, [profile])

    useEffect(() => {
        // if (params?.id && profile?.company && damageReport?.id) {
        //     handleSetDamageReportData(damageReport)
        // } else if (params?.id && profile?.company && !damageReport?.id) {
        //     getDamageReportDetails(profile?.company, params?.id);
        // }

        if (params?.id && profile?.company) {
            getMaintenanceReqDetails(profile?.company, params?.id);
            getMaintenanceComments(params?.id)
        }

    }, [params?.id, profile]);

    const getMaintenanceReqDetails = () => {
        dispatch(
            getDamageDetailAction(profile?.company, params?.id, (res) => {
                dispatch(setMaintenanceReqSlice(res))

                setDamageData({
                    damageCause: res?.damage_cause,
                    damageType: { name: res?.damage_type?.name, value: res?.damage_type?.id },
                    damageDescription: res?.description,
                    images: res?.images,
                })
                setPropertyData({
                    id: res?.property?.id,
                    propertyID: res?.property?.property_id,
                    landlordName: res?.property?.landlord_name,
                    address: res?.property?.address,
                    movingDate: reformatDateString(res?.property?.contract?.moving_in_date),
                    maturityType: res?.property?.contract?.maturity_type,
                    rent: res?.property?.contract?.rent_rise_type,
                    status: res?.status,
                    damageHandle: res?.damage_handle,
                    damageFeedback: res?.damage_feedback_comment,
                    fixedImages: res?.damage_fixed_image,
                    ratings: res?.handyman_service_rating,
                })
            })
        )
    }

    const getLandlordData = () => {
        dispatch(getPropertyLandlordAction(propertyData?.id, "", (res) => {
            setLandlordData({
                email: res?.email,
                address: res?.address,
                contact: res?.phone_number,
                contacts: res?.contacts
            })
        }))
    }

    const getHandymanList = () => {
        dispatch(getHandymanListByServiceTypeAction(profile?.company, damageData?.damageType?.value, "Unblocked", (res) => {
            setHandymanList(res?.options)
        }))
    }

    const updateDamageStatus = (status) => {
        dispatch(updateDamageStatusAction(profile?.company, params?.id, status, dispatch, (res) => {
            // navigate("/real-estate/maintenance/damage/list")
        }))
    }

    const onConfirm = () => {
        if (!propertyData.damageHandle) {
            if (responseData?.whoHandle && !selectedItem) {
                dispatch(showToastAction({
                    type: "error",
                    detail: `Please select a ${responseData?.whoHandle === "Landlord" ? "contact" : "service"}.`,
                }))
            }
            if (showFormErrors(responseData, setResponseData, ["response", "expectedDate", "startTime", "endTime"]) && selectedItem) {
                setIsResponseModal(true)
            }
        } else if (showFormErrors(commentData, setCommentData, ["rating"]) && !propertyData?.damageFeedback) {
            dispatch(addDamageFeedbackAction(params?.id, commentData, setLoading, dispatch, (res) => {
                getMaintenanceReqDetails()
                setIsAddFeedBack(false)
            }))
        }
    }

    const RatingsBodyTemplate = (options) => {
        const tooltipId = `ratings-tooltip-${options?.id}`;
        return (
            <div>
                <Tooltip
                    className="tooltip"
                    target={`.${tooltipId}`}
                    content={options.rating?.average_rating || 0}
                />
                <span
                    className={`${tooltipId} cursor-pointer`}
                    data-pr-classname="tooltip"
                >
                    <span
                    // className={{
                    //     color: selectedItems.some((item) => item?.id == options?.id)
                    //         ? "text-blue-700"
                    //         : null,
                    // }}

                    >
                        <CustomRatings
                            value={options.rating?.average_rating || 0}
                            name="rating"
                            disabled
                            cancel={false}
                            ignoreLabel={true}
                            count={options.rating?.count || 0}
                        />
                    </span>
                </span>
            </div>
        );
    };

    const ServiceBodyTemplate = (options) => {
        return <span
            className={{
                color: selectedItem?.id == options?.id
                    ? "text-blue-700"
                    : null,
            }}
        >{options?.service?.name}</span>
    };

    const StatusBodyTemplate = (options) => {
        return <span
            className={{
                color: selectedItem?.id == options?.id
                    ? "text-blue-700"
                    : null,
            }}
        >
            {options?.status ? "Active" : options?.status}
        </span>
    };

    const companyColumns = [
        {
            name: "Name",
            accessor: "handyman_name",
        },
        {
            name: "Service",
            accessor: "",
            body: (options) => ServiceBodyTemplate(options),
        },
        {
            name: "Email",
            accessor: "email",
        },
        {
            name: "Work phone",
            accessor: "work_phone",
        },
        {
            name: "Status",
            accessor: "status",
            body: (options) => StatusBodyTemplate(options),
        },
        {
            name: "Rating",
            accessor: "",
            body: (options) => RatingsBodyTemplate(options),
        },
    ];

    const responseModalContent = () => {
        return (
            <>
                <CustomDropDown
                    extraClassName={"w-full"}
                    data={responseData}
                    options={responseOpt}
                    name="response"
                    onChange={handleResponseChange}
                    label="Any Response"
                    required
                />
                <CustomCalander
                    extraClassName="w-full"
                    data={responseData}
                    minDate={new Date()}
                    name="expectedDate"
                    onChange={handleResponseChange}
                    optionLabel="name"
                    className="p-column-filter"
                    // ignoreLabel
                    required
                />
                <div className="flex flex-wrap">
                    <CustomTime
                        data={responseData}
                        name="startTime"
                        onChange={handleResponseChange}
                        optionLabel="name"
                        className="p-column-filter"
                        label="Expected Time"
                        required
                        // minHour="9" 
                        // minMinute="15"
                    />
                    <CustomTime
                        data={responseData}
                        name="endTime"
                        onChange={handleResponseChange}
                        optionLabel="name"
                        className="p-column-filter"
                        label=""
                        required
                    />
                </div>
            </>
        );
    };

    const handleResponseChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, responseData);
        setResponseData((prev) => ({ ...prev, [name]: value, formErrors }));
        if (name === "whoHandle") {
            if (value === "Landlord") {
                setLandlordData("")
                getLandlordData()
            } else if (value === "Company") {
                !handymanList?.length && getHandymanList()
            }
        }
    }

    const handleResponse = () => {
        if (showFormErrors(responseData, setResponseData)) {
            dispatch(handleDamageAction(responseData, selectedItem?.id, params?.id, setLoading, dispatch, (res) => {
                setResponseData({
                    whoHandle: "",
                    // cost: "",
                    response: "",
                    expectedDate: "",
                    startTime: "",
                    endTime: "",
                })
                setIsResponseModal(false)
                getMaintenanceReqDetails()
            }))
        }
    };

    const onFixed = () => {
        if (!propertyData?.damageFeedback && !isAddFeedback) {
            setIsAddFeedBack(true)
        }
    }

    const getMaintenanceComments = (requestId) => {
        dispatch(getMaintenanceCommentsListAction(requestId, (res) => {
            setComments(res)
        }))
    }

    const addComment = (comment, loading) => {
        dispatch(addMaintenanceCommentAction(params?.id, comment, loading, dispatch, (res) => {
            const allComments = [...comments]
            allComments.push(res)
            setComments(allComments)
        }))
    }

    const updateComment = (commentId, commentIndex, comment, loading) => {
        dispatch(updateMaintenanceCommentAction(params?.id, commentId, comment, loading, dispatch, (res) => {
            const allComments = [...comments]
            allComments[commentIndex] = { ...res }
            setComments(allComments)
        }))
    }

    const deleteComment = (commentId, loading, onRes) => {
        dispatch(deleteMaintenanceCommentAction(commentId, loading, dispatch, (res) => {
            if (res.success) {
                const allComments = [...comments]
                const updatedComments = allComments.filter((item) => item.id !== commentId);
                setComments(updatedComments)
            }
            onRes(res)
        }))
    }

    return {
        damageData,
        companyColumns,
        isResponseModal,
        setIsResponseModal,
        responseModalContent,
        handleResponse,
        whoHandleOpt,
        propertyData,
        setPropertyData,
        onConfirm,
        landlordData,
        selectedItem,
        setSelectedItem,
        handymanList,
        handleResponseChange,
        responseData,
        loading,
        navigate,
        isAddDamage,
        setIsAddDamage,
        allDamages,
        setAllDamages,
        reportId: params?.id,
        updateDamageStatus,
        commentData,
        setCommentData,
        isAddFeedback,
        onFixed,
        comments,
        addComment,
        updateComment,
        deleteComment
    }
}

export default MaintenanceContainer