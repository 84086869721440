import React from "react";
import { CustomForm } from "../../shared/AllInputs";
import CommonViewTable from "./CommonViewTable";
import DocumentView from "views/CommonViewComponents/DocumentView";

const MaintenanceTable = ({ allDocuments, header, onEdit }) => {

  return (
    <>
      <CustomForm title=" " header={header}>
        {allDocuments?.length ? (
          allDocuments?.map((document, index) => {
            return (
              <>
                <DocumentView
                  document={document}
                  docIndex={index}
                  onEdit={onEdit}
                />
              </>
            );
          })
        ) : (
          <CommonViewTable title={""} list={[]} />
        )}
      </CustomForm>
    </>
  );
};

export default MaintenanceTable;
