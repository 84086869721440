import React from "react";
import {
  CustomAutoComplete,
  CustomDropDown,
  CustomForm,
  CustomInput,
  CustomPhoneInput,
} from "shared/AllInputs";
import { LandoardTypes } from "shared/AllDropDownOptions";
// import GooglePlacesInput from "shared/GooglePlacesInput";
import { useSelector } from "react-redux";
// import { CustomViewInput } from "shared/AllViewInputs";
import AddressComponent from "shared/Components/AddressComponent";

const LandlordInfo = ({
  type,
  // isDisabled,
  data,
  handleChange,
  landlordOptions,
  setLandlordAddress,
  landlordIdChange,

  // allBankAccounts,
  // setAllBankAccounts,
  // bankErrors,
  // setBankErrors,

  // allContacts,
  // setAllContacts,
  // contactErrors,
  // setContactErrors,

  // allFurniture,
  // setAllFurniture,
  // furnitureErrors,
  // setFurnitureErrors,
  isSearch,
}) => {
  const { profile } = useSelector((state) => state?.user);
  return (
    <>
      <CustomForm title="Landlord Info">
        {type === "new" ? (
          <>
            {/* <CustomAutoComplete
             data={data}
             onChange={handleChange}
             name="landlordId"
             suggestions={landlordOptions}
             required
             editable
             label="Landlord ID"
             placeholder="Landlord ID"
             search={(e) => landlordIdChange.current(e.query)}
             max={9}
           /> */}
            {data?.landlordId && (
              <CustomInput data={data} name="landlordId" required disabled />
            )}

            <CustomInput
              data={data}
              onChange={({ name, value }) => {
                handleChange({ name: name, value: value });
                landlordIdChange.current(profile?.company, value);
              }}
              name="landlordName"
              required
              isSearch={isSearch && isSearch}
              tooltipContent={
                landlordOptions?.length > 0 ? landlordOptions : ""
              }
            />
          </>
        ) : (
          <>
            <CustomAutoComplete
              field={"landlordName"}
              data={data}
              onChange={handleChange}
              name="existingLandlord"
              suggestions={landlordOptions}
              // required
              editable
              panelClassName="dropdown-option-color"
              label="Search Existing Landlord"
              placeholder="Search Landlord"
              search={(e) => landlordIdChange.current(e.query)}
              // max={9}
            />
            <CustomInput
              data={data}
              onChange={handleChange}
              name="landlordName"
              required
            />
          </>
        )}
        <CustomDropDown
          data={data}
          onChange={handleChange}
          name="landlordType"
          options={LandoardTypes}
          placeholder="Select Type"
          label={"Type"}
          required
        />
        <AddressComponent 
          id="landlord"
          data={data}
          handleChange={handleChange}
          setAddress={setLandlordAddress}
          isDisabled={false}
        />
        {/* <GooglePlacesInput
          id="landlord"
          data={data}
          onChange={handleChange}
          name="address"
          required
          setAddress={setLandlordAddress}
        />
        <CustomInput
          data={data}
          onChange={handleChange}
          name="street"
          required
        />
        <CustomInput
          data={data}
          onChange={handleChange}
          name="zipCode"
          required
        />
        <CustomInput data={data} onChange={handleChange} name="city" required />
        <CustomInput
          data={data}
          onChange={handleChange}
          name="cityDivision"
          required
        />
        <CustomInput
          data={data}
          onChange={handleChange}
          name="country"
          required
        /> */}
        <CustomInput
          data={data}
          onChange={handleChange}
          name="email"
          // required
        />
        <CustomPhoneInput
          data={data}
          onChange={handleChange}
          name="phoneNumber"
          // required
        />
      </CustomForm>
    </>
  );
};

export default React.memo(LandlordInfo);
