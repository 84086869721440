import React, { useRef, useState } from "react";
import {
  CustomAmountInput,
  CustomForm,
  CustomInput,
} from "shared/AllInputs";
import CustomButton from "shared/CustomButton";
import {
  HandleAddNew,
  handleCancelNew,
  handleChangeNew,
  handleDeleteNew,
  handlEdit,
  handleSaveNew,
} from "./commonFunctions";
import { convertAmountInGerman } from "utils/commonFunctions";
import { useDispatch } from "react-redux";
import CustomModal from "shared/Modals/CustomModal";
import CustomOverlay from "shared/CustomOverlay";
import SaveIcon from "assets/images/svg/Save-icon.svg";
import CrossIcon from "assets/images/svg/cross-icon.svg";
import {
  deleteMaintenanceCostAction,
  getMaintenanceCostDetailsAction,
} from "store/actions/AnnualStatementActions";
import EuroIcon from "assets/images/svg/euroIcon.svg"
const AddMaintenanceCosts = ({ title, allValues, setAllValues }) => {
  const dispatch = useDispatch();
  const openAction = useRef(null);
  const ignoreInputs = ["status", "id"];
  const InputObj = {
    id: "",
    cost_type: "",
    amount: "",
    status: "",
  };
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected maintenance cost from the
        list? This action cannot be undone.
      </span>
    );
  };

  const header = () => {
    const totalAmount = allValues?.reduce((total, item) => {
      return total + parseFloat(item.amount || 0);
    }, 0);
    return (
      <div className="w-12 flex justify-content-end gap-5 align-items-center flex-wrap">
        <span className="total-amount">
          Total: {convertAmountInGerman(totalAmount)}
        </span>
        <CustomButton
          type="button"
          label="Add"
          icon="pi pi-plus"
          onClick={() => HandleAddNew(InputObj, allValues, setAllValues)}
        />
      </div>
    );
  };

  const handleDeleteMaintenance = () => {
    if (idToDelete?.id) {
      dispatch(
        deleteMaintenanceCostAction(
          idToDelete?.id,
          setLoading,
          dispatch,
          (res) => {
            handleDeleteNew(allValues, setAllValues, idToDelete?.index);
            setIdToDelete({ id: "", index: "" });
            setDeleteModal(false);
          }
        )
      );
    } else {
      handleDeleteNew(allValues, setAllValues, idToDelete?.index);
      setIdToDelete({ id: "", index: "" });
      setDeleteModal(false);
    }
  };

  const getItemDetails = (id, index) => {
    dispatch(
      getMaintenanceCostDetailsAction(id, (res) => {
        handleCancelNew(res, index, allValues, setAllValues);
      })
    );
  };

  const actions = (item, index) => {
    return (
      <>
        {item.status === "save" ? (
          <CustomOverlay ref={openAction}>
            <ul className="no-style p-0">
              <li
                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                onClick={() => handlEdit(allValues, setAllValues, index)}
              >
                <i className="pi pi-file-edit text-dark"></i>
                Edit
              </li>
              <li
                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                onClick={() => {
                  setIdToDelete({ id: item?.id, index: index });
                  setDeleteModal(true);
                }}
              >
                <i className="pi pi-trash cursor-pointer text-dark"></i>
                Delete
              </li>
            </ul>
          </CustomOverlay>
        ) : (
          <div className="d-flex gap-3">
            <a
              className="cursor-pointer"
              onClick={() =>
                handleSaveNew(index, allValues, setAllValues, ignoreInputs)
              }
            >
              <img src={SaveIcon} />
            </a>
            <a
              className="cursor-pointer"
              onClick={() => {
                if (item.id) {
                  getItemDetails(item.id, index);
                } else {
                  handleDeleteNew(allValues, setAllValues, index);
                }
              }}
            >
              <img src={CrossIcon} />
            </a>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <CustomForm title={title} header={header()} titleCol={7} col={5}>
        <div className="overflow-auto w-full">
          <table className="financial-table w-full mt-3 table-error-align">
            <thead>
              <tr>
                <th>Cost Type</th>
                <th>Amount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody id="financial-table-form">
              {allValues?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td>
                        <CustomInput
                          data={item}
                          onChange={({ name, value }) =>
                            handleChangeNew(
                              name,
                              value,
                              index,
                              allValues,
                              setAllValues,
                              ignoreInputs
                            )
                          }
                          name="cost_type"
                          col={12}
                          disabled={item?.status === "save" ? true : false}
                          ignoreLabel
                        />
                      </td>
                      <td>
                        <CustomAmountInput
                          data={item}
                          onChange={({ name, value }) =>
                            handleChangeNew(
                              name,
                              value,
                              index,
                              allValues,
                              setAllValues,
                              ignoreInputs
                            )
                          }
                          name="amount"
                          col={12}
                          disabled={item?.status === "save" ? true : false}
                          ignoreLabel
                          spanIcon={EuroIcon}
                        />
                      </td>
                      <td>{actions(item, index)}</td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <br />
        <CustomModal
          isOpen={deleteModal}
          setIsOpen={setDeleteModal}
          heading={"Delete"}
          body={modalContent}
          submitLabel={"Confirm"}
          onSubmit={handleDeleteMaintenance}
          loading={loading}
        />
      </CustomForm>
    </>
  );
};

export default AddMaintenanceCosts;
