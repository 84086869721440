import React, { useEffect, useRef, useState } from "react";
import {
  CustomDropDown,
  CustomForm,
  CustomInput,
  CustomPhoneInput,
} from "shared/AllInputs";
import CustomButton from "shared/CustomButton";
import {
  HandleAddNew,
  handleChangeNew,
  handleDeleteNew,
  handlEdit,
  handleSaveNew,
} from "./commonFunctions";
import CustomOverlay from "../CustomOverlay";
import CustomModal from "../Modals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { getAllHandymanServiceListAction } from "store/actions/handymanServiceAction";
import SaveIcon from "assets/images/svg/Save-icon.svg";
import CrossIcon from "assets/images/svg/cross-icon.svg";
import { showToastAction } from "store/slices/commonSlice";

const AddService = ({ title, allValues, setAllValues }) => {
  const openAction = useRef(null);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });
  const InputObj = {
    service: "",
    email: "",
    work_phone: "",
    home_phone: "",
  };
  const ignoreInputs = ["status", "id"];
  const [optional, setOptional] = useState([""]);
  const [deleteModal, setDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.user);
  const [servicesList, setServicesList] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([]);

  useEffect(() => {
    if (profile?.company) {
      getServiceList(profile?.company);
    }
  }, [profile]);

  const header = (
    <div className="w-12 flex justify-content-end flex-wrap">
      <CustomButton
        type="button"
        label="Add"
        onClick={() => HandleAddNew(InputObj, allValues, setAllValues)}
      />
    </div>
  );

  useEffect(() => {
    setServiceOptions(servicesList);
  }, []);

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected service from the list? This
        action cannot be undone.
      </span>
    );
  };

  const handleServiceDelete = () => {
    handleDeleteNew(allValues, setAllValues, idToDelete?.index);
    setIdToDelete({ id: "", index: "" });
    setDeleteModal(false);
    // dispatch(
    //   showToastAction({
    //     type: "success",
    //     detail: "Service Deleted Sucessfully",
    //   })
    // );
  };

  const getServiceList = () => {
    dispatch(
      getAllHandymanServiceListAction(profile?.company, (res) => {
        const options = res?.options?.map((item) => {
          return {
            name: item?.name,
            value: item?.id,
          };
        });
        setServicesList(options);
      })
    );
  };

  const handleOptionalInputs = ({ index, name, value }) => {
    const values = [...allValues];
    if (name === "home_phone") {
      if (!values[index]?.work_phone) {
        delete values[index]?.formErrors?.work_phone;
      }

      if (!values[index]?.email) {
        delete values[index]?.formErrors?.email;
      }

      setOptional(["work_phone", "email"]);
    } else if (name === "work_phone") {
      if (!values[index]?.work_phone) {
        delete values[index]?.formErrors?.home_phone;
      }

      if (!values[index]?.email) {
        delete values[index]?.formErrors?.email;
      }

      setOptional(["home_phone", "email"]);
    } else if (name === "email") {
      if (!values[index]?.work_phone) {
        delete values[index]?.formErrors?.work_phone;
      }

      if (!values[index]?.home_phone) {
        delete values[index]?.formErrors?.home_phone;
      }
      setOptional(["home_phone", "work_phone"]);
    }
    setAllValues(values);
  };

  const handleSaveService = (index) => {
    let innerOptional = optional;
    const myObj = allValues[index];
    const { email, home_phone, work_phone, id, service } = myObj;

    if (!email || !home_phone || !work_phone || !service) {
      handleSaveNew(index, allValues, setAllValues, ignoreInputs);
    }

    if (!optional.length) {
      if (service && email) {
        innerOptional = ["home_phone", "work_phone"];
      } else if (service && home_phone) {
        innerOptional = ["email", "work_phone"];
      } else if (service && work_phone) {
        innerOptional = ["email", "home_phone"];
      }
    }

    if (service && (email || home_phone || work_phone)) {
      handleSaveNew(
        index,
        allValues,
        setAllValues,
        ignoreInputs,
        innerOptional
      );
      setOptional([]);
    }
  };

  useEffect(() => {
    const options = servicesList?.map((option) => {
      const isDisabled = allValues?.some(
        (service) => service?.service === option.value
      );
      return {
        ...option,
        disabled: isDisabled,
      };
    });

    setServiceOptions(options);
  }, [allValues, servicesList]);

  return (
    <>
      <CustomForm title={title} header={header}>
        <div className="overflow-auto">
          <table className="financial-table w-full mt-3 ">
            <thead>
              <tr>
                <th>Service</th>
                <th>Email</th>
                <th>Work Phone</th>
                <th>Home Phone</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody id="financial-table-form" className="cell-width">
              {allValues?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td>
                        <CustomDropDown
                          data={item}
                          onChange={(e) =>
                            handleChangeNew(
                              e?.target?.name,
                              e?.target?.value,
                              index,
                              allValues,
                              setAllValues,
                              ignoreInputs
                            )
                          }
                          name="service"
                          col={12}
                          options={serviceOptions}
                          disabled={item?.status === "save" ? true : false}
                          ignoreLabel
                        />
                      </td>
                      <td>
                        <CustomInput
                          disabled={item?.status === "save"}
                          type="text"
                          name="email"
                          extraClassName="w-full"
                          data={item}
                          onChange={(e) => {
                            handleOptionalInputs({
                              index: index,
                              name: e?.target?.name,
                              value: e?.target?.value,
                            });
                            handleChangeNew(
                              e?.target?.name,
                              e?.target?.value,
                              index,
                              allValues,
                              setAllValues,
                              ignoreInputs
                            );
                          }}
                          ignoreLabel
                        />
                      </td>
                      <td>
                        <CustomPhoneInput
                          disabled={item?.status === "save"}
                          data={item}
                          extraClassName="w-full"
                          onChange={(e) => {
                            handleOptionalInputs({
                              index: index,
                              name: e?.name,
                              value: e?.value,
                            });
                            handleChangeNew(
                              e?.name,
                              e?.value || "",
                              index,
                              allValues,
                              setAllValues,
                              ignoreInputs
                            );
                          }}
                          name="work_phone"
                          required
                          ignoreLabel
                        />
                      </td>
                      <td>
                        <CustomPhoneInput
                          disabled={item?.status === "save"}
                          data={item}
                          extraClassName="w-full"
                          onChange={(e) => {
                            handleOptionalInputs({
                              index: index,
                              name: e?.name,
                              value: e?.value,
                            });
                            handleChangeNew(
                              e?.name,
                              e?.value || "",
                              index,
                              allValues,
                              setAllValues,
                              ignoreInputs
                            );
                          }}
                          name="home_phone"
                          required
                          ignoreLabel
                        />
                      </td>
                      {item?.status === "save" ? (
                        <td>
                          <CustomOverlay ref={openAction}>
                            <ul className="no-style p-0">
                              <li
                                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                                onClick={() =>
                                  handlEdit(allValues, setAllValues, index)
                                }
                              >
                                <i className="pi pi-file-edit text-dark"></i>
                                Edit
                              </li>
                              <li
                                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                                onClick={() => {
                                  setIdToDelete({ id: item?.id, index: index });
                                  setDeleteModal(true);
                                }}
                              >
                                <i className="pi pi-trash cursor-pointer text-dark"></i>
                                Delete
                              </li>
                            </ul>
                          </CustomOverlay>
                        </td>
                      ) : (
                        <td>
                          <div className="d-flex gap-3">
                            <a
                              className="cursor-pointer"
                              onClick={() => {
                                handleSaveService(index);
                                const options = allValues
                                  ?.map((service) => {
                                    return servicesList?.filter(
                                      (opt) => opt?.value !== service?.service
                                    );
                                  })
                                  .flat();
                                setServiceOptions(options);
                              }}
                            >
                              <img src={SaveIcon} />
                            </a>
                            <a
                              className="cursor-pointer"
                              onClick={() => {
                                if (allValues.length > 1) {
                                  handleDeleteNew(
                                    allValues,
                                    setAllValues,
                                    index
                                  );
                                } else {
                                  dispatch(
                                    showToastAction({
                                      type: "error",
                                      detail:
                                        "At least one service is required.",
                                    })
                                  );
                                }
                              }}
                            >
                              <img src={CrossIcon} />
                            </a>
                          </div>
                        </td>
                      )}
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <br />
      </CustomForm>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleServiceDelete}
        loading={loading}
      />
    </>
  );
};

export default AddService;
