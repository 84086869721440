import api from "services/api";
import endPoints from "services/endPoints";
import { showToastAction } from "store/slices/commonSlice";
import { setOfficeUnitSlice } from "store/slices/officeUnitSlice";

const addOfficeUnitAction = (company, data, loading, navigate, dispatch) => async () => {
    loading(true)
    const res = await api(
        "post",
        endPoints?.ADD_OFFICE_UNIT + `${company}/`,
        data
    );

    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        dispatch(setOfficeUnitSlice(res?.data?.results))
        navigate("/administrator/office-unit/list")
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }

    loading(false)
};

const getOfficeUnitListAction = (company, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.OFFICE_UNIT_LIST + `${company}/`,
    );
    onRes(res);
};

const officeUnitListAction = (company, rows, page, search, ordering, name, head1, head3, officeCount, onRes) => async () => {
    
    const res = await api(
        "get",
        endPoints?.OFFICE_UNIT_LIST + `${company}/?limit=${rows}&page=${page}&search=${search}&ordering=${ordering}&name=${name}&head_1=${head1}&head_3=${head3}&office_count=${officeCount || ""}`,
    );
    onRes(res);
};

const getOfficeUnitInfoAction = (company, id, dispatch, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.OFFICE_UNIT_LIST + `${company}/${id}`,
    );
    if(res.success){
        onRes(res?.data?.results);
    }else{
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
};

const updateOfficeUnitAction = (company, id, data, loading, navigate, dispatch) => async () => {
    loading(true)
    const res = await api(
        "put",
        endPoints?.ADD_OFFICE_UNIT + `${company}/${id}/`,
        data
    );
    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        navigate(`/administrator/office-unit/view/${id}`)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    loading(false)
};

const deleteOfficeUnitAction = (company, id, data, loading, navigate, onError) => async () => {
    loading(true)
    const res = await api(
        "put",
        endPoints?.ADD_OFFICE_UNIT + `${company}/${id}/`,
        data
    );
    if (res?.success) {
        navigate(`/administrator/office-unit/view/${id}`)
    } else {
        onError(res?.message);
    }
    loading(false)
};

const unitDeleteAction = (company, unitId, loading, deleteModal, dispatch, onRes) => async () => {
    loading(true)
    const res = await api(
        "delete",
        endPoints?.OFFICE_UNIT_LIST + `${company}/${unitId}`,
    );

    loading(false)
    deleteModal(false)
    if (res?.success) {
        const info = res?.data?.results;
        onRes(info)
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
};

export {
    addOfficeUnitAction,
    getOfficeUnitListAction,
    getOfficeUnitInfoAction,
    updateOfficeUnitAction,
    deleteOfficeUnitAction,
    unitDeleteAction,
    officeUnitListAction,
};
