import React from "react";
import { CustomForm, CustomRadioInput } from "shared/AllInputs";
import {
  CustomAmountViewInput,
  CustomDateViewInput,
  CustomNumberViewInput,
  CustomViewInput,
} from "shared/AllViewInputs";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "assets/images/icons/editicon.png";
import {
  handleFormatDate,
} from "utils/commonFunctions";
import CustomExtraCostTable from "../Tables/CustomExtraCostTable";
import { canAccess } from "services/auth";
import EuroIcon from "assets/images/svg/euroIcon.svg"

const ViewRentandCost = ({
  title,
  type, 
  isEditable,
  contractId,
  data,
  allExtraCost,
  setAllExtraCost,
  movingInDate
}) => {
  const params = useParams()
  const navigate = useNavigate();
  
  const header = () => {
    return (
      <div className="flex justify-content-around cursor-pointer">
        <img
          src={EditIcon}
          alt=""
          onClick={() =>
            navigate(`/real-estate/property/edit/rent-and-cost/${params?.id}`)
          }
        ></img>
      </div>
    );
  };

  return (
    <>
      <CustomForm title={title || "Contractual Rent"} header={isEditable? canAccess("change_property") && header() : null}>
        <CustomAmountViewInput
          name="amount"
          data={data}
          icon={EuroIcon}
        />
        <CustomAmountViewInput
          name="amountFirstMonth"
          data={data}
          icon={EuroIcon}
        />
      </CustomForm>

      <CustomForm title="Automatic Rent Increment">
        <CustomViewInput name="rentRiseType" data={data} />
      </CustomForm>
      <CustomForm>
        {data?.rentRiseType === "None" ||
        data?.rentRiseType === "null" ? null : (
          <>
            <CustomViewInput
              name="intervalType"
              data={data}
              label={"Rent Rise Interval Type"}
            />
            <CustomViewInput
              name="riseInterval"
              data={data}
              label={"Rent Rise Interval"}
            />
            <CustomDateViewInput
              name="startDate"
              value={data?.startDate}
              label={"Date of First Rise"}
            />

            <CustomAmountViewInput
              name="riseAmount"
              data={data}
              label={"Rent Rise Amount"}
              icon={EuroIcon}
            />
            <CustomDateViewInput
              name="endDate"
              value={data?.endDate}
              label={"Date of Last Rise"}
            />
          </>
        )}
      </CustomForm>
      {/* <CommonViewTable
        title={"Extra Cost"}
        list={allExtraCost}
        columns={costColumns}
        actions={actions}
      /> */}
      <CustomExtraCostTable
        action={type}
        id={contractId}
        title={"Extra Costs"}
        allExtraCost={allExtraCost}
        setAllExtraCost={setAllExtraCost}
        movingInDate={movingInDate}
      />
      {data?.propertyRent && <CustomForm title={"Property Rent"}>
        <CustomRadioInput
          disabled={true}
          extraClassName="w-25rem"
          inputClass="mr-2"
          name="propertyRent"
          value="Size"
          label={<div className="inline-block">By Size</div>}
          checked={data?.propertyRent === "Size"}
        />
        <CustomRadioInput
          disabled={true}
          extraClassName="w-25rem"
          inputClass="mr-2"
          name="propertyRent"
          value="Room"
          label={<div className="inline-block">By rooms</div>}
          checked={data?.propertyRent === "Room"}
        />
        <CustomRadioInput
          disabled={true}
          extraClassName="w-25rem"
          inputClass="mr-2"
          name="propertyRent"
          value="Manually"
          label={<div className="inline-block">Manually Enter</div>}
          checked={data?.propertyRent === "Manually"}
        />
      </CustomForm>}
    </>
  );
};

export default ViewRentandCost;
