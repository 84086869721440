import React, { useEffect, useMemo, useRef, useState } from "react";
import formValidation from "utils/validations";
import { handleSetAddress, showFormErrors } from "utils/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { getOfficeUnitListAction } from "store/actions/officeUnitActions";
import { useNavigate, useParams } from "react-router-dom";
import { addOfficeAction, getOfficeInfoAction, updateOfficeAction } from "store/actions/officeActions";
// import { getEmployeesListAction } from "store/actions/EmployeeActions";
import { debounce } from "lodash";
import { searchOfficeIdAction } from "store/actions/createProjectActions";
import { checkBankErrors, checkEmployeesErrors } from "shared/Components/commonFunctions";

const OfficeContainer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const { profile } = useSelector((state) => state.user);
    const [data, setData] = useState({
        officeId: "",
        officeName: "",
        // officeNickName: "",
        officeEmail: "",
        phoneNumber: "",
        unit: "",
        // purpose: "",
        // representative: "",
        address: "",
        houseNo: "",
        street: "",
        zipCode: "",
        city: "",
        cityDivision: "",
        country: "",
        costCenter: "",
    });
    const [allBankAccounts, setAllBankAccounts] = useState([
        { id: null, purpose: "All Combined", owner_name: "", iban: "", bic: "", status: "" },
    ]);
    // const [bankErrors, setBankErrors] = useState([])
    const [allEmployees, setAllEmployees] = useState([
        { id: null, position: null, employee: null, status: null },
    ]);
    const [empErrors, setEmpErrors] = useState([]);
    const [allInvites, setAllInvites] = useState([]);
    // const [inviteError, setInviteError] = useState("");
    const [loading, setLoading] = useState(false);
    const [unitList, setUnitList] = useState([]);
    // const [employeesList, setEmployeesList] = useState([]);
    const [officeIdOptions, setOfficeIdOptions] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState()

    useEffect(() => {
        if (profile?.company) {
            getOfficeUnitList(profile?.company);
            // getEmployeesList(profile?.company)
        }

        if (params?.id && profile?.company) {
            getOfficeInfo(profile?.company, params?.id);
        }
    }, [profile, params]);

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    // const getEmployeesList = (company) => {
    //     dispatch(
    //         getEmployeesListAction(company, (res) => {
    //             if (res?.success) {
    //                 let options = res?.data?.results.map((employee) => {
    //                     return {
    //                         value: employee?.id,
    //                         name: `${employee?.user?.first_name} ${employee?.user?.last_name}`,
    //                     };
    //                 });
    //                 setEmployeesList(options);
    //             }
    //         })
    //     );
    // };

    const onSubmit = () => {
        const bankAccounts = checkBankErrors(allBankAccounts, setAllBankAccounts)
        const employees = checkEmployeesErrors(allEmployees, setAllEmployees)
        if (showFormErrors(data, setData) && !bankAccounts?.hasErrors && !employees?.hasErrors) {
            let payload = {
                name: data?.officeName,
                // purpose: data?.purpose,
                address: data?.address,
                street: data?.street,
                zipcode: data?.zipCode,
                city_division: data?.cityDivision,
                city: data?.city,
                country: data?.country,
                office_email: data?.officeEmail,
                phone_number: data?.phoneNumber,
                cost_center: data?.costCenter,
                office_id: data?.officeId,
                // nick_name: data?.officeNickName,
                // office_rep: data?.representative,
                house_number: data?.houseNo
            }

            if (data?.unit) {
                payload.office_unit = data.unit;
            }

            if (!bankAccounts?.hasErrors && bankAccounts?.bankAccounts?.length) {
                payload.bank_accounts = bankAccounts?.bankAccounts;
            }

            if (!employees?.hasErrors && employees?.employees?.length) {
                const empList = employees?.employees?.map((emp) => {
                    return { position: emp?.position, employee: emp?.employee?.id}
                }) 
                payload.add_employee = empList
            }

            if (allInvites) {
                payload.invite_employee = allInvites
            }
            let jsonData = JSON.stringify(payload);
            params?.id ? handleUpdateOffice(jsonData) : handleAddOffice(jsonData)
        }
    };

    // const officeOptions = [
    //     { name: "Social Worker", value: "social-worker" },
    //     { name: "Property Management", value: "property-management" },
    // ];

    const getOfficeUnitList = (company) => {
        dispatch(
            getOfficeUnitListAction(company, (res) => {
                if (res?.success) {
                    let options = res?.data?.results.map((unit) => {
                        return { name: unit?.name, value: unit?.id }
                    })
                    setUnitList(options);
                }
            })
        );
    };

    const handleAddOffice = (payload) => {
        dispatch(
            addOfficeAction(profile?.company, payload, setLoading, navigate, dispatch)
        );
    }

    const handleUpdateOffice = (payload) => {
        dispatch(
            updateOfficeAction(
                profile?.company,
                params?.id,
                payload,
                setLoading,
                navigate,
                dispatch
            )
        );
    }

    const getOfficeInfo = (company) => {
        dispatch(
            getOfficeInfoAction(company, params?.id, dispatch, (res) => {
                let info = {
                    id: res?.id,
                    officeId: res?.office_id,
                    officeName: res?.name,
                    // officeNickName: res?.nick_name,
                    officeEmail: res?.office_email,
                    // purpose: res?.purpose,
                    unit: res?.office_unit?.id,
                    phoneNumber: res?.phone_number,
                    // representative: res?.office_rep?.id,
                    address: res?.address,
                    street: res?.street,
                    zipCode: res?.zipcode,
                    city: res?.city,
                    cityDivision: res?.city_division,
                    country: res?.country,
                    costCenter: res?.cost_center,
                    houseNo: res?.house_number
                };
                setData(info);
                let bankAccounts = res?.bank_accounts?.map((bank) => {
                    return { ...bank, status: "save" }
                })

                setAllBankAccounts(bankAccounts)
                let employees = res?.employees?.map((emp) => {
                    return { id: emp?.id, employee: {
                        name:`${emp?.employee?.name} (${emp?.employee?.email})`,
                        id: emp?.employee?.id
                    }, position: emp?.position, status: "save" }
                })
                setAllEmployees(employees)
                setAllInvites(res?.invite_employee)

            })
        );
    };

    // const handleOfficeIdChange = ({ name, value }) => {
    //     const formErrors = formValidation(name, value, data);
    //     setData((prev) => ({ ...prev, [name]: value, formErrors }));
    //     if (value) {
    //         debouncedOnChange.current(value);
    //     }
    // };

    const handleSearch = (value) => {
        let payload = {
            office_id: value,
        };

        dispatch(
            searchOfficeIdAction(payload, setOfficeIdOptions, (error) => {
                // if (!res?.success) {
                    const formErrors = ({ ["officeId"]: error })
                    setData((prev) => ({ ...prev, ["officeId"]: value, formErrors }));
                   
                // }
            })
        );
    };

    const debouncedOnChange = useRef(debounce(handleSearch, 1000));

    useMemo(() => {
        if (selectedAddress) {
            handleSetAddress(selectedAddress, data, setData, ["longitude", "latitude"])
        }
    }, [selectedAddress])

    return {
        data,
        handleChange,
        onSubmit,
        allBankAccounts,
        setAllBankAccounts,
        allEmployees,
        setAllEmployees,
        loading,
        unitList,
        // officeOptions,
        // employeesList,
        // inviteError,
        // setInviteError,
        // allInvites,
        // setAllInvites,
        officeIdOptions,
        // handleOfficeIdChange,
        // bankErrors,
        // setBankErrors,
        empErrors,
        setEmpErrors,
        setSelectedAddress,
        debouncedOnChange,
        navigate
    }
}

export default OfficeContainer