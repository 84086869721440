import React, { useEffect, useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { OverlayPanel } from "primereact/overlaypanel";
import { CustomCheckbox } from "shared/AllInputs";
import CustomButton from "shared/CustomButton";
const CustomTableContainer = ({
  list,
  columns,
  actions,
  first,
  rows,
  count,
  ActionTemplate,
  filters,
  setFilters,
  globalFilters,
  activeRow,
  handleSort,
  onPageChange,
  slice,
  selectionMode,
  allowExpansion,
  expandedRows,
  setExpandedRows,
  rowExpansionTemplate,
  onApplyFilter,
  onClearFilter,
  selectedItems,
  setSelectedItems,
  notShowSelection,
  openAction,
  columnOptions,
  setColumnOptions,
  setColumns,
  firstCol,
  sortField,
}) => {
  const [rowClick, setRowClick] = useState(true);
  const [timer, setTimer] = useState(5);
  const dispatch = useDispatch();

  const filterClearTemplate = (options) => {
    return (
      <Button
        type="button"
        // icon="pi pi-times"
        // onClick={() => options.filterClearCallback("")}
        onClick={() => {
          onClearFilter
            ? onClearFilter(options)
            : options.filterClearCallback("");
        }}
        className="clear-button-outlined"
      >
        Clear
      </Button>
    );
  };

  const filterApplyTemplate = (options) => {
    return (
      <Button
        type="button"
        // icon="pi pi-check"
        // onClick={options.filterApplyCallback}
        onClick={() => {
          onApplyFilter
            ? onApplyFilter(options)
            : options.filterApplyCallback();
        }}
        className="bg-primary-color"
      >
        Apply
      </Button>
    );
  };

  const rowClass = (data) => {
    return {
      // "bg-primary-color": data?.id == activeRow,
      "bg-grey-color": data?.id == activeRow,
    };
  };

  useEffect(() => {
    const timeInterval = setInterval(() => {
      if (timer > 0) {
        let newTime = timer - 1;
        setTimer(newTime);
      }
    }, 1000);

    return () => {
      clearInterval(timeInterval);
    };
  }, [timer]);

  useEffect(() => {
    if (timer === 0 && slice) {
      dispatch(slice(""));
    }
  }, [timer]);

  const handleCheckboxChange = (name, value) => {
    setColumnOptions((prevOptions) =>
      prevOptions.map((option) =>
        option.accessor === name ? { ...option, value: value } : option
      )
    );
  };

  const handleChangeColumns = (e) => {
    let filterdCols = columnOptions.filter((col) => col.value === true);
    setColumns(filterdCols);
    openAction.current.toggle(e);
  };

  const customSortIcon = (options) => {
    // if(sortField?.order == "-"){
    //   return <i className="pi pi-arrow-up"></i>
    // }else {
    //   return <i className="pi pi-arrow-down"></i>
    // }
    //   // let icon = options.sorted ? (
    //   //   options.sortOrder < 0 ? (
    //   //     <ChevronDownIcon {...options.iconProps} />
    //   //   ) : (
    //   //     <ChevronUpIcon {...options.iconProps} />
    //   //   )
    //   // ) : (
    //   //   <SortAltIcon {...options.iconProps} />
    //   // );
    //   // return icon;
  };
  return (
    <>
      <DataTable
        className="mt-4 table-list"
        value={list}
        tableStyle={{ minWidth: "50rem" }}
        // selectionMode={rowClick ? null : "checkbox"}
        selectionMode={"checkbox"}
        selection={selectedItems && selectedItems}
        onSelectionChange={(e) => {
          setSelectedItems && setSelectedItems(e.value);
        }}
        dataKey="id"
        onSort={handleSort}
        rowClassName={rowClass}
        scrollable
        filters={filters}
        globalFilterFields={globalFilters}
        onFilter={(e) => {
          setFilters && setFilters(e.filters);
        }}
        onRowToggle={(e) => setExpandedRows(e?.data)}
        expandedRows={expandedRows}
        rowExpansionTemplate={rowExpansionTemplate}
        // sortIcon={sortField?.order === "-"? <i className="pi pi-arrow-up"></i> : <i className="pi pi-arrow-down"></i>}
        sortIcon={customSortIcon}
      >
        {!notShowSelection && (
          <Column
            headerClassName="custom-header"
            selectionMode={selectionMode || "multiple"}
            headerStyle={{ width: "3rem" }}
            frozen
          ></Column>
        )}
        {allowExpansion && (
          <Column expander={allowExpansion} style={{ width: "5rem" }} frozen />
        )}
        {columns?.map((col, index) => {
          return (
            <Column
              key={index}
              headerClassName="custom-header"
              filterField={col?.accessor}
              field={col?.accessor}
              header={col?.name}
              frozen={col?.frozen}
              sortable={col?.sortable}
              filter={col?.filter}
              filterElement={col?.filterElement}
              filterMenuStyle={{ width: "14rem" }}
              style={{ minWidth: "180px" }}
              filterClear={filterClearTemplate}
              filterApply={filterApplyTemplate}
              body={col?.body}
              showFilterMenuOptions={false}
              showFilterMatchModes={false}
            ></Column>
          );
        })}
        <Column
          headerClassName="custom-header"
          body={actions || ""}
          style={{ width: "80px" }}
          header={ActionTemplate || ""}
          alignFrozen="right"
          frozen={true}
        ></Column>
      </DataTable>
      <div className="flex align-items-center">
        {(selectionMode !== "single"  && !notShowSelection) && <span className="text-gray-600">
          {selectedItems?.length || 0} out of {count || 0}
        </span>}

        {onPageChange && (
          <Paginator
            className="mx-auto justify-content-center"
            first={Number(first)}
            rows={Number(rows)}
            totalRecords={Number(count)}
            rowsPerPageOptions={[10, 20, 50]}
            onPageChange={onPageChange}
            // lazy
          ></Paginator>
        )}
      </div>
      <OverlayPanel className="filter-overlay-panel" ref={openAction}>
        <h4 className="p-2">Manage Column</h4>
        <ul className="no-style p-0">
          {columnOptions
            ?.sort((a, b) => {
              if (a.name === firstCol) return -1;
              if (b.name === firstCol) return 1;

              if (a.value !== b.value) {
                return b.value - a.value;
              }
              return a.name.localeCompare(b.name);
            })
            ?.map((column, index) => {
              if (!column.disabled) {
                return (
                  <li
                    className="flex gap-2 text-xs font-medium cursor-pointer"
                    key={index}
                  >
                    <CustomCheckbox
                      label={column.name}
                      extraClassName="flex align-items-center"
                      col={12}
                      value={column.value}
                      name={column.accessor}
                      onChange={({ name, value }) =>
                        handleCheckboxChange(name, value)
                      }
                      // disabled={column.accessor === "contract_id"}
                    />
                  </li>
                );
              }
            })}
        </ul>
        <CustomButton
          extraClassNames="mx-2 block"
          label="Apply"
          onClick={(e) => handleChangeColumns(e)}
        />
      </OverlayPanel>
    </>
  );
};

export default CustomTableContainer;
