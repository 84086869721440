import React from "react";
import { CustomDropDown, CustomForm } from "../../shared/AllInputs";
import AddFurniture from "../../shared/Components/AddFurniture";
import { YesNoOptions } from "../../shared/AllDropDownOptions";

const FurnitureForm = ({title, propertyId, landlordId, data, handleChange, allFurniture, setAllFurniture, mode}) => {
  return (
    <>
      <CustomForm title={title}>
        <CustomDropDown
          data={data}
          onChange={handleChange}
          name="furnitureProvided"
          options={YesNoOptions} 
          label="Is Furniture provide by the Landlord?"
          required
          extraClassName={"capitalize"}
          disabled={mode === "edit"}
        />
      </CustomForm>
      {data?.furnitureProvided === "yes" && (
        <AddFurniture
          title=""
          propertyId={propertyId}
          landlordId={landlordId}
          allValues={allFurniture}
          setAllValues={setAllFurniture}
          // errors={furnitureErrors}
          // setErrors={setFurnitureErrors}
        />
      )}
    </>
  );
};

export default FurnitureForm;
