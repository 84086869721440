import React from "react";
import { CustomForm } from "shared/AllInputs";
import { NewCustomImageView } from "shared/AllViewInputs";

const ViewContractAndUnitFiles = ({ title, files }) => {
  return (
    <>
      <CustomForm title={title || "Documents"}>
        <div className="col-12 flex gap-3 flex-wrap align-items-start">
          <NewCustomImageView
            files={files[0]?.handover_protocol}
            documentType={"handover_protocol"}
          />
          <NewCustomImageView
            files={files[1]?.key_protocol}
            documentType={"key_protocol"}
          />
          <NewCustomImageView
            files={files[2]?.floor_plan}
            documentType={"floor_plan"}
          />
          <NewCustomImageView
            files={files[3]?.photos}
            documentType={"photos"}
          />
          <NewCustomImageView
            files={files[4]?.energy_bill}
            documentType={"energy_bill"}
          />
          <NewCustomImageView
            files={files[5]?.energy_contract}
            documentType={"energy_contract"}
          />
          <NewCustomImageView
            files={files[6]?.energymeter_replacement}
            documentType={"energymeter_replacement"}
          />
        </div>
      </CustomForm>
    </>
  );
};

export default ViewContractAndUnitFiles;
