import React, { useEffect, useState } from "react";
import {
  CustomDropDown,
  CustomForm,
  CustomInput,
  CustomPhoneInput,
  CustomRatings,
  CustomTextArea,
} from "shared/AllInputs";
import CustomButton from "shared/CustomButton";
import CustomModal from "shared/Modals/CustomModal";
import {
  changeServiceStatusActon,
  deleteHandymanServiceActon,
  handleAddServiceActon,
  handleUpdateServiceActon,
} from "store/actions/HandymanActions";
import { useDispatch, useSelector } from "react-redux";
import CustomOverlay from "shared/CustomOverlay";
import { getAllHandymanServiceListAction } from "store/actions/handymanServiceAction";
import formValidation from "utils/validations";
import { useParams } from "react-router-dom";
import CommonViewTable from "./CommonViewTable";
import { showToastAction } from "store/slices/commonSlice";
import { showFormErrors } from "utils/commonFunctions";
const CustomHandymanServiceTable = ({ services }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [isServiceOpen, setIsServiceOpen] = useState({
    isOpen: false,
    action: "",
  });
  const [data, setData] = useState({
    service: "",
    email: "",
    work_phone: "",
    home_phone: "",
    status: "",
  });
  const [serviceStatusModal, setServiceStatusModal] = useState({
    isOpen: false,
    action: "",
  });
  const [loading, setLoading] = useState(false);
  const [servicesList, setServicesList] = useState([]);
  const [selectedService, setSelectedService] = useState();
  const [reason, setReason] = useState("");
  const { profile } = useSelector((state) => state.user);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [serviceOptions, setServiceOptions] = useState([]);

  useEffect(() => {
    if (profile?.company) {
      getServiceList(profile?.company);
    }
  }, [profile]);

  const header = (
    <div className="w-12  flex justify-content-end flex-wrap">
      <CustomButton
        type="button"
        label="Add"
        onClick={() => setIsServiceOpen({ isOpen: true, action: "Add" })}
      />
    </div>
  );

  const serviceModalContent = () => {
    return (
      <div>
        <CustomDropDown
          data={data}
          onChange={handleChange}
          name="service"
          col={12}
          options={serviceOptions}
          label={"Select Service"}
        />
        <CustomInput
          data={data}
          type="text"
          name="email"
          extraClassName="w-full"
          onChange={handleChange}
        />
        <CustomPhoneInput
          data={data}
          extraClassName="w-full"
          onChange={handleChange}
          name="work_phone"
        />
        <CustomPhoneInput
          data={data}
          extraClassName="w-full"
          onChange={handleChange}
          name="home_phone"
        />
      </div>
    );
  };

  // const handleChange = ({ name, value }) => {
  //   // Update the field's value in the data
  //   const updatedData = { ...data, [name]: value };

  //   // Validate the fields
  //   let formErrors = formValidation(updatedData);

  //   // Apply optional handling
  //   formErrors = handleOptional(name, formErrors);

  //   // Update state
  //   setData({ ...updatedData, formErrors });
  // };

  const handleChange = ({ name, value }) => {
    const formErrors = formValidation(name, value, data);
    const err = handleOptional(name, formErrors);
    setData((prev) => ({ ...prev, [name]: value, err }));
  };

  useEffect(() => {
    const fieldsToValidate = ["email", "work_phone", "home_phone"];
  
    fieldsToValidate.forEach((field) => {
      if (data?.[field]) {
        const formErrors = formValidation(field, data[field], data);
        const err = handleOptional(field, formErrors);
  
        setData((prev) => ({
          ...prev,
          err: {
            ...prev.err,
            [field]: err,
          },
        }));
      }
    });
  }, [data?.email, data?.work_phone, data?.home_phone]);

  const handleAddService = () => {
    let optional = [];
    if (data?.email) {
      optional = ["work_phone", "home_phone"];
    } else if (data?.work_phone) {
      optional = ["email", "home_phone"];
    } else if (data?.home_phone) {
      optional = ["work_phone", "email"];
    }

    if (showFormErrors(data, setData, ["status"], optional)) {
      if (data?.id) {
        dispatch(
          handleUpdateServiceActon(
            params.id,
            data,
            setLoading,
            dispatch,
            (res) => {
              setIsServiceOpen({ isOpen: false, action: "" });
              setData({
                service: "",
                email: "",
                work_phone: "",
                home_phone: "",
                status: "",
              });
            }
          )
        );
      } else {
        dispatch(
          handleAddServiceActon(
            params.id,
            data,
            setLoading,
            dispatch,
            (res) => {
              setIsServiceOpen({ isOpen: false, action: "" });
              setData({
                service: "",
                email: "",
                work_phone: "",
                home_phone: "",
                status: "",
              });
            }
          )
        );
      }
    }
  };

  const handleOptional = (name, formErrors) => {
    const errors = { ...formErrors };

    if (name === "email" || name === "home_phone" || name === "work_phone") {
      if (data?.work_phone || data?.home_phone || data?.email) {
        delete data?.formErrors?.work_phone;
        delete data?.formErrors?.home_phone;
        delete data?.formErrors?.email;
      }
    }

    return errors;
  };

  // const handleOptional = (name, formErrors) => {
  //   const errors = formErrors;
  //   if (name === "email") {
  //     delete errors.work_phone;
  //     delete errors.home_phone;
  //   } else if (name === "work_phone") {
  //     delete errors.email;
  //     delete errors.home_phone;
  //   } else if (name === "home_phone") {
  //     delete errors.work_phone;
  //     delete errors.email;
  //   }
  //   setData((prev) => ({ ...prev, formErrors: errors }));
  // };

  const actions = (d) => {
    return (
      <>
        <CustomOverlay>
          <ul className="no-style p-0">
            <li
              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
              onClick={() => {
                setIsServiceOpen({ isOpen: true, action: "Edit" });
                setData({
                  id: d?.id,
                  service: d?.service?.id,
                  email: d?.email,
                  work_phone: d?.work_phone,
                  home_phone: d?.home_phone,
                  status: d?.status,
                });
              }}
            >
              <i className="pi pi-file-edit text-dark"></i>
              Edit
            </li>
            {d?.status === "Unblocked" ? (
              <li
                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                onClick={() => {
                  setServiceStatusModal({
                    isOpen: true,
                    action: d?.status === "Unblocked" ? "Block" : "Unblock",
                  });
                  setSelectedService(d?.id);
                }}
              >
                <i className="pi pi-ban text-dark"></i>
                Block
              </li>
            ) : (
              <li
                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                onClick={() => {
                  setServiceStatusModal({
                    isOpen: true,
                    action: d?.status === "Blocked" ? "Unblock" : "Block",
                  });
                  setSelectedService(d?.id);
                }}
              >
                <i className="pi pi-ban text-dark"></i>
                Unblock
              </li>
            )}
            <li
              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
              onClick={() => {
                if (services?.length === 1) {
                  dispatch(
                    showToastAction({
                      type: "error",
                      detail: "At least one service is required.",
                    })
                  );
                } else {
                  setIsDeleteModal(true);
                  setSelectedService(d?.id);
                }
              }}
            >
              <i className="pi pi-trash text-dark"></i> Delete
            </li>
          </ul>
        </CustomOverlay>
      </>
    );
  };

  const getServiceList = () => {
    dispatch(
      getAllHandymanServiceListAction(profile?.company, (res) => {
        const options = res?.options?.map((item) => {
          return {
            name: item?.name,
            value: item?.id,
          };
        });
        setServicesList(options);
      })
    );
  };

  const modalContent = () => {
    return (
      <>
        <span>{`Are you sure you want to ${serviceStatusModal?.action} user?`}</span>
        {serviceStatusModal?.action === "Block" && (
          <CustomTextArea
            extraClassName={"w-full"}
            value={reason}
            onChange={(e) => setReason(e?.target?.value)}
            name="reason"
            label="Write a reason for the block?"
          />
        )}
      </>
    );
  };

  const deleteModalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected service from the list? This
        action cannot be undone.
      </span>
    );
  };

  const handleChangeServiceStatus = () => {
    dispatch(
      changeServiceStatusActon(
        selectedService,
        serviceStatusModal?.action,
        reason,
        setLoading,
        dispatch,
        (res) => {
          setReason("");
          setServiceStatusModal({ isOpen: false, action: "" });
        }
      )
    );
  };

  const handleDeleteService = () => {
    dispatch(
      deleteHandymanServiceActon(
        selectedService,
        setLoading,
        setIsDeleteModal,
        setSelectedService,
        dispatch,
        (res) => {}
      )
    );
  };

  const onCancel = () => {
    setData({
      service: "",
      email: "",
      work_phone: "",
      home_phone: "",
      status: "",
    });
  };

  useEffect(() => {
    setServiceOptions(servicesList);
  }, []);

  useEffect(() => {
    const options = servicesList?.map((option) => {
      const isDisabled = services?.some(
        (service) => service?.service?.id === option.value
      );
      return {
        ...option,
        disabled: isDisabled,
      };
    });

    setServiceOptions(options);
  }, [services, servicesList]);

  return (
    <>
      <CustomForm header={header} col={12}>
        {services?.length ? (
          services?.map((service, index) => {
            return (
              <div className="p-4 w-full bg-primary-light border-round-xl grid mt-3">
                <div className="col-1">{index + 1}</div>
                <div className="col-4">
                  <h4 className="mb-2">{service?.service?.name}</h4>
                  <p className="mb-2">
                    <span className="font-bold">Email : </span> {service?.email}
                  </p>
                  <p className="mb-2">
                    <span className="font-bold">Home phone : </span>{" "}
                    {service?.home_phone}
                  </p>
                  {service?.reason && (
                    <p className="mb-2">
                      <span className="font-bold">Reason for Block : </span>{" "}
                      {service?.reason}
                    </p>
                  )}
                </div>
                <div className="col-6">
                  <div className="flex gap-3 mb-1">
                    <CustomRatings
                      value={service?.rating?.average_rating}
                      name="average_rating"
                      ignoreLabel
                      extraClassName="p-0"
                      disabled
                      cancel={false}
                      count={service?.rating?.count}
                    />{" "}
                    {/* ({service?.rating?.count}) */}
                  </div>
                  <div className="flex gap-3 mb-2">
                    <p>
                      <span className="font-bold">Work phone : </span>{" "}
                      {service?.work_phone}
                    </p>
                  </div>
                  {service?.blocked_date && (
                    <div className="flex gap-3 mb-2">
                      <p>
                        <span className="font-bold">Block Date : </span>{" "}
                        {service?.blocked_date}
                      </p>
                    </div>
                  )}
                </div>
                <div className="col-1">{actions(service)}</div>
              </div>
            );
          })
        ) : (
          <CommonViewTable title={""} list={[]} />
        )}
      </CustomForm>
      <CustomModal
        isOpen={isServiceOpen?.isOpen}
        setIsOpen={setIsServiceOpen}
        heading={`${isServiceOpen?.action} Service`}
        body={serviceModalContent}
        submitLabel={"Save"}
        onSubmit={handleAddService}
        loading={loading}
        onCancel={onCancel}
      />
      <CustomModal
        isOpen={serviceStatusModal?.isOpen}
        setIsOpen={setServiceStatusModal}
        heading={`${serviceStatusModal?.action}`}
        body={modalContent}
        submitLabel={serviceStatusModal?.action}
        onSubmit={handleChangeServiceStatus}
        loading={loading}
      />
      <CustomModal
        isOpen={isDeleteModal}
        setIsOpen={setIsDeleteModal}
        heading={"Delete Service"}
        body={deleteModalContent}
        submitLabel={"Confirm"}
        onSubmit={handleDeleteService}
        loading={loading}
      />
    </>
  );
};

export default CustomHandymanServiceTable;
