import { handleFormatDate } from "utils/commonFunctions";
import api from "services/api";
import endPoints from "services/endPoints";
import { showToastAction } from "../slices/commonSlice";
import { setEmployeeSlice } from "../slices/employeeSlice";

const addEmployeeAction = (company, data, offices, loading, navigate, dispatch, onRes) => async () => {
    loading(true)

    let payload = {
        employee_id: data?.employeeId,
        first_name: data?.firstName,
        last_name: data?.lastName,
        email: data?.email,
        phone_number: data?.phoneNumber,
        role: data?.selectRole,
    };

    if (offices.length) {
        payload.offices = offices
    }

    const res = await api(
        "post",
        endPoints?.EMPLOYEE_LIST + `${company}/`,
        payload
    );

    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        navigate("/administrator/employees/list")
        onRes && onRes(res)
        dispatch(setEmployeeSlice(res?.data?.results))
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    loading(false)
};

const getEmployeesListAction = (company, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.EMPLOYEE_LIST + `${company}/`,
    );
    onRes(res);
};

const employeesListAction = (company, rows, page, search, id, email, name, phoneNumber, role, createdAt, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.EMPLOYEE_LIST + `${company}/?limit=${rows}&page=${page}&search=${search}&name=${name}&phone_number=${phoneNumber}&role=${role}&active_from=${handleFormatDate(createdAt) || ""}&employee_id=${id}&email=${email}`,
    );
    onRes(res);
};

const searchEmployeesAction = (company, name, isSearch, setEmployeesList) => async () => {
    isSearch && isSearch(true)
    const res = await api(
        "get",
        endPoints?.EMPLOYEE_LIST + `${company}/?name=${name || ""}`,
    );
    if (res?.success) {
        let options = res?.data.results?.map((employee) => {
            return {
                ...employee,
                value: employee?.id,
                name: `${employee?.user?.first_name} ${employee?.user?.last_name} (${employee?.user?.email})`,
            };
        });
        setEmployeesList(options);
    }else {
        setEmployeesList([])
    }
    isSearch && isSearch(false)
};

const getEmployeeInfoAction = (company, empId, dispatch, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.EMPLOYEE_LIST + `${company}/${empId}`,
    );
    if (res?.success) {
        const info = res?.data?.results
        onRes(info);
        dispatch(setEmployeeSlice(info));
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
};

const updateEmployeeAction = (company, id, data, offices, loading, navigate, dispatch, onRes) => async () => {
    loading(true)
    let payload = {
        employee_id: data?.employeeId,
        first_name: data?.firstName,
        last_name: data?.lastName,
        email: data?.email,
        phone_number: data?.phoneNumber,
        role: data?.selectRole,
    };

    if (offices.length) {
        payload.offices = offices
    }
    const res = await api(
        "put",
        endPoints?.EMPLOYEE_LIST + `${company}/${id}/`,
        payload
    );
    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        navigate(`/administrator/employees/view/${id}`)
        onRes && onRes(res)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    loading(false)
};

const employeeDeleteAction = (company, empId, loading, deleteModal, dispatch, onRes) => async () => {
    loading(true)
    const res = await api(
        "delete",
        endPoints?.EMPLOYEE_LIST + `${company}/${empId}`,
    );
    if (res?.success) {
        onRes(res);
        dispatch(showToastAction({
            severity: "success",
            detail: res?.message,
        }))
    } else {
        dispatch(showToastAction({
            severity: "error",
            detail: res?.message,
        }))
    }
    loading(false)
    deleteModal(false)
};

const searchEmployeeIdAction = (payload, setEmployeeIdOptions, onError) => async () => {
    const res = await api(
        "post",
        endPoints?.SEARCH_EMPLOYEE_ID,
        payload
    )
    // onRes(res)
    if (res?.success) {
        setEmployeeIdOptions([])
    } else {
        // let options = res?.data?.results?.map((option) => {
        //     return { name: option, value: option }
        // })
        setEmployeeIdOptions(res?.data?.results)
        onError(`${res?.message}!.`)
    }
}

const updateEmployeeOfficeAction = (id, data, dispatch, onRes) => async () => {
    const res = await api(
        "put",
        endPoints?.OFFICE_EMPLOYEE + `${id}/`,
        data
    );
    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(res)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
}

const deleteEmployeeOfficeAction = (id, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    const res = await api(
        "delete",
        endPoints?.OFFICE_EMPLOYEE + `${id}/`,
    );
    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    onRes(res)
    setLoading(false)
}

const getEmployeeOfficeDetailsAction = (id, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.OFFICE_EMPLOYEE + `/${id}/`,
    );
    if (res.success) {
        const info = res?.data?.results;
        onRes(info)
    }
}

export {
    addEmployeeAction,
    getEmployeesListAction,
    getEmployeeInfoAction,
    updateEmployeeAction,
    employeeDeleteAction,
    employeesListAction,
    searchEmployeeIdAction,
    updateEmployeeOfficeAction,
    deleteEmployeeOfficeAction,
    getEmployeeOfficeDetailsAction,
    searchEmployeesAction
};
