import React, { useState } from "react";
import {
  CustomDropDown,
  CustomForm,
  CustomInput,
  CustomPhoneInput,
} from "shared/AllInputs";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import formValidation from "utils/validations";
import CustomOverlay from "shared/CustomOverlay";
import CustomModal from "shared/Modals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { handleDelete } from "shared/Components/commonFunctions";
import { ContactOptions, ContactRoleOptions } from "shared/AllDropDownOptions";
import {
  deleteContactAction,
  getLandlordAction,
  getPropertyLandlordAction,
  updateContactAction,
} from "store/actions/landlordActions";
import { useParams } from "react-router-dom";
import CrossIcon from "assets/images/svg/cross-icon.svg";
import SaveIcon from "assets/images/svg/Save-icon.svg";

export default function CustomLandlordContactTable({
  action,
  id,
  title,
  allContacts,
  setAllContacts,
}) {
  const dispatch = useDispatch();
  const [editingRows, setEditingRows] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });
  const { profile } = useSelector((state) => state?.user);
  const [optional, setOptional] = useState(["mobile", "office_phone", "email"]);
  const params = useParams();
  const [rowId, setRowId] = useState("");
  const [formErrors, setFormErrors] = useState([
    { mobile: "", office_phone: "", email: "" },
  ]);

  const TypeEditor = (options) => {
    const Errors = formValidation(
      "type",
      options.value,
      allContacts,
      [""],
      optional
    );
    return (
      <CustomDropDown
        value={options.value}
        col={12}
        extraClassName="p-0"
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
        options={ContactOptions}
        name="type"
        ignoreLabel
        errorMessage={Errors["type"]}
      />
    );
  };

  const NameEditor = (options) => {
    const Errors = formValidation(
      "name",
      options.value,
      allContacts,
      [""],
      optional
    );
    return (
      <CustomInput
        value={options.value}
        col={12}
        extraClassName="p-0"
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
        name="name"
        ignoreLabel
        errorMessage={Errors["name"]}
      />
    );
  };

  const RoleEditor = (options) => {
    const Errors = formValidation(
      "role",
      options.value,
      allContacts,
      [""],
      optional
    );
    return (
      <CustomDropDown
        value={options.value}
        col={12}
        extraClassName="p-0"
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
        options={ContactRoleOptions}
        name="role"
        ignoreLabel
        errorMessage={Errors["role"]}
      />
    );
  };

  const MobileEditor = (options) => {
    // let optionalInputs = [""];
    // if (options?.value) {
    //   // optionalInputs = handleOptionalInputs(options?.value);
    //   optionalInputs = optional
    // } else {
    //   optionalInputs = handleGetOptionalInputs(
    //     "mobile",
    //     options?.value,
    //     options?.rowData
    //   );
    // }
    // if(!options?.value){
    //   handleGetOptionalInputs("mobile", options?.value, options?.rowData);
    // }

    // const formErrors = formValidation(options?.field, options?.value, allContacts, ["mobile"], optional);
    // setFormErrors((prev) => ({...prev, ["mobile"]: formErrors}))
    return (
      <CustomPhoneInput
        data={options?.rowData}
        extraClassName="w-full"
        col={12}
        onChange={(e) => {
          // if(!e.value){
          //   handleGetOptionalInputs(options?.field, e?.value, options?.rowData);
          // }else{
          // handleOptionalInputs(options?.field, e.value, options.rowData);}
          options.editorCallback(e?.value || "");
          handleInputChange("mobile", e?.value);
        }}
        name="mobile"
        ignoreLabel
        errorMessage={formErrors["mobile"]}
      />
    );
  };

  const OfficePhoneEditor = (options) => {
    // let optionalInputs = [""];
    // if (options?.value) {
    //   // optionalInputs = handleOptionalInputs(options?.value);
    //   optionalInputs = optional;
    // } else {
    //   optionalInputs = handleGetOptionalInputs(
    //     "office_phone",
    //     options?.value,
    //     options?.rowData
    //   );
    // }

    // if(!options?.value){
    //   handleGetOptionalInputs("mobile", options?.value, options?.rowData);
    // }

    // const formErrors = formValidation(
    //   options?.field,
    //   options.value,
    //   allContacts,
    //   optional, //ignore inputs
    //   optional
    // );
    return (
      <CustomPhoneInput
        data={options?.rowData}
        extraClassName="w-full"
        col={12}
        onChange={(e) => {
          // if(!e.value){
          //   handleGetOptionalInputs(options?.field, e?.value, options?.rowData);
          // }else{
          // handleOptionalInputs(options?.field, e.value, options?.rowData);}
          options.editorCallback(e.value || "");
          handleInputChange("office_phone", e.value);
        }}
        name="office_phone"
        ignoreLabel
        errorMessage={formErrors["office_phone"]}
      />
    );
  };

  const EmailEditor = (options) => {
    // let optionalInputs = [""];
    // if (options?.value) {
    //   // optionalInputs = handleOptionalInputs(options?.value);
    //   optionalInputs = optional;
    // } else {
    //   optionalInputs = handleGetOptionalInputs(
    //     "email",
    //     options?.value,
    //     options?.rowData
    //   );
    // }

    // if(!options?.value){
    //   handleGetOptionalInputs("mobile", options?.value, options?.rowData);
    // }

    // const formErrors = formValidation(
    //   options?.field,
    //   options.value,
    //   allContacts,
    //   optional, //ignore inputs
    //   optional
    // );

    return (
      <CustomInput
        value={options.value}
        extraClassName="w-full"
        col={12}
        onChange={(e) => {
          // if(!e.target.value){
          //   handleGetOptionalInputs(options?.field, options?.value, options?.rowData);
          // }else{
          //   handleOptionalInputs(options?.field, e.target.value, options?.rowData);
          // }
          options.editorCallback(e.target.value);
          handleInputChange("email", e.target.value);
        }}
        name="email"
        ignoreLabel
        errorMessage={formErrors["email"]}
      />
    );
  };

  const rowEditValidator = async (rowData, options) => {
    const { type, name, role, mobile, office_phone, email } = rowData;
    const typeError = formValidation("type", type, allContacts, [""], optional);
    const nameError = formValidation("name", name, allContacts, [""], optional);
    const roleError = formValidation("role", role, allContacts, [""], optional);
    const mobileError = formValidation("mobile", mobile, allContacts, [""]);
    const office_phoneError = formValidation(
      "office_phone",
      office_phone,
      allContacts,
      [""]
    );
    const emailError = formValidation("email", email, allContacts, [""]);
    let hasError = false;

    if (!mobile && !office_phone && !email) {
      setFormErrors((prev) => ({ ...prev, ["mobile"]: mobileError["mobile"] }));
      setFormErrors((prev) => ({
        ...prev,
        ["office_phone"]: office_phoneError["office_phone"],
      }));
      setFormErrors((prev) => ({ ...prev, ["email"]: emailError["email"] }));
      hasError = true;
    } else if (mobile || office_phone || email) {
      const mobileError = formValidation(
        "mobile",
        mobile,
        allContacts,
        [""],
        optional
      );
      const office_phoneError = formValidation(
        "office_phone",
        office_phone,
        allContacts,
        [""],
        optional
      );
      const emailError = formValidation(
        "email",
        email,
        allContacts,
        [""],
        optional
      );
      if (
        mobileError["mobile"] ||
        office_phoneError["office_phoneError"] ||
        emailError["email"]
      ) {
        hasError = true;
      }
    }

    if (
      !typeError["type"] &&
      !nameError["name"] &&
      !roleError["role"] &&
      !hasError
    ) {
      let payload = {
        type: type,
        name: name,
        role: role,
        mobile: mobile,
        office_phone: office_phone,
        email: email,
        landlord: id,
      };

      dispatch(
        updateContactAction(rowData?.id, payload, dispatch, (res) => {
          let contacts = [...allContacts];
          contacts[options.rowIndex] = rowData;
          setAllContacts(contacts);
          setEditingRows({});
          handleGetInfo();
          return true;
        })
      );
    } else {
      return false;
    }
  };

  const handleEditRow = (rowData) => {
    const val = { ...{ [`${rowData?.id}`]: true } };
    setRowId(rowData?.id);
    // const { mobile, office_phone, email } = rowData;
    // if (mobile) {
    //   setOptional(["office_phone", "email"]);
    // } else if (office_phone) {
    //   setOptional(["mobile", "email"]);
    // } else if (email) {
    //   setOptional(["office_phone", "mobile"]);
    // }
    setEditingRows(val);
  };

  const handleSave = async (rowData, options, e) => {
    options?.rowEditor?.onSaveClick(e);
    const val = { ...{ [`${rowData?.id}`]: true } };
    setEditingRows(val);
  };

  const actions = (rowData, options) => {
    return (
      <>
        {options.rowEditor?.editing ? (
          <>
            <a
              style={{ cursor: "pointer" }}
              onClick={(e) => handleSave(rowData, options, e)}
            >
              <img src={SaveIcon} />
            </a>
            <a
              onClick={(e) =>
                options.rowEditor?.onCancelClick &&
                options.rowEditor?.onCancelClick(e)
              }
            >
              {" "}
              <img src={CrossIcon} />
            </a>
          </>
        ) : (
          <>
            <CustomOverlay>
              <ul className="no-style p-0">
                <li
                  className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                  onClick={() => handleEditRow(rowData)}
                >
                  <i className="pi pi-file-edit text-dark"></i>
                  Edit
                </li>
                <li
                  className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                  onClick={() => {
                    setIdToDelete({
                      id: rowData?.id,
                      index: options?.rowIndex,
                    });
                    setDeleteModal(true);
                  }}
                >
                  <i className="pi pi-trash text-dark"></i> Delete
                </li>
              </ul>
            </CustomOverlay>
          </>
        )}
      </>
    );
  };

  const onRowEditChange = (e) => {
    setEditingRows(e.data);
  };

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected contact from the list? This
        action cannot be undone.
      </span>
    );
  };

  const handleDeleteContact = () => {
    setLoading(true);
    dispatch(
      deleteContactAction(idToDelete?.id, setLoading, dispatch, (res) => {
        handleDelete(allContacts, setAllContacts, "", "", idToDelete?.index);
        setIdToDelete({ id: "", index: "" });
        setDeleteModal(false);
        handleGetInfo();
      })
    );
  };

  const handleGetInfo = () => {
    if (action === "property") {
      dispatch(getPropertyLandlordAction(params?.id, dispatch));
    } else if (action === "landlord") {
      dispatch(getLandlordAction(profile?.company, params?.id, dispatch));
    }
  };

  // const handleOptionalInputs = (name, value, rowData) => {
  //   let optionalInputs = [""];
  //   if (name === "mobile") {
  //     optionalInputs = ["office_phone", "email"];
  //   } else if (name === "office_phone") {
  //     optionalInputs = ["mobile", "email"];
  //   } else if (name === "email") {
  //     optionalInputs = ["mobile", "office_phone"];
  //   }
  //   setOptional(optionalInputs);
  //   // return optionalInputs;

  //   // if(!value || value == undefined){
  //   //   const { mobile, office_phone, email } = rowData;
  //   //   if (mobile) {
  //   //     setOptional(["office_phone", "email"])
  //   //   } else if (office_phone) {
  //   //     setOptional(["mobile", "email"])
  //   //   } else if (email) {
  //   //     setOptional(["mobile", "office_phone"])
  //   //   }
  //   // }
  // };

  // const handleGetOptionalInputs = (name, value, data) => {
  //   let optionalInputs = [""];
  //   if (!value) {
  //     const { mobile, office_phone, email } = data;
  //     if (mobile) {
  //       optionalInputs = ["office_phone", "email"];
  //     } else if (office_phone) {
  //       optionalInputs = ["mobile", "email"];
  //     } else if (email) {
  //       optionalInputs = ["mobile", "office_phone"];
  //     }
  //   }
  //   setOptional(optionalInputs);
  //   // return optionalInputs;
  // };

  const handleInputChange = (name, value) => {
    const formErrors = formValidation(name, value, allContacts, [""], optional);
    setFormErrors(formErrors);
  };

  return (
    <CustomForm title={title}>
      <DataTable
        className="col-12 editor-container table-error-align"
        value={allContacts}
        editMode="row"
        dataKey="id"
        editingRows={editingRows}
        onRowEditChange={onRowEditChange}
        // onRowEditComplete={onRowEditComplete}
        rowEditValidator={rowEditValidator}
        onRowEditSave={handleSave}
        tableStyle={{ minWidth: "100rem" }}
      >
        <Column
          headerClassName="custom-header"
          field="type"
          header="Type"
          editor={(options) => TypeEditor(options)}
          // style={{ width: "200px" }}
        ></Column>
        <Column
          headerClassName="custom-header"
          field="name"
          header="Name"
          editor={(options) => NameEditor(options)}
          // style={{ width: "200px" }}
        ></Column>
        <Column
          headerClassName="custom-header"
          field="role"
          header="Role"
          editor={(options) => RoleEditor(options)}
          // style={{ width: "200px" }}
        ></Column>
        <Column
          headerClassName="custom-header"
          field="mobile"
          header="Mobile"
          editor={(options) => MobileEditor(options)}
          // style={{ width: "200px" }}
        ></Column>
        <Column
          headerClassName="custom-header"
          field="office_phone"
          header="Office Phone"
          editor={(options) => OfficePhoneEditor(options)}
          // style={{ width: "200px" }}
        ></Column>
        <Column
          headerClassName="custom-header"
          field="email"
          header="Email"
          editor={(options) => EmailEditor(options)}
          // style={{ width: "200px" }}
        ></Column>
        <Column
          headerClassName="custom-header"
          header="Action"
          rowEditor
          // headerStyle={{ width: "10%", minWidth: "8rem" }}
          // bodyStyle={{ textAlign: "center" }}
          body={(rowData, options) => actions(rowData, options)}
        ></Column>
      </DataTable>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleDeleteContact}
        loading={loading}
      />
    </CustomForm>
  );
}
