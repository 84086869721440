import React, { useEffect, useState } from "react";
import { CustomForm } from "../../shared/AllInputs";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
export default function CommonViewTable({ title, list, columns, actions, header }) {
  // const [products, setProducts] = useState(null);
  // const [statuses] = useState(['INSTOCK', 'LOWSTOCK', 'OUTOFSTOCK']);

  // useEffect(() => {
  //     // ProductService.getProductsMini().then((data) => setProducts(list));
  //     setProducts(list)
  // }, [list]); // eslint-disable-line react-hooks/exhaustive-deps

  // const getSeverity = (value) => {
  //     switch (value) {
  //         case 'INSTOCK':
  //             return 'success';

  //         case 'LOWSTOCK':
  //             return 'warning';

  //         case 'OUTOFSTOCK':
  //             return 'danger';

  //         default:
  //             return null;
  //     }
  // };

  // const onRowEditComplete = (e) => {
  //     let _products = [...products];
  //     let { newData, index } = e;

  //     _products[index] = newData;

  //     setProducts(_products);
  // };

  // const textEditor = (options) => {
  //     return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
  // };

  // const statusEditor = (options) => {
  //     return (
  //         <Dropdown
  //             value={options.value}
  //             options={statuses}
  //             onChange={(e) => options.editorCallback(e.value)}
  //             placeholder="Select a Status"
  //             itemTemplate={(option) => {
  //                 return <Tag value={option} severity={getSeverity(option)}></Tag>;
  //             }}
  //         />
  //     );
  // };

  // const priceEditor = (options) => {
  //     return <InputNumber value={options.value} onValueChange={(e) => options.editorCallback(e.value)} mode="currency" currency="USD" locale="en-US" />;
  // };

  // const statusBodyTemplate = (rowData) => {
  //     return <Tag value={rowData.inventoryStatus} severity={getSeverity(rowData.inventoryStatus)}></Tag>;
  // };

  // const priceBodyTemplate = (rowData) => {
  //     return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(rowData.price);
  // };

  // const allowEdit = (rowData) => {
  //     return rowData.name !== 'Blue Band';
  // };

  
  // const onRowEditCancel = (e) => {
  //     // let _products = [...products];
  //     // let { newData, index } = e;

  //     // _products[index] = newData;

  //     // setProducts(_products);
  // };
  return (
    <CustomForm title={title} header={header && header()} titleCol={8} col={4}>
      <DataTable
        className="col-12"
        value={list}
        tableStyle={{
          minWidth: "50rem",
        }}
      >
        {columns?.map((col, index) => {
          return (
            <Column
              key={index}
              // className="firstletter_capital"
              field={col.accessor}
              header={col?.name}
              headerClassName="custom-header"
              body={col.body}
            ></Column>   
          );
        })}
        {actions && (
          <Column
            body={actions}
            style={{ width: "80px" }}
            header="Action"
            headerClassName="custom-header"
          ></Column>
        )}
      </DataTable>
      {/* <div className="card p-fluid">
            <DataTable value={products} editMode="row" dataKey="id" onRowEditComplete={onRowEditComplete} onRowEditCancel={onRowEditCancel} tableStyle={{ minWidth: '50rem' }}>
                <Column field="code" header="Code" editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
                <Column field="name" header="Name" editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
                <Column field="inventoryStatus" header="Status" body={statusBodyTemplate} editor={(options) => statusEditor(options)} style={{ width: '20%' }}></Column>
                <Column field="price" header="Price" body={priceBodyTemplate} editor={(options) => priceEditor(options)} style={{ width: '20%' }}></Column>
                <Column rowEditor={allowEdit} headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
            </DataTable>
        </div> */}
    </CustomForm>
  );
}
