import React from "react";
import { CustomAmountInput, CustomDropDown, CustomForm } from "../AllInputs";
import EuroIcon from "assets/images/svg/euroIcon.svg"
import { DepositType } from "../AllDropDownOptions";
const Deposits = ({ title, data, handleChange }) => {
  // const [value, setValue] = useState();

  return (
    <>
      <CustomForm title={title ? title : "Deposits"}>
        <CustomDropDown
          data={data}
          onChange={handleChange}
          name="depositType"
          options={DepositType}
          required
        />
        {data.depositType !== "None" && (
         <> 
         {/* <CustomNumberInput2
            data={data}
            onChange={handleChange}
            name="amount"
            required
          /> */}
          
          <CustomAmountInput
            data={data}
            onChange={handleChange}
            name="amount"
            required
            // spanLabel={<i className="pi pi-euro"></i>}
            spanIcon={EuroIcon}
          />
        </>
        )}
      </CustomForm>
    </>
  );
};

export default Deposits;
