import { jwtDecode } from "jwt-decode";
import CryptoJS from "crypto-js";
import { KEYS } from "../utils/keys";

export const isAuthenticated = () => {
  if (typeof window == "undefined") {
    return false;
  }
  if (localStorage.getItem("appToken")) {
    return JSON.parse(localStorage.getItem("appToken"));
  } else if (sessionStorage.getItem("appToken")) {
    return JSON.parse(sessionStorage.getItem("appToken"));
  } else {
    return false;
  }
};

export const getMyId = () => {
  let token = "";
  if (localStorage.getItem("appToken")) {
    token = JSON.parse(localStorage.getItem("appToken"));
  } else if (sessionStorage.getItem("appToken")) {
    token = JSON.parse(sessionStorage.getItem("appToken"));
  }
  const decoded = jwtDecode(token);
  return decoded.userId;
};

export const authenticate = (appToken, rememberMe, next) => {
  if (typeof window !== "undefined") {
    appToken = JSON.stringify(appToken);
    if (rememberMe) {
      localStorage.setItem("appToken", appToken);
    } else {
      sessionStorage.setItem("appToken", appToken);
    }
    next();
  }
};

export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(data, KEYS.secretKey).toString()
}

export const decryptData = (data) => {
  const bytes = CryptoJS.AES.decrypt(data, KEYS.secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
}

export const storeUserInfo = (info, rememberMe) => {
  if (typeof window !== "undefined") {
    const encrypteData = encryptData(JSON.stringify(info))
    let user = JSON.stringify(encrypteData)
    if (rememberMe) {
      localStorage.setItem(KEYS?.user, user);
    } else {
      // localStorage.clear()
      sessionStorage.setItem(KEYS?.user, user);
    }
  }
}

export const getUserInfo = () => {
  const info = sessionStorage.getItem(KEYS.user) || localStorage.getItem(KEYS.user)
  if (info) {
    const decryptedData = decryptData(JSON.parse(info))
    return JSON.parse(decryptedData)
  }
}

export const logout = (next) => {
  if (typeof window !== "undefined") {
    localStorage.clear();
    sessionStorage.clear();
    if (next) {
      next();
    }
  }
};

export const canAccess = (route) => {
  const profile = getUserInfo()
  let permissions = ["dashboard"]

  profile?.permissions?.map((perm) => {
    return permissions.push(perm.codename)
  })
  
  if (permissions.includes(route)) {
    return true
  } else {
    // if (!route) {
    //   return true  //remove
    // } else {
    return false
    // }
  }

  // return true
};

