import React from "react";
import { Panel } from "primereact/panel";
import { CustomCheckbox } from "shared/AllInputs";
export default function RolesPanel({
  type,
  parentKey,
  parent,
  roles,
  setRoles,
}) {
  const handleParentChange = (value, parent) => {
    let updatedChildren = roles[parentKey][parent].map((child) => ({
      ...child,
      check: value,
      isDisabled: false
    }));

    setRoles({
      ...roles,
      [parentKey]: {
        ...roles[parentKey],
        [parent]: updatedChildren,
      },
    });
  };

  const handleChildChange = (value, index, parent, name) => {
    const nameMap = {
      "Edit Properties": ["View Properties", "Add Properties"],
      "Edit Contract": ["View Contract", "Add Contract"],
      "Edit Landlord": ["View Landlord", "Add Landlord"],
      "Edit Building": ["View Building", "Add Building"],
      "Edit Annual Statements": [
        "View Annual Statements",
        "Add Annual Statements",
      ],
      "Edit Handyman": ["View Handyman", "Add Handyman"],
      "Edit Damage": ["View Damage", "Add Damage"],
      "Process Maintenance Request": ["View Maintenance Request"],
      "Edit Tenant": ["View Tenant", "Add Tenant"],
      "Edit Unit": ["View Unit", "Add Unit"],
      "Edit Office": ["View Office", "Add Office"],
      "Edit Employee": ["View Employee", "Add Employee"],
      "Edit Role": ["View Role", "Add Role"],
    };

    let child = roles[parentKey][parent].map((item, i) => {
      if (value && nameMap[name] && nameMap[name].includes(item.name)) {
        return { ...item, check: true, isDisabled: true };
      } else if (!value && nameMap[name] && nameMap[name].includes(item.name)) {
        return { ...item, check: true, isDisabled: false };
      }

      if (index === i) {
        return { ...item, check: value };
      }

      return item;
    });
    setRoles({
      ...roles,
      [parentKey]: { ...roles[parentKey], [parent]: child },
    });
  };

  const isChecked = (parent) => {
    let isAllChecked = roles[parentKey][parent].every((item) => {
      return item?.check === true;
    });

    return isAllChecked;
  };

  return (
    <div className="accordion-item">
      <Panel
        header={
          <>
            <CustomCheckbox
              col={12}
              disabled={type === "View"}
              className="form-check-input mt-0 me-2"
              type="checkbox"
              name={parent}
              onChange={() => {}}
              checked={isChecked(parent)}
              onClick={(e) => handleParentChange(e?.target?.checked, parent)}
              label={parent}
            />
          </>
        }
        toggleable
        collapseIcon={
          <i className="pi pi-chevron-up" style={{ fontSize: "1rem" }}></i>
        }
        expandIcon={
          <i className="pi pi-chevron-down" style={{ fontSize: "1rem" }}></i>
        }
      >
        <div className="accordion-body">
          <div className="table-responsive mt-3">
            {roles[parentKey][parent]?.map((child, index) => {
              return (
                <div className="mb-3" key={index}>
                  {/* <label className="form-check-label cursor-pointer"> */}
                    <CustomCheckbox
                      disabled={type === "View" || child?.isDisabled}
                      col={12}
                      extraClassName={"flex gap-1"}
                      // className={`form-check-input mt-0 me-2 ${type === "View" || child?.isDisabled ? "disabled-checkbox" : ""}`}
                      type="checkbox"
                      value={child?.value}
                      name={child?.name}
                      onChange={() => {}}
                      checked={child.check}
                      onClick={(e) => {
                        handleChildChange(
                          e?.target?.checked,
                          index,
                          parent,
                          e?.target?.name
                        );
                      }}
                      label={child?.name}
                    />
                    {/* {child?.name} */}
                  {/* </label> */}
                </div>
              );
            })}
          </div>
        </div>
      </Panel>
    </div>
  );
}

// export default RolesPanel;
