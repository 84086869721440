import React, { useEffect, useMemo, useRef, useState } from "react";
import CustomCard from "shared/CustomCard";
import {
  CustomInput,
  CustomNumberInput2,
  CustomSearch,
} from "shared/AllInputs";
import CustomButton from "shared/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  officeUnitListAction,
  unitDeleteAction,
} from "store/actions/officeUnitActions";
import CustomOverlay from "shared/CustomOverlay";
import CustomModal from "shared/Modals/CustomModal";
import debounce from "lodash.debounce";
import CustomTableContainer from "views/Tables/CustomTableContainer";
import { setOfficeUnitSlice } from "store/slices/officeUnitSlice";
import { canAccess } from "services/auth";
const OfficeUnitList = () => {
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState(null);
  // const [rowClick, setRowClick] = useState(true);
  const navigate = useNavigate();
  const { profile } = useSelector((state) => state.user);
  const [unitList, setUnitList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");
  const [count, setCount] = useState(0);
  const [first, setFirst] = useState(0);
  const [search, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [sortField, setSortField] = useState({ field: "", order: "" });
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = query.get("page") || 1;
  const rows = query.get("rows") || 10;
  const { officeUnit } = useSelector((state) => state?.officeUnit);
  const globalFilters = ["name", "head_1", "head_3", "office_count"];

  const [filters2, setFilters2] = useState({
    name: { value: "" },
    head_1: { value: "" },
    head_3: { value: "" },
    office_count: { value: "" },
  });

  const [filters, setFilters] = useState({
    name: "",
    head_1: "",
    head_3: "",
    office_count: "",
  });

  useMemo(() => {
    setFirst(page * rows - rows);
  }, []);

  const UnitNameFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          value={options.value}
          name="name"
          onChange={({ name, value }) => {
            handleFilterChange({ name: name, value: value });
            options.filterCallback(value);
          }}
          placeholder="Enter Unit Name"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const UnitHeadFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          value={options.value}
          name="head_1"
          onChange={({ name, value }) => {
            handleFilterChange({ name: name, value: value });
            options.filterCallback(value);
          }}
          className="p-column-filter"
          ignoreLabel
          placeholder="Enter Unit Head"
        />
      </div>
    );
  };


  const CoHeadFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          value={options.value}
          name="head_3"
          onChange={({ name, value }) => {
            handleFilterChange({ name: name, value: value });
            options.filterCallback(value);
          }}
          className="p-column-filter"
          ignoreLabel
          placeholder="Enter Unit Co-Head"
        />
      </div>
    );
  };

  const OfficeCountFilter = (options) => {
    return (
      <div>
        <CustomNumberInput2
          col={12}
          value={options.value}
          name="office_count"
          onChange={({ name, value }) => {
            handleFilterChange({ name: name, value: value });
            options.filterCallback(value);
          }}
          optionLabel="name"
          className="p-column-filter"
          ignoreLabel
          placeholder="Enter No of Office"
        />
      </div>
    );
  };

  const handleFilterChange = ({ name, value }) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleSearch = (
    company,
    rows,
    page,
    search,
    order,
    name,
    head1,
    head3,
    officeCount
  ) => {
    setIsSearch(true);
    getOfficeUnitList(
      company,
      rows,
      page,
      search,
      order,
      name,
      head1,
      head3,
      officeCount
    );
  };

  const debouncedOnChange = useRef(debounce(handleSearch, 1000));

  const header = () => {
    return (
      <div className="w-12 md:w-7 flex justify-content-end gap-2 flex-wrap">
        <div className="w-12 md:w-8 ">
          <CustomSearch
            name="search"
            value={search}
            onChange={({ value }) => {
              // setRows(5);
              // setPage(1);
              setFirst(0);
              setSearch(value);
              debouncedOnChange.current(
                profile?.company,
                rows,
                1,
                value,
                sortField?.order + sortField?.field,
                filters?.name,
                filters?.head_1,
                filters?.head_3,
                filters?.office_count
              );
            }}
            isSearch={isSearch}
          />
        </div>
        {canAccess("add_officeunit") && (
          <CustomButton
            size="small"
            icon="pi pi-plus"
            label="Add Office Unit"
            onClick={() => navigate("/administrator/office-unit/add")}
          />
        )}
      </div>
    );
  };

  const getOfficeUnitList = (
    company,
    rows,
    page,
    search,
    order,
    name,
    head1,
    head3,
    officeCount
  ) => {
    dispatch(
      officeUnitListAction(
        company,
        rows,
        page,
        search,
        order,
        name,
        head1,
        head3,
        officeCount,
        (res) => {
          if (res?.success) {
            setUnitList(res?.data?.results);
            setCount(res?.data?.count);
            setIsSearch(false);
            navigate(
              `/administrator/office-unit/list?page=${page}&rows=${rows}`
            );
          }
        }
      )
    );
  };

  const actions = (d) => {
    return (
      <CustomOverlay extraClass={"text-center"}>
        <ul className="no-style p-0">
          <li
            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
            onClick={() => {
              navigate(`/administrator/office-unit/view/${d.id}`);
              dispatch(setOfficeUnitSlice(d));
            }}
          >
            <i className="pi pi-eye cursor-pointer text-dark"></i>
            View
          </li>
          {canAccess("delete_officeunit") && (
            <li className="flex gap-2 text-xs font-medium mb-3 cursor-pointer" onClick={() => {
              //handleUnitDelete(d?.id)
              setIdToDelete(d?.id);
              setDeleteModal(true);
            }}>
              <i
                className="pi pi-trash  text-dark"

              ></i>{" "}
              Delete
            </li>
          )}
        </ul>
      </CustomOverlay>
    );
  };

  const handleUnitDelete = () => {
    dispatch(
      unitDeleteAction(
        profile?.company,
        idToDelete,
        setLoading,
        setDeleteModal,
        dispatch,
        (res) => {
          getOfficeUnitList(
            profile?.company,
            rows,
            page,
            search,
            sortField?.order + sortField?.field,
            filters?.name,
            filters?.head_1,
            filters?.head_3,
            filters?.office_count
          );
        }
      )
    );
  };

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected office unit from the list?
        This action cannot be undone.
      </span>
    );
  };

  const onPageChange = (e) => {
    // setRows(e.rows);
    // setPage(e.page + 1);
    // navigate(`/administrator/office-unit/list?page=${e.page + 1}&rows=${e.rows}`);
    getOfficeUnitList(
      profile?.company,
      e.rows,
      e.page + 1,
      search,
      sortField?.order + sortField?.field,
      filters?.name,
      filters?.head_1,
      filters?.head_3,
      filters?.office_count
    );
    setFirst(e.first);
  };

  useEffect(() => {
    if (profile?.company) {
      getOfficeUnitList(
        profile?.company,
        rows,
        page,
        "",
        sortField?.order + sortField?.field,
        filters?.name,
        filters?.head_1,
        filters?.head_3,
        filters?.office_count
      );
    }
  }, [profile?.company, sortField]);

  const handleSort = (e) => {
    if (sortField?.order === "-") {
      setSortField({ field: e?.sortField, order: "" });
    } else {
      setSortField({ field: e?.sortField, order: "-" });
    }
  };

  const columns = useMemo(
    () => [
      {
        name: "Unit Name",
        accessor: "name",
        value: true,
        frozen: true,
        sortable: false,
        filter: true,
        filterElement: (option) => UnitNameFilter(option),
      },
      {
        name: "Unit Head",
        accessor: "head_1",
        value: true,
        sortable: false,
        filter: true,
        filterElement: (option) => UnitHeadFilter(option),
      },
      {
        name: "Unit Co-Head",
        accessor: "head_3",
        value: true,
        sortable: false,
        filter: true,
        filterElement: (option) => CoHeadFilter(option),
      },
      {
        name: "No of Office",
        accessor: "office_count",
        sortable: false,
        value: true,
        filter: true,
        filterElement: (option) => OfficeCountFilter(option),
      },
    ],
    []
  );

  const ActionTemplate = () => {
    return (
      <>
        <p className="flex gap-2 align-items-center">Action</p>
      </>
    );
  };

  const applyFilters = (options) => {
    options.filterApplyCallback();
    getOfficeUnitList(
      profile?.company,
      rows,
      1,
      "",
      sortField?.order + sortField?.field,
      filters?.name,
      filters?.head_1,
      filters?.head_3,
      filters?.office_count
    );
  };

  const clearFilter = (options) => {
    const field = options.field;
    setFilters((prev) => ({ ...prev, [field]: "" }));
    getOfficeUnitList(
      profile?.company,
      rows,
      1,
      "",
      sortField?.order + sortField?.field,
      field === "name" ? "" : filters?.name,
      field === "head_1" ? "" : filters?.head_1,
      field === "head_3" ? "" : filters?.head_3,
      field === "office_count" ? "" : filters?.office_count
    );
    options.filterClearCallback("");
  };


  return (
    <div>
      <CustomCard title="Office Unit List" header={header()}>
        <CustomTableContainer
          list={unitList}
          columns={columns}
          actions={actions}
          first={first}
          rows={rows}
          count={count}
          ActionTemplate={ActionTemplate}
          activeRow={officeUnit?.id}
          slice={setOfficeUnitSlice}
          // handleSort={handleSort}
          onPageChange={onPageChange}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          // sortField={sortField}
          filters={filters2}
          onApplyFilter={applyFilters}
          onClearFilter={clearFilter}
        />
      </CustomCard>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleUnitDelete}
        loading={loading}
      />
    </div>
  );
};

export default OfficeUnitList;
