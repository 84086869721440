import React from "react";
import {
  CustomDropDown,
  CustomForm,
  CustomNumberInput2,
  CustomSizeM2,
  NewCustomFileInput,
} from "shared/AllInputs";
import {
  BuildingTypeOptions,
  StairAccessOptions,
} from "shared/AllDropDownOptions";
import SizeM2 from "assets/images/svg/SizeM2.svg";
const BuildingStucture = ({
  data,
  handleChange,
  buildingOptions,
  // fileKey,
  // sturcureDocuments,
  // fileChange,
  fileDelete,
}) => {
  return (
    <>
      <CustomForm title="Building Structure">
        <CustomDropDown
          data={data}
          onChange={handleChange}
          name="building"
          options={buildingOptions}
          label="Select Building"
          placeholder="Select Building"
          required
        />
        <CustomDropDown
          data={data}
          onChange={handleChange}
          name="buildingType"
          options={BuildingTypeOptions}
          required
          disabled={data?.building !== "add_new"}
        />
        <CustomNumberInput2
          data={data}
          onChange={handleChange}
          name="buildingMaxFloor"
          max={3}
          required
        />
        <CustomDropDown
          data={data}
          onChange={handleChange}
          name="staircaseAccess"
          options={StairAccessOptions}
          required
        />
        <CustomSizeM2
          data={data}
          onChange={handleChange}
          name="size"
          label={"Size"}
          placeholder={"Enter Size"}
          spanExtraClassName="p-1"
          max={9}
          required
          spanIcon={SizeM2}
        />
      </CustomForm>
      <CustomForm title="Upload Document">
        <NewCustomFileInput
          col={6}
          onChange={handleChange}
          data={data}
          selectedFiles={data.document}
          onFileDelete={fileDelete}
          // inputKey={fileKey}
          type="file"
          name="buildingEnergyCertificate"
          ignoreLabel
          documentType="building_energy_certificate"
        />

        {/* <CustomFileInput
          data={data}
          selectedFiles={sturcureDocuments}
          inputKey={fileKey}
          type="file"
          name="document"
          onChange={fileChange}
        /> */}

        {/* {sturcureDocuments?.length > 0 && (
            <ul className="p-3 m-0 list-none">
              {sturcureDocuments?.map((file, index) => (
                <li className="border-none w-6 mb-2 grid align-items-center upload-list" key={index} >
                  <div className="col-11">
                    {file?.name || file?.document}{" "}
                  </div> */}

        {/* <i
                    onClick={() => fileDelete(index)}
                    className="pi pi-times"
                    style={{ color: "red", fontSize: "1rem", cursor: "pointer" }}
                  ></i> */}
        {/* <div className="col-1">
                    <i className="pi pi-ellipsis-v cursor-pointer"  onClick={(e) => op.current.toggle(e)} ></i>
                    <OverlayPanel ref={op}>
                     <ul className="list-none p-0 m-0 p-2">
                      <li className="mb-2">
                        <Link className="no-underline text-sm text-black-alpha-90" to="">View</Link>
                      </li>
                      <li className="mb-2">
                        <Link className="no-underline text-sm text-black-alpha-90" to="">Delete</Link>
                      </li>
                     </ul>
                    </OverlayPanel>
                  </div>
                </li>
              ))}
            </ul>
          )} */}
        {/* </NewCustomFileInput> */}
      </CustomForm>
    </>
  );
};

export default React.memo(BuildingStucture);
