import React, { useEffect, useState } from "react";
import {
  CustomCalander,
  CustomForm,
  CustomInput,
} from "../../shared/AllInputs";
import CustomButton from "../../shared/CustomButton";
import {
  HandleAddNew,
  handleChangeNew,
  handleDeleteNew,
  handlEdit,
  handleSaveNew,
} from "./commonFunctions";
import { useDispatch } from "react-redux";
import {
  addEnergyClockAction,
  deleteEnergyClockAction,
  getEnergyClocksListAction,
  updateEnergyClockAction,
} from "../../store/actions/buildingUnitActions";
import { handleFormatDate, reformatDateString } from "../../utils/commonFunctions";
import CustomOverlay from "shared/CustomOverlay";
import CustomModal from "shared/Modals/CustomModal";
import CrossIcon from "assets/images/svg/cross-icon.svg";
import SaveIcon from "assets/images/svg/Save-icon.svg";
import formValidation from "utils/validations";
const AddEnergyClocks = ({ meterId }) => {
  const dispatch = useDispatch();
  const [allClocks, setAllClocks] = useState([
    {
      id: "",
      date: "",
      amount: "",
    },
  ]);
  const InputObj = {
    date: "",
    amount: "",
  };

  // const errorObj = {
  //   date: "",
  //   amount: "",
  // };

  const ignoreInputs = ["status", "id"];
  const [errors, setErrors] = useState([]);
  const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (allClocks?.length) {
  //     let allErrors = [...errors];
  //     let myErrors = allClocks?.map((clock, clockIndex) => {
  //       let error = allErrors.find(
  //         (err, errorIndex) => errorIndex === clockIndex
  //       );
  //       if (error) {
  //         return error;
  //       } else {
  //         return errorObj;
  //       }
  //     });
  //     setErrors(myErrors);
  //   }
  // }, [allClocks]);

  useEffect(() => {
    if (meterId) {
      getClocksList(meterId);
    }
  }, [meterId]);

  const getClocksList = (id) => {
    dispatch(
      getEnergyClocksListAction(id, (res) => {
        if (res?.success) {
          const result = res?.data?.results;
          const list = result?.map((clock) => {
            return {
              id: clock?.id,
              date: reformatDateString(clock?.date),
              amount: clock?.amount,
              status: "save",
            };
          });
          if (list.length) {
            setAllClocks(list);
          }
        }
      })
    );
  };

  const header = (
    <div className="">
      <CustomButton
        type="button"
        extraClassNames=""
        label="Add Energy Clock"
        onClick={() =>
          HandleAddNew(
            InputObj,
            allClocks,
            setAllClocks,
          )
        }
      />
    </div>
  );

  const handleAddClock = (clock, index) => {
    const dateError = formValidation("date", clock?.date, ["id", "status"]);
    const amountError = formValidation("amount", clock?.amount, ["id", "status"]);
    // return
    // let hasErrors = handleSave(
    //   allClocks,
    //   setAllClocks,
    //   errors,
    //   setErrors,
    //   ignoreInputs,
    //   index
    // );

    // let hasErrors = handleSaveNew(index, allClocks, setAllClocks, ignoreInputs);
    // let hasErrors = checkEnergyClockErrors(allClocks, setAllClocks, ["id", "status"]);

    if (!dateError?.date && !amountError?.amount) {
      let data = {
        energy_meter: meterId,
        date: handleFormatDate(clock?.date),
        amount: clock.amount,
      };
      if (clock?.id) {
        dispatch(
          updateEnergyClockAction(clock?.id, data, (res) => {
            if (res?.success) {
            } else {
              handlEdit(allClocks, setAllClocks, index);
            }
          })
        );
      } else {
        dispatch(
          addEnergyClockAction(data, (res) => {
            if (res) {
              if (res?.success) {
                const values = [...allClocks];
                values[index]["id"] = res?.data?.results?.id;
                setAllClocks(values);
                handleSaveNew(index, allClocks, setAllClocks, ignoreInputs)
              }
            }
          })
        );
      }
    }
  };

  const deleteClock = (id, index) => {
    dispatch(
      deleteEnergyClockAction(id, (res) => {
        if (res?.success) {
          // handleDelete(allClocks, setAllClocks, errors, setErrors, index);
          handleDeleteNew(allClocks, setAllClocks, index);
        } else {
          // msgs?.current?.show({
          //   severity: "error",
          //   detail: res?.message,
          // });
        }
      })
    );
  };

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected energy clock from the list?
        This action cannot be undone.
      </span>
    );
  };

  const handleDeleteClock = () => {
    if (idToDelete?.id) {
      setLoading(true);
      dispatch(
        deleteEnergyClockAction(idToDelete?.id, (res) => {
          if (res) {
            handleDeleteNew(allClocks, setAllClocks, idToDelete?.index);
            setIdToDelete({ id: "", index: "" });
            setDeleteModal(false);
            setLoading(false)
          }
        })
      );
    } else {
      handleDeleteNew(allClocks, setAllClocks, idToDelete?.index);
      setIdToDelete({ id: "", index: "" });
      setDeleteModal(false);
    }
  };

  return (
    <>
      <CustomForm col={12} header={header}>
        <div className="overflow-auto">
          <table className="financial-table w-full mt-7">
            <thead>
              <tr>
                <th>Date</th>
                <th>Amount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody id="financial-table-form">
              {allClocks?.map((clock, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td>
                        <CustomCalander
                          col={12}
                          data={clock}
                          panelStyle={{marginTop: '100px '}}
                          onChange={
                            (e) =>
                              handleChangeNew(
                                e?.target?.name,
                                e?.target?.value,
                                index,
                                allClocks,
                                setAllClocks,
                                ignoreInputs
                              )
                            // handleObjChange(
                            //   allClocks,
                            //   setAllClocks,
                            //   errors,
                            //   setErrors,
                            //   ignoreInputs,
                            //   e?.target?.name,
                            //   e?.target?.value,
                            //   index
                            // )
                          }
                          name="date"
                          ignoreLabel
                          disabled={clock?.status === "save" ? true : false}
                        />
                      </td>
                      <td>
                        <CustomInput
                          data={clock}
                          col={12}
                          onChange={
                            (e) =>
                              handleChangeNew(
                                e?.target?.name,
                                e?.target?.value,
                                index,
                                allClocks,
                                setAllClocks,
                                ignoreInputs
                              )

                            // handleObjChange(
                            //   allClocks,
                            //   setAllClocks,
                            //   errors,
                            //   setErrors,
                            //   ignoreInputs,
                            //   e?.target?.name,
                            //   e?.target?.value,
                            //   index
                            // )
                          }
                          name="amount"
                          disabled={clock?.status === "save" ? true : false}
                          ignoreLabel
                        />
                      </td>
                      {clock?.status === "save" ? (
                        // <td>
                        //   <i
                        //     className="pi pi-ellipsis-v cursor-pointer text-dark"
                        //     onClick={() =>
                        //       handlEdit(allClocks, setAllClocks, index)
                        //     }
                        //   ></i>
                        // </td>
                        <td>
                          <CustomOverlay>
                            <ul className="no-style p-0">
                              <li
                                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                                onClick={() =>
                                  handlEdit(allClocks, setAllClocks, index)
                                }
                              >
                                <i className="pi pi-file-edit text-dark"></i>
                                Edit
                              </li>
                              {/* )} */}
                              <li
                                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                                onClick={() => {
                                  setIdToDelete({
                                    id: clock?.id,
                                    index: index,
                                  });
                                  setDeleteModal(true);
                                }}
                              >
                                <i className="pi pi-trash cursor-pointer text-dark"></i>
                                Delete
                              </li>
                            </ul>
                          </CustomOverlay>
                        </td>
                      ) : (
                        <td>
                          <div className="d-flex gap-3">
                            <a
                              className="cursor-pointer"
                              onClick={() => {
                                handleSaveNew(
                                  index,
                                  allClocks,
                                  setAllClocks,
                                  ignoreInputs
                                )
                                handleAddClock(clock, index)
                              }
                              }
                            >
                              <img src={SaveIcon} />
                            </a>
                            <a
                              className="cursor-pointer"
                              onClick={() => {
                                if (clock.id) {
                                  deleteClock(clock?.id, index);
                                } else {
                                  handleDeleteNew(
                                    allClocks,
                                    setAllClocks,
                                    index
                                  );
                                }
                              }}
                            >
                              <img src={CrossIcon} />
                            </a>
                          </div>
                        </td>
                        // <td>
                        //   <div className="d-flex gap-3">
                        //     <a
                        //       onClick={() => {
                        //         handleAddClock(clock, index);
                        //       }}
                        //       className="cursor-pointer"
                        //     >
                        //       <svg
                        //         xmlns="http://www.w3.org/2000/svg"
                        //         width="1.5em"
                        //         height="1.5em"
                        //         viewBox="0 0 24 24"
                        //       >
                        //         <path
                        //           d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10s10-4.486 10-10S17.514 2 12 2zm-1.999 14.413l-3.713-3.705L7.7 11.292l2.299 2.295l5.294-5.294l1.414 1.414l-6.706 6.706z"
                        //           fill="#787878"
                        //         ></path>
                        //       </svg>
                        //     </a>
                        //     <a
                        //       className="cursor-pointer"
                        //       onClick={() => deleteClock(clock?.id, index)}
                        //     >
                        //       <svg
                        //         xmlns="http://www.w3.org/2000/svg"
                        //         width="1.5em"
                        //         height="1.5em"
                        //         viewBox="0 0 24 24"
                        //       >
                        //         <path
                        //           fill="#787878"
                        //           d="M19.1 4.9C15.2 1 8.8 1 4.9 4.9S1 15.2 4.9 19.1s10.2 3.9 14.1 0s4-10.3.1-14.2m-4.3 11.3L12 13.4l-2.8 2.8l-1.4-1.4l2.8-2.8l-2.8-2.8l1.4-1.4l2.8 2.8l2.8-2.8l1.4 1.4l-2.8 2.8l2.8 2.8z"
                        //         ></path>
                        //       </svg>
                        //     </a>
                        //   </div>
                        // </td>
                      )}
                    </tr>
                    {/* {(errors[index]?.doors || errors[index]?.size) && (
                      <tr>
                        <td></td>
                        <td>
                          <small className="p-error">
                            {errors[index]?.doors}
                          </small>
                        </td>
                        <td>
                          <small className="p-error">
                            {errors[index]?.size}
                          </small>
                        </td>
                      </tr>
                    )} */}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <br />
        <CustomModal
          isOpen={deleteModal}
          setIsOpen={setDeleteModal}
          heading={"Delete"}
          body={modalContent}
          submitLabel={"Confirm"}
          onSubmit={handleDeleteClock}
          loading={loading}
        />
      </CustomForm>
    </>
  );
};

export default AddEnergyClocks;
