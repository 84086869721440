import React, { useRef, useState } from "react";
import { CustomDropDown, CustomForm } from "shared/AllInputs";
import CustomButton from "shared/CustomButton";
import {
  HandleAddNew,
  handleCancelNew,
  handleChangeNew,
  handleDeleteNew,
  handlEdit,
  handleSaveNew,
} from "./commonFunctions";
import {
  FurnitureBathopt,
  FurnitureKitchenOpt,
  FurnitureOtheropt,
  FurnitureType,
} from "../AllDropDownOptions";
import CustomOverlay from "../CustomOverlay";
import CustomModal from "../Modals/CustomModal";
import { useDispatch } from "react-redux";
import {
  deleteFurnitureAction,
  getFurnitureAction,
} from "store/actions/landlordActions";
import CrossIcon from "assets/images/svg/cross-icon.svg";
import SaveIcon from "assets/images/svg/Save-icon.svg";
import { showToastAction } from "store/slices/commonSlice";

const AddFurniture = ({ title, landlordId, propertyId, allValues, setAllValues }) => {
  const openAction = useRef(null);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });
  const InputObj = {
    type: "Kitchen",
    item: "",
    status: "",
    property: propertyId || "",
    landlord: landlordId || "",
  };

  const ignoreInputs = ["property", "landlord", "status", "id"];
  const [deleteModal, setDeleteModal] = useState(false);
  const dispatch = useDispatch();

  const header = (
    <div className="w-12 flex justify-content-end flex-wrap">
      <CustomButton
        type="button"
        label="Add"
        onClick={() => HandleAddNew(InputObj, allValues, setAllValues)}
      />
    </div>
  );

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected furniture from the list?
        This action cannot be undone.
      </span>
    );
  };

  const handleFurnitureDelete = () => {
    if (idToDelete?.id) {
      dispatch(
        deleteFurnitureAction(idToDelete?.id, setLoading, dispatch, (res) => {
          handleDeleteNew(allValues, setAllValues, idToDelete?.index);

          setIdToDelete({ id: "", index: "" });
          setDeleteModal(false);
        })
      );
    } else {
      handleDeleteNew(allValues, setAllValues, idToDelete?.index);

      setIdToDelete({ id: "", index: "" });
      setDeleteModal(false);
    }
  };

  const getFunrnitureInfo = (id, index) => {
    dispatch(
      getFurnitureAction(id, (res) => {
        if (res?.success) {
          const info = res?.data?.results;
          handleCancelNew(info, index, allValues, setAllValues);
        }
      })
    );
  };

  return (
    <>
      <CustomForm
        title={title}
        header={header}
        formClass={"justify-content-end"}
      >
        <div className="overflow-auto">
          <table className="financial-table w-full mt-3 table-error-align">
            <thead>
              <tr>
                <th style={{ width: "45%" }}>Type</th>
                <th style={{ width: "45%" }}>Item</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody id="financial-table-form">
              {allValues?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td>
                        <CustomDropDown
                          value={item?.type}
                          onChange={(e) =>
                            handleChangeNew(
                              e?.target?.name,
                              e?.target?.value,
                              index,
                              allValues,
                              setAllValues,
                              ignoreInputs
                            )
                          }
                          name="type"
                          col={12}
                          options={FurnitureType}
                          disabled={item?.status === "save" ? true : false}
                          ignoreLabel
                          errorMessage={item?.formErrors?.type}
                        />
                      </td>
                      <td>
                        <CustomDropDown
                          value={item?.item}
                          onChange={(e) =>
                            handleChangeNew(
                              e?.target?.name,
                              e?.target?.value,
                              index,
                              allValues,
                              setAllValues,
                              ignoreInputs
                            )
                          }
                          name="item"
                          col={12}
                          options={
                            item?.type === "Kitchen"
                              ? FurnitureKitchenOpt
                              : item.type === "Bath"
                                ? FurnitureBathopt
                                : FurnitureOtheropt
                          }
                          disabled={item?.status === "save" ? true : false}
                          ignoreLabel
                          errorMessage={item?.formErrors?.item}
                        />
                      </td>
                      {item?.status === "save" ? (
                        <td>
                          <CustomOverlay ref={openAction}>
                            <ul className="no-style p-0">
                              <li
                                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                                onClick={() =>
                                  handlEdit(allValues, setAllValues, index)
                                }
                              >
                                <i className="pi pi-file-edit text-dark"></i>
                                Edit
                              </li>
                              <li
                                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                                onClick={() => {
                                  if (allValues.length < 2) {
                                    dispatch(showToastAction({
                                      type: "error",
                                      detail: "Minimum one furniture is required",
                                    }))
                                  } else {
                                    setIdToDelete({ id: item?.id, index: index });
                                    setDeleteModal(true);
                                  }
                                }}
                              >
                                <i className="pi pi-trash text-dark"></i>
                                Delete
                              </li>
                            </ul>
                          </CustomOverlay>
                        </td>
                      ) : (
                        <td>
                          <div className="d-flex gap-3">
                            <a
                              className="cursor-pointer"
                              onClick={() => {
                                handleSaveNew(
                                  index,
                                  allValues,
                                  setAllValues,
                                  ignoreInputs
                                )
                              }}
                            >
                              <img src={SaveIcon} />
                            </a>
                            <a
                              className="cursor-pointer"
                              onClick={() => {
                                if (allValues.length < 2) {
                                  dispatch(showToastAction({
                                    type: "error",
                                    detail: "Minimum one furniture is required",
                                  }))
                                } else {
                                  if (item.id) {
                                    getFunrnitureInfo(item.id, index);
                                  } else {
                                    handleDeleteNew(
                                      allValues,
                                      setAllValues,
                                      index
                                    );
                                  }
                                }
                              }}
                            >
                              <img src={CrossIcon} />
                            </a>
                          </div>
                        </td>
                      )}
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <br />
      </CustomForm>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleFurnitureDelete}
        loading={loading}
      />
    </>
  );
};

export default AddFurniture;
