import React from "react";
import { capitalizeCamelCase } from "../utils/commonFunctions";
import { Tooltip } from "primereact/tooltip";

export default function InputLayout({
  label,
  name,
  required,
  col,
  extraClassName = "",
  errorMessage,
  data,
  children,
  ignoreLabel,
  tooltipContent,
  note,
  ignoreError
}) {
  col = parseInt(col);
  if (col > 12) {
    col = 12;
  }

  return (
    <div className={`input-layout col-12  xl:col-${col} ${extraClassName}`}>
      {<label htmlFor={name} className="text-sm font-semibold">
        <div className="mb-1">
          {!ignoreLabel ? (label ? label : capitalizeCamelCase(name)) : null}
          {!ignoreLabel && required ? (
            <span className="text-red-500">*</span>
          ) : null}
        </div>
      </label>}

      {children}

      {(!ignoreError) && (errorMessage || data?.formErrors?.[name]) ? (
        <small className="p-error">
          {errorMessage || data?.formErrors?.[name]}
        </small>
      ) : null}
      {note || data?.note?.[name] && <div
        className={`flex gap-2 mt-2 ${note || data?.note?.[name] ? "justify-content-between" : "justify-content-end"
          }`}
      >
        {note || data?.note?.[name] ? <small className="p-error">{note || data?.note?.[name]}</small> : null}
        {tooltipContent && (
          <>
            {" "}
            <Tooltip
              className="tooltip"
              target={`.tooltip-${name}`}
              content={tooltipContent}
            />
            <i
              className={`pi pi-exclamation-circle mr-2 cursor-pointer tooltip-${name} p-error`}
              data-pr-classname="tooltip"
            />
          </>
        )}
      </div>}
    </div>
  );
}
