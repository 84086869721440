import React, { useEffect, useMemo, useRef, useState } from "react";
import CustomCard from "shared/CustomCard";
import { CustomInput, CustomSearch } from "shared/AllInputs";
import CustomButton from "shared/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTableContainer from "../../Tables/CustomTableContainer";
import CustomOverlay from "shared/CustomOverlay";
import CustomModal from "shared/Modals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { LandoardTypes } from "shared/AllDropDownOptions";
import {
  deleteLandlordAction,
  landlordListAction,
} from "store/actions/landlordActions";
import { debounce } from "lodash";
import { canAccess } from "services/auth";
import { Tooltip } from "primereact/tooltip";
import { truncateText } from "utils/regex";
import { setLandlordSlice } from "store/slices/landlordSlice";
import { setListDetailSlice } from "store/slices/listSlices";
import manageColICon from "assets/images/icons/manage-col-icon.png";

const LandlordList = () => {
  const openAction = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [count, setCount] = useState(0);
  const [first, setFirst] = useState(0);
  const [idToDelete, setIdToDelete] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [landlordList, setLandlordList] = useState();
  const { landlord } = useSelector((state) => state?.landlord);
  const globalFilters = [
    "landlord_name",
    "type",
    "address",
    "email",
    "phone_number",
  ];
  const [filters, setFilters] = useState({
    landlord_name: { value: "" },
    type: { value: "" },
    address: { value: "" },
    email: { value: "" },
    phone_number: { value: "" },
  });
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = query.get("page") || 1;
  const rows = query.get("rows") || 10;
  const { profile } = useSelector((state) => state.user);
  const [selectedItems, setSelectedItems] = useState();
  const [columns, setColumns] = useState([]);
  useMemo(() => {
    setFirst(page * rows - rows);
  }, []);

  const header = () => {
    return (
      <div className="w-12 md:w-8 flex justify-content-end flex-wrap align-items-center">
        <div className="w-12 md:w-8 mr-3">
          <CustomSearch
            name="search"
            value={search}
            onChange={({ value }) => {
              setFirst(0);
              setSearch(value);
              debouncedOnChange.current(
                profile?.company,
                10,
                1,
                value,
                filters?.landlord_name?.value,
                filters?.type?.value,
                filters?.address?.value,
                filters?.email?.value,
                filters?.phone_number?.value
              );
            }}
            isSearch={isSearch}
            placeholder="Search Landlord"
          />
        </div>
        {canAccess("add_landlord") && (
          <CustomButton
            size="small"
            icon="pi pi-plus"
            label="Add Landlord"
            onClick={() => navigate("/real-estate/landlord/add")}
          />
        )}
      </div>
    );
  };

  const NameFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          value={options.value}
          name="name"
          onChange={(e) => {
            options.filterCallback(e.value);
          }}
          optionLabel="name"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const AddressFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          value={options.value}
          name="address"
          onChange={(e) => {
            options.filterCallback(e.value);
          }}
          optionLabel="name"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const TypeFilter = (options) => {
    return (
      <div>
        <Dropdown
          value={options.value}
          options={LandoardTypes}
          onChange={(e) => {
            options.filterCallback(e.value);
          }}
          optionLabel="name"
          placeholder="Select Type"
          className="p-column-filter"
        />
      </div>
    );
  };

  const EmailFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          value={options.value}
          name="email"
          onChange={(e) => {
            options.filterCallback(e.value);
          }}
          optionLabel="name"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const ContactFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          value={options.value}
          name="phone_number"
          onChange={(e) => {
            options.filterCallback(e.value);
          }}
          optionLabel="name"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const addressBodyTemplate = (options) => {
    return (
      <div>
        <Tooltip
          className="tooltip"
          target={`.tooltip-${options?.id}`}
          content={options?.address}
        />
        <span
          className={`tooltip-${options?.id}`}
          data-pr-classname="tooltip"
          style={{ cursor: "pointer" }}
        >
          <span
          // style={{
          //   color: options?.id === landlord?.id ? "white" : null,
          // }}
          >
            {truncateText(options?.address, 20)}
          </span>
        </span>
      </div>
    );
  };

  const columnOptionsInitial = useMemo(
    () => [
      {
        name: "Landlord ID",
        accessor: "landlord_id",
        value: true,
        frozen: true,
      },
      {
        name: "Name",
        accessor: "landlord_name",
        value: true,
        sortable: false,
        filter: true,
        filterElement: (option) => NameFilter(option),
      },
      {
        name: "Type",
        accessor: "type",
        value: true,
        sortable: false,
        filter: true,
        filterElement: (option) => TypeFilter(option),
      },
      // {
      //   name: "Address",
      //   accessor: "address",
      //   sortable: false,
      //   value: true,
      //   filter: true,
      //   body: (options) => addressBodyTemplate(options),
      //   filterElement: (option) => AddressFilter(option),
      // },
      {
        name: "Email",
        accessor: "email",
        sortable: false,
        value: true,
        filter: true,
        filterElement: (option) => EmailFilter(option),
      },
      {
        name: "Contact",
        accessor: "phone_number",
        ssortable: false,
        value: true,
        filter: true,
        filterElement: (option) => ContactFilter(option),
      },
      {
        name: "No of Bank Account",
        accessor: "totalBankAccounts",
        sortable: false,
        value: true,
      },
      {
        name: "No of Contacts",
        accessor: "totalContacts",
        sortable: false,
        value: true,
      },
      { name: "Street", accessor: "street", value: true },
      { name: "Zipcode", accessor: "zip_code", value: true },
      { name: "City", accessor: "city", value: false },
      { name: "City Division", accessor: "city_division", value: false },
      { name: "Country", accessor: "country", value: false },
    ],
    [landlord]
  );

  const [columnOptions, setColumnOptions] = useState(columnOptionsInitial);

  const actions = (d) => {
    return (
      <CustomOverlay extraClass={"text-center"}>
        <ul className="no-style p-0">
          <li
            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
            onClick={() => {
              navigate(`/real-estate/landlord/view/${d.id}`);
              dispatch(setLandlordSlice(d));
            }}
          >
            <i className="pi pi-eye cursor-pointer text-dark"></i>
            View
          </li>
          {canAccess("delete_landlord") && (
            <li className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
              onClick={() => {
                setIdToDelete(d?.id);
                setDeleteModal(true);
              }}
            >
              <i
                className="pi pi-trash  text-dark"
              ></i>{" "}
              Delete
            </li>
          )}
        </ul>
      </CustomOverlay>
    );
  };

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected landlord from the list?
        This action cannot be undone.
      </span>
    );
  };

  const handleLandlordDelete = () => {
    dispatch(
      deleteLandlordAction(
        profile?.company,
        idToDelete,
        setLoading,
        setDeleteModal,
        dispatch,
        (res) => {
          getLandlordList(profile?.company, rows, page, search);
        }
      )
    );
  };

  const ActionTemplate = () => {
    return (
      <>
        <p
          className="flex gap-2 align-items-center"
          onClick={(e) => openAction.current.toggle(e)}
        >
          <img src={manageColICon} style={{ width: "27px" }} />
          <i className="pi pi-chevron-down"></i>
          {/* Action */}
        </p>
      </>
    );
  };

  const getLandlordList = (
    company,
    rows,
    page,
    search,
    // order,
    name,
    type,
    address,
    email,
    contact
  ) => {
    dispatch(
      landlordListAction(
        company,
        rows,
        page,
        search,
        // order,
        name,
        type,
        address,
        email,
        contact,
        (res) => {
          setLandlordList(res?.options);
          setCount(res?.count);
          setIsSearch(false);
          navigate(`/real-estate/landlord/list?page=${page}&rows=${rows}`);
        }
      )
    );
  };

  const handleSearch = (
    company,
    rows,
    page,
    search,
    // order,
    name,
    type,
    address,
    email,
    contact
  ) => {
    setIsSearch(true);
    getLandlordList(
      company,
      rows,
      page,
      search,
      // order,
      name,
      type,
      address,
      email,
      contact
    );
  };

  const debouncedOnChange = useRef(debounce(handleSearch, 1000));

  useEffect(() => {
    if (profile?.company) {
      getLandlordList(
        profile?.company,
        rows,
        page,
        search,
        filters?.landlord_name?.value,
        filters?.type?.value,
        filters?.address?.value,
        filters?.email?.value,
        filters?.phone_number?.value
      );
    }
  }, [
    profile?.company,
    // rows,
    // page,
    // first,
    filters?.landlord_name?.value,
    filters?.type?.value,
    filters?.address?.value,
    filters?.email?.value,
    filters?.phone_number?.value,
  ]);

  const onPageChange = (e) => {
    // navigate(`/real-estate/landlord/list?page=${e.page + 1}&rows=${e.rows}`);
    getLandlordList(
      profile?.company,
      e.rows,
      e.page + 1,
      search,
      filters?.landlord_name?.value,
      filters?.type?.value,
      filters?.address?.value,
      filters?.email?.value,
      filters?.phone_number?.value
    );
    dispatch(setListDetailSlice({ page: e.page + 1, row: e.rows }));
    setFirst(e.first);
  };

  useEffect(() => {
    let filterdCols = columnOptions.filter((col) => col.value === true);
    setColumns(filterdCols);
  }, []);

  return (
    <div>
      <CustomCard title="Landlord List" header={header()}>
        <CustomTableContainer
          list={landlordList}
          columns={columns}
          actions={actions}
          first={first}
          rows={rows}
          count={count}
          ActionTemplate={ActionTemplate}
          activeRow={landlord?.id}
          slice={setLandlordSlice}
          globalFilters={globalFilters}
          handleSort={""}
          onPageChange={onPageChange}
          filters={filters}
          setFilters={setFilters}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          openAction={openAction}
          columnOptions={columnOptions}
          setColumnOptions={setColumnOptions}
          setColumns={setColumns}
          firstCol="Landlord ID"
        />
      </CustomCard>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleLandlordDelete}
        loading={loading}
      />
    </div>
  );
};

export default LandlordList;
