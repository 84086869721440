import React, { useRef, useState } from "react";
import InputLayout from "./InputLayout";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";
import { Calendar } from "primereact/calendar";
import { InputSwitch } from "primereact/inputswitch";
import FileIcon from "../assets/images/icons/file-icon.svg";
import {
  capitalizeCamelCase,
  getFileExtension,
  getFileName,
  getFileNameExtension,
} from "../utils/commonFunctions";
import { InputOtp } from "primereact/inputotp";
import { MultiSelect } from "primereact/multiselect";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
// import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { InputNumber } from "primereact/inputnumber";
// import { startsWith } from "lodash";
import { AutoComplete } from "primereact/autocomplete";
import { number, truncateText } from "../utils/regex";
import { NumericFormat } from "react-number-format";
import { InputTextarea } from "primereact/inputtextarea";
import { Rating } from "primereact/rating";
import UploadImg from "../assets/images/icons/uploadclound.svg";
import CustomOverlay from "./CustomOverlay";
import constants from "constants";

const baseURL = constants.baseUrl;

export const CustomInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  spanLabel,
  ignoreLabel,
  isSearch,
  tooltipContent,
  note,
  ignoreError,
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
      ignoreLabel={ignoreLabel}
      tooltipContent={tooltipContent}
      note={note}
      ignoreError={ignoreError}
    >
      <div className="input-box ">
        <InputText
          id={name}
          name={name}
          value={value || data?.[name] || ""}
          type={type}
          onChange={(e) =>
            onChange &&
            onChange({ ...e, name: e.target.name, value: e.target.value })
          }
          className={`input w-full mb-1 ${inputClass ? inputClass : ""} ${
            errorMessage ? "p-invalid" : ""
          }`}
          placeholder={placeholder || `Enter ${capitalizeCamelCase(name)}`}
          disabled={disabled}
          {...props}
        />
        {isSearch && <i className="pi pi-spin pi-spinner" />}
        {spanLabel && <span className="p-inputgroup-addon">{spanLabel}</span>}
      </div>
    </InputLayout>
  );
};

export const CustomSizeM2 = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  spanIcon,
  ignoreLabel,
  spanExtraClassName,
  max,
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={`input-group-box ${extraClassName}`}
      data={data}
      errorMessage={errorMessage}
      ignoreLabel={ignoreLabel}
    >
      <div className="p-inputgroup flex">
        <InputText
          id={name}
          name={name}
          value={value || data?.[name] || ""}
          type={type}
          onChange={(e) => {
            if (onChange) {
              if (number(e.target.value)) {
                onChange({
                  ...e,
                  name: e.target.name,
                  value: e.target.value,
                });
              } else if (!e.target.value) {
                onChange({
                  ...e,
                  name: e.target.name,
                  value: e.target.value,
                });
              }
            }
          }}
          className={`input w-full mb-1 ${inputClass ? inputClass : ""} ${
            errorMessage ? "p-invalid" : ""
          }`}
          placeholder={placeholder || `Enter ${capitalizeCamelCase(name)}`}
          disabled={disabled}
          maxLength={max}
          {...props}
        />
        {spanIcon && (
          <span className={`p-inputgroup-addon ${
            spanExtraClassName ? spanExtraClassName : ""
          }`}>
            <img src={spanIcon} />
          </span>
        )}
      </div>
    </InputLayout>
  );
};

export const CustomNumberInput2 = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  spanLabel,
  ignoreLabel,
  spanExtraClassName,
  max,
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
      ignoreLabel={ignoreLabel}
    >
      <div className="p-inputgroup flex">
        <InputText
          id={name}
          name={name}
          value={value || data?.[name] || ""}
          type={type}
          onChange={(e) => {
            if (onChange) {
              if (number(e.target.value)) {
                onChange({ ...e, name: e.target.name, value: e.target.value });
              } else if (!e.target.value) {
                onChange({ ...e, name: e.target.name, value: e.target.value });
              }
            }
          }}
          className={`input w-full mb-1 ${inputClass ? inputClass : ""} ${
            errorMessage ? "p-invalid" : ""
          }`}
          placeholder={placeholder || `Enter ${capitalizeCamelCase(name)}`}
          disabled={disabled}
          maxLength={max}
          {...props}
        />
        {spanLabel && (
          <span
            className={`p-inputgroup-addon ${
              spanExtraClassName ? spanExtraClassName : ""
            }`}
          >
            {spanLabel}
          </span>
        )}
      </div>
    </InputLayout>
  );
};

export const CustomAmountInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  spanLabel,
  ignoreLabel,
  spanExtraClassName,
  spanIcon,
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
      ignoreLabel={ignoreLabel}
    >
      <div className="p-inputgroup flex w-full">
        <NumericFormat
          value={value || data?.[name]}
          thousandSeparator="."
          decimalSeparator=","
          // decimalScale={2}
          fixedDecimalScale={true}
          allowNegative={false}
          onValueChange={(e) => {
            if (onChange) {
              if (number(e.value)) {
                onChange({ ...e, name: name, value: e.value });
              } else if (!e.value) {
                onChange({ ...e, name: name, value: e.value });
              }
            }
          }}
          // prefix="₹"
          className={`input border-1 numeric_input w-full mb-1 ${
            inputClass ? inputClass : ""
          } ${errorMessage ? "p-invalid" : ""}`}
          placeholder={placeholder || `Enter ${capitalizeCamelCase(name)}`}
          disabled={disabled}
        />
        {spanLabel && (
          <span
            className={`p-inputgroup-addon ${
              spanExtraClassName ? spanExtraClassName : ""
            }`}
          >
            {spanLabel}
          </span>
        )}

        {spanIcon && (
          <span
            className={`p-inputgroup-addon ${
              spanExtraClassName ? spanExtraClassName : ""
            }`}
          >
            <img src={spanIcon} />
          </span>
        )}
      </div>
    </InputLayout>
  );
};

export const CustomNumberInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  spanLabel,
  style,
  ignoreLabel,
  min,
  max,
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
      ignoreLabel={ignoreLabel}
    >
      <InputNumber
        id={name}
        name={name}
        value={value || data?.[name] || 0}
        type={type}
        onValueChange={(e) =>
          onChange && onChange({ ...e, name: e?.target?.name, value: e.value })
        }
        className={`input w-full ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        placeholder={placeholder || `Enter ${capitalizeCamelCase(name)}`}
        mode="decimal"
        disabled={disabled}
        style={style}
        {...props}
        step={1}
        min={min}
        max={max}
      />
    </InputLayout>
  );
};

export const CustomFileInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  inputKey,
  selectedFiles,
  accept,
  isMulitple,
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      {/* <InputText
        id={name}
        name={name}
        type={type}
        onChange={(e) =>
          onChange &&
          onChange({ ...e, name: e.target.name, value: e.target.files[0] })
        }
        className={`input w-full ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        placeholder={placeholder || `Enter ${capitalizeCamelCase(name)}`}
        disabled={disabled}
        {...props}
        multiple={true}
        accept=".png, .jpg, .jpeg"
      /> */}
      <input
        disabled={disabled}
        key={inputKey}
        id="file-upload"
        className={inputClass}
        type="file"
        accept={accept || ".jpg,.jpeg,.png,.pdf"} //.doc,.xls,.zip
        onChange={onChange}
        onClick={(event) => {
          if (
            event.target.files.length === 1 &&
            event.target.files[0].name ===
              selectedFiles[selectedFiles.length - 1]?.name
          ) {
            event.target.value = null;
          }
        }}
        multiple={isMulitple}
      />
    </InputLayout>
  );
};

export const NewCustomFileInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  // inputKey,
  selectedFiles,
  accept,
  children,
  ignoreError,
  ignoreLabel,
  containerClass,
  documentType,
  onFileDelete,
  onFileView,
  documentLabel,
  ...props
}) => {
  const openImage = (imageUrl) => {
    window.open(imageUrl, "_blank");
  };

  return (
    // <InputLayout
    //   col={col}
    //   label={label}
    //   ignoreLabel={ignoreLabel}
    //   name={name}
    //   required={required}
    //   extraClassName={extraClassName}
    //   data={data}
    //   ignoreError={ignoreError}
    //   errorMessage={errorMessage}
    // >
    <div
      className={`h-auto upload-file-box h-auto ${
        selectedFiles?.length > 0 ? "uploaded-file" : ""
      } ${extraClassName}`}
    >
      <div className={`flex justify-content-between p-3`}>
        {(documentLabel || documentType) && (
          <div className="flex gap-3">
            {/* <i className="pi pi-file"></i> */}
            <img src={FileIcon} alt="" width={17} />
            <span className="text-sm font-semibold">
              {capitalizeCamelCase(documentLabel || documentType)}
            </span>
          </div>
        )}
        <div className="relative">
          <input
            disabled={disabled}
            // key={inputKey}
            id="file-upload"
            type="file"
            accept={accept || ".jpg,.jpeg,.png,.pdf"} //.doc,.xls,.zip
            // onChange={onChange}
            onChange={(e) =>
              onChange &&
              onChange({
                ...e,
                name: name,
                value: e?.target?.files[0],
                documentType: documentType,
              })
            }
            onClick={(event) => {
              if (
                event.target.files.length === 1 &&
                event.target.files[0].name ===
                  selectedFiles[selectedFiles.length - 1]?.name
              ) {
                event.target.value = null;
              }
            }}
            // multiple
          />
          <img src={UploadImg} alt="" />
        </div>
      </div>
      {/* {children} */}
      {selectedFiles && selectedFiles?.length > 0 && (
        <ul className="px-3 m-0 list-none">
          {selectedFiles?.map((file, index) =>
            file?.document ? (
              <li
                className="border-none w-full mb-2 grid align-items-center upload-list"
                key={index}
              >
                <div className="col-11">
                  {file?.document?.name
                    ? getFileNameExtension(file?.document?.name)
                    : `${truncateText(
                        getFileName(file?.document),
                        20
                      )}.${getFileExtension(file.document)}`}{" "}
                </div>

                {/* <i
                    onClick={() => fileDelete(index)}
                    className="pi pi-times"
                    style={{ color: "red", fontSize: "1rem", cursor: "pointer" }}
                  ></i> */}
                {(onFileDelete || file?.id) && (
                  <div className="col-1">
                    <CustomOverlay>
                      <ul className="list-none p-0 m-0 p-2">
                        {file?.id && (
                          <li
                            className="mb-2 cursor-pointer"
                            onClick={() => openImage(baseURL + file?.document)}
                          >
                            <i className="pi pi-eye  text-dark"></i> View
                          </li>
                        )}
                        {onFileDelete && (
                          <li
                            className="mb-2 cursor-pointer"
                            onClick={() => onFileDelete(index)}
                          >
                            <i className="pi pi-trash text-dark"></i> Delete
                          </li>
                        )}
                      </ul>
                    </CustomOverlay>
                  </div>
                )}
              </li>
            ) : null
          )}
        </ul>
      )}
    </div>
    // </InputLayout>
  );
};

export const CustomPhoneInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled,
  type = "text",
  placeholder = "",
  ignoreLabel,
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
      ignoreLabel={ignoreLabel}
    >
      <PhoneInput
        enableSearch
        disabled={disabled}
        id={name}
        name={name}
        defaultCountry="DE"
        placeholder={placeholder || `Enter ${capitalizeCamelCase(name)}`}
        value={value || data[name]}
        className={`input w-full phone-input ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        onChange={(value) => {
          onChange({ e: { type: "phone" }, name: name, value: value || "" });
        }}
      />{" "}
    </InputLayout>
  );
};

export const CustomPassword = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 12,
  inputClass,
  disabled = false,
  placeholder = "",
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <Password
        id={name}
        name={name}
        value={value || data?.[name] || ""}
        onChange={(e) =>
          onChange &&
          onChange({ ...e, name: e.target.name, value: e.target.value })
        }
        className={` ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        disabled={disabled}
        feedback={false}
        toggleMask
        placeholder={placeholder || `Enter ${capitalizeCamelCase(name)}`}
        {...props}
      />
    </InputLayout>
  );
};

export const CustomDropDown = ({
  label,
  name,
  onChange,
  data,
  value,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  optionLabel = "name",
  placeholder = "",
  multiple,
  ignoreLabel,
  colors,
  options,
  ...props
}) => {
  const selectedValueTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div className={getStatusClass(option?.name)}>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const getStatusClass = (status) => {
    if (colors) {
      switch (status) {
        case "Initiated":
        case "New":
          return "text-red-600";

        case "Back Office":
          return "text-yellow-500";

        case "Social Worker":
          return "text-blue-600";

        case "Follow-up":
          return "text-orange-500";

        case "Fixed":
        case "Pending":
          return "text-green-600";

        case "Handyman":
          return "text-cyan-500";

        default:
          return "";
      }
    } else {
      return "";
    }
  };

  const OptionsTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div className={getStatusClass(option?.name)}>{option.name}</div>
      </div>
    );
  };

  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
      ignoreLabel={ignoreLabel}
    >
      <Dropdown
        id={name}
        name={name}
        value={value || data?.[name]}
        onChange={(e) =>
          onChange && onChange({ ...e, name: e.target.name, value: e.value })
        }
        className={`w-full custom-dropdown mb-1 ${
          errorMessage ? "p-invalid" : ""
        }`}
        optionLabel={optionLabel || "name"}
        placeholder={placeholder || `Select ${capitalizeCamelCase(name)}`}
        valueTemplate={selectedValueTemplate}
        itemTemplate={OptionsTemplate}
        options={options.map(option => ({
          ...option,
          disabled: option.disabled || false // Disable conditionally
        }))}
        {...props}
        // filter
      />
    </InputLayout>
  );
};

export const CustomAutoComplete = ({
  label,
  name,
  onChange,
  data,
  value,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  optionLabel = "name",
  placeholder = "",
  multiple,
  ignoreLabel,
  suggestions,
  search,
  onClick,
  max,
  onLazyLoad,
  panelClassName,
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
      ignoreLabel={ignoreLabel}
    >
      <AutoComplete
        name={name}
        value={value || data?.[name]}
        suggestions={suggestions}
        completeMethod={search}
        inputClassName="w-full"
        panelClassName={panelClassName}
        className={`w-full custom-dropdown ${errorMessage ? "p-invalid" : ""}`}
        onChange={(e) =>
          onChange && onChange({ ...e, name: e.target.name, value: e.value })
        }
        {...props}
        placeholder={placeholder || `Enter ${capitalizeCamelCase(name)}`}
        maxLength={max}
        // virtualScrollerOptions={{ lazy: true, showLoader: true, itemSize: 10, onLazyLoad: onLazyLoad && onLazyLoad }}
      />
    </InputLayout>
  );
};

export const CustomMulitSelect = ({
  label,
  name,
  onChange,
  data,
  value,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  optionLabel = "name",
  placeholder = "",
  multiple,
  ignoreLabel,
  options,
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
      ignoreLabel={ignoreLabel}
    >
      <MultiSelect
        id={name}
        name={name}
        value={value || data?.[name]}
        onChange={(e) =>
          onChange && onChange({ ...e, name: e.target.name, value: e.value })
        }
        options={options}
        placeholder={placeholder || `Select ${capitalizeCamelCase(name)}`}
        maxSelectedLabels={3}
        className={`w-full custom-dropdown ${errorMessage ? "p-invalid" : ""}`}
        optionLabel={optionLabel}
      />
    </InputLayout>
  );
};

export const CustomCalander = ({
  label,
  name,
  data,
  value,
  onFocus,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  placeholder = "",
  ignoreLabel,
  ...props
}) => {
  const calendarRef = useRef(null);

  const handleIconClick = () => {
    if (calendarRef.current) {
      calendarRef.current.focus(); // Focus the calendar to open it
    }
  };

  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
      ignoreLabel={ignoreLabel}
    >
      <div className="calendar-box">
        <Calendar
          id={name}
          name={name}
          value={value || data?.[name] || ""}
          onFocus={onFocus}
          onChange={(e) => onChange && onChange({ ...e, name, value: e.value })}
          className={`w-full ${inputClass ? inputClass : ""}`}
          disabled={disabled}
          placeholder={placeholder || `Select ${capitalizeCamelCase(name)}`}
          ref={calendarRef}
          
          {...props}
          // dateFormat="dd/mm/yy"
          dateFormat="dd.mm.yy"
        />
        <i
          className="pi pi-calendar"
          onClick={handleIconClick}
          style={{ cursor: "pointer" }}
        />
      </div>
    </InputLayout>
  );
};

export const CustomYearInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  placeholder = "",
  ignoreLabel,
  ...props
}) => {
  const calendarRef = useRef(null);

  const handleIconClick = () => {
    if (calendarRef.current) {
      calendarRef.current.focus(); // Focus the calendar to open it
    }
  };

  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
      ignoreLabel={ignoreLabel}
    >
      <div className="calendar-box">
        <Calendar
          id={name}
          name={name}
          value={value || data?.[name] || ""}
          onChange={(e) => onChange && onChange({ ...e, name, value: e.value })}
          className={`w-full ${inputClass ? inputClass : ""}`}
          disabled={disabled}
          placeholder={placeholder || `Select ${capitalizeCamelCase(name)}`}
          ref={calendarRef}
          {...props}
          view="year"
          dateFormat="yy"
        />
        <i
          className="pi pi-calendar"
          onClick={handleIconClick}
          style={{ cursor: "pointer" }}
        />
      </div>
    </InputLayout>
  );
};

export const CustomTime = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  placeholder = "",
  ignoreLabel,
  // minHour,
  // minMinute,
  ...props
}) => {
  const calendarRef = useRef(null);

  const handleIconClick = () => {
    if (calendarRef.current) {
      calendarRef.current.focus(); // Focus the calendar to open it
    }
  };

  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
      ignoreLabel={ignoreLabel}
    >
      <div className="calendar-box">
        <Calendar
          id={name}
          name={name}
          value={value || data?.[name] || ""}
          onChange={(e) => onChange && onChange({ ...e, name, value: e.value })}
          className={`w-full ${inputClass ? inputClass : ""}`}
          disabled={disabled}
          placeholder={placeholder || `Select ${capitalizeCamelCase(name)}`}
          ref={calendarRef}
          {...props}
          timeOnly
          hourFormat="12"
          showTime
          // minHour={minHour}
          // minMinute={minMinute}
        />
        <i
          className="pi pi-clock"
          onClick={handleIconClick}
          style={{ cursor: "pointer" }}
        />
      </div>
    </InputLayout>
  );
};

export const CustomCheckbox = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col,
  inputClass,
  template,
  ...props
}) => {
  return (
    <InputLayout
      col={col || 6}
      name=""
      extraClassName={extraClassName}
      errorMessage={data?.formErrors?.[name]}
    >
      <Checkbox
        id={name}
        name={name}
        inputId={label}
        checked={value || data?.[name]}
        onChange={(e) =>
          onChange && onChange({ ...e, name: e.target.name, value: e.checked })
        }
        className={`checkbox ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        {...props}
      />
      {label && (
        <label htmlFor={label} className="ml-2">
          {label}
        </label>
      )}
      {template && <>{template}</>}
    </InputLayout>
  );
};

export const CustomRadioInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col,
  inputClass,
  template,
  checked,
  ...props
}) => {
  return (
    <InputLayout
      col={col || 6}
      name=""
      extraClassName={extraClassName}
      errorMessage={data?.formErrors?.[name]}
    >
      <Checkbox
        id={`${name}-${value}`}
        name={name}
        value={value}
        inputId={label}
        checked={checked}
        onChange={(e) =>
          onChange && onChange({ ...e, name: e?.target?.name, value: e.value })
        }
        className={`checkbox ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        {...props}
      />
      {label && (
        <label htmlFor={`${name}-${value}`} className="ml-2">
          {label}
        </label>
      )}
      {template && <>{template}</>}
    </InputLayout>
  );
};

export const CustomSwitch = ({
  label,
  name,
  data,
  value,
  onChange,
  col = 6,
  extraClassName,
  flexClass,
  ...props
}) => {
  return (
    <InputLayout extraClassName={extraClassName} col={col} name="">
      <div className={`flex ${flexClass}`}>
        <InputSwitch
          checked={value || data?.[name]}
          onChange={(e) => onChange && onChange({ ...e, name, value: e.value })}
          {...props}
        />
        <div htmlFor={label} className="ml-2 text-lg my-auto">
          {label ? capitalizeCamelCase(label) : label}
        </div>
      </div>
    </InputLayout>
  );
};

//New
export const CustomSearch = ({
  name,
  data,
  value,
  onChange,
  isSearch,
  placeholder,
}) => {
  return (
    <div className="search-box">
      <InputText
        id={name}
        className="w-full"
        placeholder={placeholder || "Search"}
        name={name}
        value={value || data?.[name] || ""}
        onChange={(e) =>
          onChange &&
          onChange({ ...e, name: e.target.name, value: e.target.value })
        }
      />
      {!isSearch ? (
        <i className="pi pi-search" />
      ) : (
        <i className="pi pi-spin pi-spinner" />
      )}
    </div>
  );
};

export const CustomOtpInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 12,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <InputOtp
        integerOnly
        length={6}
        value={value || data?.[name] || ""}
        name={name}
        onChange={(e) =>
          onChange && onChange({ ...e, name: name, value: e?.value })
        }
      />
    </InputLayout>
  );
};

export const CustomAddSubtractInput = ({
  label,
  name,
  data,
  // value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  ...props
}) => {
  const [value, setValue] = useState(0);

  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <InputText
        id={name}
        name={name}
        value={value || data?.[name] || ""}
        type={type}
        onChange={(e) =>
          onChange &&
          onChange({ ...e, name: e.target.name, value: e.target.value })
        }
        className={`input w-full ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        placeholder={placeholder || `Enter ${capitalizeCamelCase(name)}`}
        disabled={disabled}
        {...props}
      />
    </InputLayout>
  );
};

export const CustomForm = ({
  children,
  title,
  header,
  col,
  extraClassName,
  titleCol,
  formClass,
}) => {
  return (
    <form
      className={`${formClass} ${formClass} grid m-0 p-0 justify-content-between overflow-hidden w-full `}
    >
      {title ? (
        <div
          className={`col-${titleCol || "10"} title my-auto ${extraClassName}`}
        >
          {title}
        </div>
      ) : null}
      {header ? (
        <div className={`col-${col || "2"} title my-auto`}>{header}</div>
      ) : null}
      {children}
    </form>
  );
};

// export const CustomInputMask = ({
//   label,
//   name,
//   data,
//   value,
//   onChange,
//   errorMessage,
//   extraClassName,
//   required,
//   col = 6,
//   inputClass,
//   disabled = false,
//   type = "text",
//   placeholder = "",
//   spanLabel,
//   ignoreLabel,
//   ...props
// }) => {
//   return (
//     <InputLayout
//       col={col}
//       label={label}
//       name={name}
//       required={required}
//       extraClassName={extraClassName}
//       data={data}
//       errorMessage={errorMessage}
//       ignoreLabel={ignoreLabel}
//     >
//       <div className="p-inputgroup flex-1">
//         <InputNumber
//           id={name}
//           name={name}
//           value={value || data?.[name] || 0}
//           type={type}
//           onValueChange={(e) =>
//             onChange && onChange({ ...e, name: e.target.name, value: e.value })
//           }
//           className={`input w-full ${inputClass ? inputClass : ""} ${
//             errorMessage ? "p-invalid" : ""
//           }`}
//           placeholder={placeholder || `Enter ${capitalizeCamelCase(name)}`}
//           disabled={disabled}
//           {...props}
//         />
//         {spanLabel && <span className="p-inputgroup-addon">{spanLabel}</span>}
//       </div>
//     </InputLayout>
//   );
// };

export const CustomInputMask = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  spanLabel,
  spanIcon,
  ignoreLabel,
  max,
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
      ignoreLabel={ignoreLabel}
    >
      <div className="p-inputgroup flex-1">
        <InputText
          id={name}
          name={name}
          value={value || data?.[name]}
          type={type}
          onChange={(e) =>
            onChange &&
            onChange({ ...e, name: e.target.name, value: e.target.value })
          }
          className={`input w-full ${inputClass ? inputClass : ""} ${
            errorMessage ? "p-invalid" : ""
          }`}
          placeholder={placeholder || `Enter ${capitalizeCamelCase(name)}`}
          disabled={disabled}
          maxLength={max}
          {...props}
        />
        {spanLabel && <span className="p-inputgroup-addon">{spanLabel}</span>}
        {spanIcon && <span className="p-inputgroup-addon"><img src={spanIcon} /></span>}
      </div>
    </InputLayout>
  );
};

export const CustomTextArea = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  spanLabel,
  ignoreLabel,
  isSearch,
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
      ignoreLabel={ignoreLabel}
    >
      <div className="input-box ">
        <InputTextarea
          id={name}
          name={name}
          value={value || data?.[name] || ""}
          type={type}
          rows={5}
          cols={30}
          onChange={(e) =>
            onChange &&
            onChange({ ...e, name: e.target.name, value: e.target.value })
          }
          className={`input w-full ${inputClass ? inputClass : ""} ${
            errorMessage ? "p-invalid" : ""
          }`}
          placeholder={placeholder || `Enter ${capitalizeCamelCase(name)}`}
          disabled={disabled}
          {...props}
        />
        {isSearch && <i className="pi pi-spin pi-spinner" />}
        {spanLabel && <span className="p-inputgroup-addon">{spanLabel}</span>}
      </div>
    </InputLayout>
  );
};

export const CustomRatings = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  spanLabel,
  ignoreLabel,
  isSearch,
  count,
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
      ignoreLabel={ignoreLabel}
    >
      <div className="input-box flex gap-2 align-items-center">
        <Rating
          className="custom-rating"
          id={name}
          value={value || data?.[name]}
          onChange={(e) => {
            onChange && onChange({ ...e, name: name, value: e.value });
          }}
          cancel={false}
          {...props}
        />
        <span className="text-base">
          {(count == 0 || count) && `(${count})`}
        </span>
        {spanLabel && <span className="p-inputgroup-addon">{spanLabel}</span>}
      </div>
    </InputLayout>
  );
};
