import { handleFileConversion } from "utils/commonFunctions";
import api from "../../services/api";
import endPoints from "../../services/endPoints";
import { showToastAction } from "../slices/commonSlice";
import { setLandlordSlice } from "../slices/landlordSlice";
import { setPropertyLandlordSlice } from "../slices/propertySlice";

const addLandlordAction = (company, data, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    let payload = {
        landlord_name: data.landlordName,
        type: data.landlordType,
        address: data.address,
        house_number: data?.houseNo,
        street: data.street,
        zip_code: data.zipCode,
        city: data.city,
        city_division: data.cityDivision,
        country: data.country,
        // email: data.email,
        // phone_number: data.phoneNumber,
    }

    if(data.email){
        payload.email = data.email
    }

    if(data.phoneNumber){
        payload.phone_number = data.phoneNumber
    }

    // if(data?.existingLandlord){
    //     payload.landlord_id = data?.existingLandlord
    // }

    // if (data?.directDebit) {
    //     payload.direct_debit = data?.directDebit
    // }

    // if (data?.is_furniture) {
    //     payload.is_furniture = data?.furnitureProvided
    // }

    if (data?.propertyId) {
        payload.property = data?.propertyId
    }

    if (data?.bank_accounts?.length) {
        payload.bank_accounts = data?.bank_accounts;
    }

    if (data?.contacts?.length) {
        payload.contacts = data?.contacts;
    }

    // if (data?.landlord_furniture?.length) {
    //     payload.landlord_furniture = data?.landlord_furniture;
    // }

    const docs = []
    for (const document of data?.landlord_documents || []) {
        for (const [documentType, documentArray] of Object.entries(document)) {
            if (documentArray?.length > 0) {
                const documents = await Promise.all(documentArray.map(async (document) => {
                    if (typeof document.document == "object") {
                        return { type: document?.type, document: await handleFileConversion(document?.document) };
                    } else {
                        return document
                    }
                }));
                docs.push(...documents);
            }
        }
    }

    if(docs.length){
        payload.landlord_documents = docs
    }

    const res = await api(
        "post",
        endPoints?.LANDLORD + `${company}/`,
        payload
    );
    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        dispatch(setPropertyLandlordSlice(res?.data?.results))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false);
};

const updateLandlordAction = (company, landlord, data, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    let payload = {
        property: data?.propertyId,
        landlord_name: data.landlordName,
        type: data.landlordType,
        address: data.address,
        house_number: data?.houseNo,
        street: data.street,
        zip_code: data.zipCode,
        city: data.city,
        city_division: data.cityDivision,
        country: data.country,
        // email: data.email,
        // phone_number: data.phoneNumber,
    }


    if(data.email){
        payload.email = data.email
    }
    
    if(data.phoneNumber){
        payload.phone_number = data.phoneNumber
    }
    // if(data?.existingLandlord) { 
    //     payload.landlord_id = data?.existingLandlord
    // }
    
    // if (data?.directDebit) {
    //     payload.direct_debit = data?.directDebit
    // }

    // if (data?.is_furniture) {
    //     payload.is_furniture = data?.furnitureProvided
    // }

    if (data?.bank_accounts?.length) {
        payload.bank_accounts = data?.bank_accounts;
    }

    if (data?.contacts?.length) {
        payload.contacts = data?.contacts;
    }

    // if (data?.landlord_furniture?.length) {
    //     payload.landlord_furniture = data?.landlord_furniture;
    // }

    const docs = []
    for (const document of data?.landlord_documents || []) {
        for (const [documentType, documentArray] of Object.entries(document)) {
            if (documentArray?.length > 0) {
                const documents = await Promise.all(documentArray.map(async (document) => {
                    if (typeof document.document == "object") {
                        return { type: document?.type, document: await handleFileConversion(document?.document) };
                    } else {
                        return document
                    }
                }));
                docs.push(...documents);
            }
        }
    }

    if(docs.length){
        payload.landlord_documents = docs
    }

    
    const res = await api(
        "put",
        endPoints?.LANDLORD + `${company}/${landlord}/`,
        payload
    );
    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        dispatch(setPropertyLandlordSlice(res?.data?.results))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false);
};

const searchLandlordAction = (company, id, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.LANDLORD + `${company}/?landlord_id=${id}`,
    )
    if (res?.success) {
        onRes(res?.data?.results)
    }
}

const getPropertyLandlordAction = (id, dispatch, onRes, onError) => async () => {
    const res = await api(
        "get",
        endPoints?.PROPERTY_LANDLORD + `${id}/`,
    )
    if (res?.success) {
        const results = res?.data?.results;
        dispatch && dispatch(setPropertyLandlordSlice(results))
        onRes && onRes(results)
    }else {
        onError && onError(res.message)
    }
   
}

const getBankAccountListAction = (id, value, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.LANDLORD_ACCOUNTS_DROPDOWN + `?landlord=${id || ""}&iban=${value || ""}`
    );
    if(res?.success){
        const list = res?.data?.results
        const options = list?.map((item) => {
            return { ...item, name: item?.iban }
        })
        options.push({name: `Add New: ${value}`, iban: value })
        onRes(options)
    }else {
        const options = [{name: `Add New: ${value}`, iban: value }]
        onRes(options)
    }
}


const getBankAccountInfoAction = (id, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.LANDLORD_BANK_ACCOUNTS + `${id}/`
    );
    onRes(res)
}

const updateBankAccountAction = (id, data, dispatch, onRes) => async () => {
    const res = await api(
        "put",
        endPoints?.LANDLORD_BANK_ACCOUNTS + `${id}/`,
        data
    );
    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(res)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
}

const deleteBankAccountAction = (id, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    const res = await api(
        "delete",
        endPoints?.LANDLORD_BANK_ACCOUNTS + `${id}/`,
    );
    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false);
}

const addFurnitureAction = (furniture, dispatch, onRes) => async () => {
    const res = await api(
        "post",
        endPoints?.LANDLORD_FURNITURE,
        furniture
    );
    if(res?.success){
        onRes && onRes(res)
    }else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
}

const getFurnitureListAction = (property, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.LANDLORD_FURNITURE + `?property=${property}`,
    );
    if(res?.success){
        onRes(res?.data?.results)
    }
}

const getFurnitureAction = (id, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.LANDLORD_FURNITURE + `${id}/`,
    );
    onRes(res)
}

const updateFurnitureAction = (id, data, dispatch, onRes) => async () => {
    const res = await api(
        "patch",
        endPoints?.LANDLORD_FURNITURE + `${id}/`,
        data
    );
    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(res)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
}

const deleteFurnitureAction = (id, setLoading, dispatch, onRes) => async () => {
    const res = await api(
        "delete",
        endPoints?.LANDLORD_FURNITURE + `${id}/`,
    );
    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false);
}

const getContactAction = (id, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.LANDLORD_CONTACT + `${id}/`,
    );
    onRes(res)
}

const updateContactAction = (id, data, dispatch, onRes) => async () => {
    const res = await api(
        "patch",
        endPoints?.LANDLORD_CONTACT + `${id}/`,
        data
    );
    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(res)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
}

const deleteContactAction = (id, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    const res = await api(
        "delete",
        endPoints?.LANDLORD_CONTACT + `${id}/`,
    );
    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(res)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false)

}

const getLandlordAction = (company, landlordId, dispatch, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.LANDLORD + `${company}/${landlordId}/`,
    );
    if (res?.success) {
        onRes && onRes(res?.data?.results)
        dispatch(setLandlordSlice(res?.data?.results))
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
}

const landlordSuggestionsAction = (id, setLandlordOptions, onError) => async () => {
    let payload = {
        landlord_id: id
    }
    const res = await api(
        "post",
        endPoints?.LANDLORD_SUGGESTIONS,
        payload
    )
    if (res.success) {
        setLandlordOptions([])
    } else {
        setLandlordOptions(res?.data?.results)
        onError(`${res?.message}!.`)
    }
}

const searchLandlordByNameAction = (company, name, setLandlordOptions, setIsSearch, onError) => async () => {
    setIsSearch(true)
    const res = await api(
        "get",
        endPoints?.LANDLORD_SEARCH + `/${company}/${name}`,
    );

    if (res?.success) {
        const info = res?.data?.results
        let options = info?.map((item) => {
            return `${item?.landlord_name} (${item?.landlord_id})`
        })
        setLandlordOptions(options)
        onError(`${res?.message}!`)
    } else {
        setLandlordOptions([])
    }

    setIsSearch(false)
}

const landlordListAction = (company, rows, page, search, name, type, address, email, contact, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.LANDLORD + `${company}/?limit=${rows}&page=${page}&search=${search}&name=${name || ""}&type=${type || ""}&address=${address || ""}&email=${email || ""}&contact=${contact || ""}`,
    );
    if (res?.success) {
        let options = res?.data?.results?.map((item) => {
            return {
                ...item,
                totalBankAccounts: item?.bank_accounts?.length,
                totalContacts: item?.contacts?.length,
            }
        })
        onRes({ options: options, count: res?.data?.count })
    }
};

const deleteLandlordAction = (company, landlord, setLoading, setDeleteModal, dispatch, onRes) => async () => {
    setLoading(true)
    const res = await api(
        "delete",
        endPoints?.LANDLORD + `${company}/${landlord}`,
    );

    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(res)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false)
    setDeleteModal(false)
}

const landlordDropdownAction = (company, search, setLandlordList, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.LANDLORD_DROPDOWN + `/${company}/?search=${search}`,
    )
    if (res?.success) {
        const options = res?.data?.results?.map((item) => {
            return { landlord: item?.id, landlordName: `${item?.landlord_name + " (" + item?.landlord_id + ")"}` }
        })
        setLandlordList && setLandlordList(options)
        onRes(res?.data?.results)
    } else {
        setLandlordList([])
    }
}

export {
    addLandlordAction,
    searchLandlordAction,
    getPropertyLandlordAction,
    updateLandlordAction,
    getLandlordAction,
    landlordSuggestionsAction,
    landlordListAction,
    deleteLandlordAction,
    landlordDropdownAction,
    searchLandlordByNameAction,

    //bank
    getBankAccountInfoAction,
    updateBankAccountAction,
    deleteBankAccountAction,
    getBankAccountListAction,

    //furniture
    getFurnitureListAction,
    addFurnitureAction,
    getFurnitureAction,
    updateFurnitureAction,
    deleteFurnitureAction,

    //contact
    getContactAction,
    updateContactAction,
    deleteContactAction
}