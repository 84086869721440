import React from "react";
import { CustomDateViewInput, CustomViewInput } from "shared/AllViewInputs";
import { CustomForm } from "shared/AllInputs";
import { reformatDateString } from "utils/commonFunctions";
const HandleDamageView = ({ data }) => {
  return (
    <>
      <CustomForm title="Maintenance Details">
        <CustomViewInput
          data={data}
          name="who_handle"
          label={"Who will Handle it?"}
        />
        {/* <CustomViewInput data={data} name="cost" /> */}
      </CustomForm>
      <CustomForm
        title={
          data?.who_handle === "Landlord" ? "Landlord Info" : "Handyman Details"
        }
      >
        <CustomViewInput
          value={data?.landlord_contact?.name || data?.handyman_service?.name}
          name="name"
        />
        {data?.landlord_contact?.role ? (
          <CustomViewInput data={data?.landlord_contact} name="role" />
        ) : (
          <CustomViewInput data={data?.handyman_service} name="service" />
        )}
        <CustomViewInput
          data={data?.landlord_contact || data?.handyman_service}
          name="email"
        />
        {data?.landlord_contact?.office_phone ? (
          <CustomViewInput data={data?.landlord_contact} name="office_phone" />
        ) : (
          <CustomViewInput data={data?.handyman_service} name="work_phone" />
        )}

        <CustomDateViewInput
          value={reformatDateString(data?.expected_date)}
          name="expected_date"
        />
        <CustomViewInput data={data} name="start_time" />
        <CustomViewInput data={data} name="end_time" />
      </CustomForm>
    </>
  );
};

export default HandleDamageView;
