import React, { useEffect, useRef, useState } from "react";
import formValidation from "utils/validations";
import { showFormErrors } from "utils/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import AllRoles from "./AllRoles";
import { addRoleAction, getRoleInfoAction, updateRoleAction } from "store/actions/rolesActions";
import { Menu } from "primereact/menu";

const RolesContainer = () => {
    const navigate = useNavigate();
    const params = useParams();
    const menuRight = useRef(null);
    const dispatch = useDispatch();
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        roleName: "",
    });
    const { profile } = useSelector((state) => state?.user)
    const [permissions, setPermissions] = useState([]);
    const keys = {
        realEsate: "Real Estate",
        administrator: "Administrator",
        tenant: "Tenant",
        settings: "Settings",
    };
    const [roleName, setRoleName] = useState("")

    useEffect(() => {
        Object.keys(AllRoles).forEach((roleKey) => {
            Object.keys(AllRoles[roleKey]).forEach((sectionKey) => {
                AllRoles[roleKey][sectionKey].map((role) => {
                    return role.check = false
                })
            })
        })
    }, [])


    useEffect(() => {
        if (profile?.company && params?.id) {
            getRoleInfo(profile?.company, params?.id);
        }

    }, [profile]);

    const getRoleInfo = (companyId, roleId) => {
        dispatch(
            getRoleInfoAction(companyId, roleId, dispatch, (res) => {
                setData({
                    roleName: res?.name,
                    employees: res?.employees,
                });
                setRoleName(res?.name)
                setPermissions(res?.permission);
            })
        );
    };


    useEffect(() => {
        updateRolesWithPermissions();
    }, [permissions]);

    const updateRolesWithPermissions = () => {
        const nameMap = {
            "change_property": ["View Properties", "Add Properties"],
            "change_contract": ["View Contract", "Add Contract"],
            "change_landlord": ["View Landlord", "Add Landlord"],
            "change_building": ["View Building", "Add Building"],
            "change_annualstatement": ["View Annual Statements", "Add Annual Statements"],
            "change_handyman": ["View Handyman", "Add Handyman"],
            "change_damagereport": ["View Damage", "Add Damage"],
            "change_damage": ["View Maintenance Request"],
            "change_tenant": ["View Tenant", "Add Tenant"],
            "change_officeunit": ["View Unit", "Add Unit"],
            "change_office": ["View Office", "Add Office"],
            "change_employee": ["View Employee", "Add Employee"],
            "change_companyrole": ["View Role", "Add Role"],
        };

        const updatedRoles = { ...AllRoles };

        permissions.forEach((permission) => {
            Object.keys(updatedRoles).forEach((roleKey) => {
                Object.keys(updatedRoles[roleKey]).forEach((sectionKey) => {
                    updatedRoles[roleKey][sectionKey] = updatedRoles[roleKey][sectionKey].map((role) => {
                        if (nameMap[permission.codename] && nameMap[permission.codename].includes(role.name)) {
                            role.isDisabled = true;
                        }
                        if (role.value === permission.codename) {
                            role.check = true;
                        } else if (
                            role.value === "services" &&
                            (permission.codename === "add_services" ||
                                permission.codename === "change_services" ||
                                permission.codename === "delete_services" ||
                                permission.codename === "view_services")
                        ) {
                            role.check = true;
                        }
                        return role;
                    });
                });
            });
        });
        setRoles(updatedRoles);
    };

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const onSubmit = (e) => {
        const filteredRoles = filterObjectsWithTrueCheck(roles);
        e.preventDefault();
        if (showFormErrors(data, setData)) {
            let payload = {
                name: data?.roleName,
                permission: filteredRoles,
            };
            params?.id ? handleUpdateRole(payload) : handleAddRole(payload)
        }
    };

    const handleAddRole = (payload) => {
        dispatch(
            addRoleAction(profile?.company, payload, setLoading, navigate, dispatch)
        );
    }

    const handleUpdateRole = (payload) => {
        dispatch(
            updateRoleAction(profile?.company, params?.id, payload, setLoading, navigate, dispatch)
        );
    }

    const filterObjectsWithTrueCheck = (obj) => {
        const filteredRoles = [];
        for (let category in obj) {
            for (let subCategory in obj[category]) {
                obj[category][subCategory].forEach((item) => {
                    if (item.check === true) {
                        filteredRoles.push(item.value);
                    }
                });
            }
        }
        return filteredRoles;
    };

    const items = [
        {
            label: "Import",
            icon: "pi pi-download",
        },
        {
            label: "Export",
            icon: "pi pi-upload",
        },
    ];

    const header = () => {
        return (
            <div className="w-12 md:w-7 flex justify-content-between flex-wrap">
                <div className="w-12 md:w-9 mr-3"></div>
                <Menu model={items} popup ref={menuRight} id="popup_menu_right" />
            </div>
        );
    };

    return {
        data,
        handleChange,
        onSubmit,
        loading,
        roleName,
        header,
        roles,
        keys,
        setRoles,
        navigate
    }
}

export default RolesContainer