import React from "react";
import { CustomForm, NewCustomFileInput } from "shared/AllInputs";

const LandlordFilesComponent = ({
  title,
  handleChange,
  data,
  fileDelete,
}) => {
  return (
    <>
      <CustomForm title={title || "Upload Document"}>
      <div className="col-12 flex gap-3 flex-wrap align-items-start">
        <NewCustomFileInput
          col={6}
          onChange={handleChange}
          data={data}
          selectedFiles={data?.landlord_documents[0]?.contact_person}
          type="file"
          name="landlord_documents"
          ignoreLabel
          documentType="contact_person"
          onFileDelete={(index) => fileDelete(index, "contact_person")}
        />
        <NewCustomFileInput
          col={6}
          onChange={handleChange}
          data={data}
          selectedFiles={data?.landlord_documents[1]?.change_of_payments}
          type="file"
          name="landlord_documents"
          ignoreLabel
          documentType="change_of_payments"
          documentLabel="Change Of IBAN/Payments"
          onFileDelete={(index) => fileDelete(index, "change_of_payments")}
        />
        <NewCustomFileInput
          col={6}
          onChange={handleChange}
          data={data}
          selectedFiles={data?.landlord_documents[2]?.change_of_property_management}
          type="file"
          name="landlord_documents"
          ignoreLabel
          documentType="change_of_property_management"
          onFileDelete={(index) => fileDelete(index, "change_of_property_management")}
        />
        </div>
      </CustomForm>
    </>
  );
};

export default LandlordFilesComponent;
