import React from "react";
import Page from "shared/Page";
import CustomCard from "shared/CustomCard";
import { CustomForm } from "shared/AllInputs";
import CustomButton, {
  Buttonlayout,
  CustomButtonOutlined,
} from "shared/CustomButton";
import { CustomViewInput } from "shared/AllViewInputs";
import { TabPanel, TabView } from "primereact/tabview";
import PropertyTableModal from "../../Maintenance/Damage/DamageForms/PropertyTableModal";
import AnnualStatementContainer from "./AnnualStatementContainer";
import AddDocumentModal from "shared/Modals/AddDocumentModal";
import AddDocumentForm from "views/CommonComponents/AddDocument";
import MaintenanceTable from "views/Tables/MaintenanceTable";
import { getStatusColors } from "utils/commonFunctions";

const AnnualStatementForms = ({ type }) => {
  const {
    propertyData,
    onSubmit,
    loading,
    navigate,
    isPropertyModal,
    setIsPropertyModal,
    onConfirm,
    selectedProperty,
    setSelectedProperty,
    handleEditStatement,
    documentheader,
    isAddDocument,
    setIsAddDocument,
    allDocuments,
    setAllDocuments,
    documentType,
    setDocumentType,
    handleAddedDocuments,
    selectedDocument,
    propertyId,
  } = AnnualStatementContainer();

  const header = () => {
    return (
      <div className="cursor-pointer">
        {type === "Add" && (
          <CustomButton
            type="button"
            size="small"
            extraClassNames="ml-0"
            label="Select Property"
            onClick={() => setIsPropertyModal(true)}
          />
        )}
      </div>
    );
  };
  
  const FormHeader = () => {
    return (
      <div className="flex align-items-center col-12 justify-content-between">
        <h1 className="title">General</h1>
        {!propertyId && header()}
      </div>
    );
  };
  return (
    <>
      {documentType?.isAdd ? (
        <>
          <AddDocumentForm
            propertyData={propertyData}
            type={type}
            documentType={documentType}
            setDocumentType={setDocumentType}
            allDocuments={allDocuments}
            setAllDocuments={setAllDocuments}
            selectedDocument={selectedDocument}
          />
        </>
      ) : (
        <>
          <Page
            title={`${type} Annual Statement`}
            description={`Let’s ${type} your Annual Statement`}
            navigateLink={propertyId? `/real-estate/property/view/annual-statement/${propertyId}` : "/real-estate/annualstatement/list"}
          >
            <CustomCard header={FormHeader()}>
              <CustomForm col={12} >
                <CustomViewInput data={propertyData} name="propertyID" />
                <CustomViewInput data={propertyData} name="address" />
                <CustomViewInput data={propertyData} name="contractID" />
                <CustomViewInput data={propertyData} name="status" />
                {propertyData?.statementStatus && <CustomViewInput data={propertyData} name="statementStatus" label={"Status"} extraClassName={getStatusColors(propertyData?.statementStatus)}/>}
              </CustomForm>
              <TabView className="tabs-design">
                <TabPanel header="Received">
                  <MaintenanceTable
                    allDocuments={allDocuments}
                    header={documentheader()}
                    onEdit={handleEditStatement}
                  />
                </TabPanel>
                <TabPanel header="Allocated">
                  {/* <DamageCommentTable /> */}
                </TabPanel>
              </TabView>
            </CustomCard>
            <Buttonlayout>
              <CustomButton onClick={onSubmit} label={type === "Add"? "Save" : "Save Changes"} loading={loading} />
              <CustomButtonOutlined
                label="Cancel"
                onClick={() => {
                  propertyId? navigate(`/real-estate/property/view/annual-statement/${propertyId}`) : navigate("/real-estate/annualstatement/list");
                }}
              />
            </Buttonlayout>
          </Page>
        </>
      )}

      {type === "Add" && (
        <PropertyTableModal
          isOpen={isPropertyModal}
          setIsOpen={setIsPropertyModal}
          heading={"Select Property"}
          submitLabel={"Confirm"}
          onSubmit={onConfirm}
          loading={loading}
          selectedProperty={selectedProperty}
          setSelectedProperty={setSelectedProperty}
        />
      )}
      <AddDocumentModal
        visible={isAddDocument}
        setVisible={setIsAddDocument}
        documentType={documentType}
        setDocumentType={setDocumentType}
        addedDocument={handleAddedDocuments()}
      />
    </>
  );
};

export default AnnualStatementForms;
