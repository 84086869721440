import api from "services/api";
import endPoints from "services/endPoints";
import { showToastAction } from "../slices/commonSlice";
import { getProfileAction } from "./userActions";
import { setUserProfile } from "store/slices/userSlice";

const addRoleAction = (company, data, loading, navigate, dispatch) => async () => {
    loading(true)
    const res = await api(
        "post",
        endPoints?.COMPANY_ROLES + `${company}/`,
        data
    );
    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        navigate(`/administrator/roles/list`)
        dispatch(getProfileAction());
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    loading(false)
};

const updateRoleAction = (company, roleId, data, loading, navigate, dispatch) => async () => {
    loading(true)
    const res = await api(
        "put",
        endPoints?.COMPANY_ROLES + `${company}/${roleId}/`,
        data
    );
    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        navigate(`/administrator/roles/list`)
        dispatch(getProfileAction());
        dispatch(setUserProfile())

    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    loading(false)
};

const getRolesListAction = (company, dispatch, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.ROLES_LIST + `${company}/`,
    );
    if (res?.success) {
        onRes(res);
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
};

const getRoleInfoAction = (company, roleId, dispatch, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.COMPANY_ROLES + `${company}/${roleId}`,
    );
    if (res?.success) {
        onRes(res?.data?.results);
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
};

const getRoleEmployeesAction = (company, roleId, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.ROLES_LIST + `${company}/${roleId}/`,
    );
    onRes(res);
};

const roleDeleteAction = (company, roleId, loading, deleteModal, dispatch, onRes) => async () => {
    loading(true)
    const res = await api(
        "delete",
        endPoints?.COMPANY_ROLES + `${company}/${roleId}`,
    );
    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(res);
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }

    loading(false)
    deleteModal(false)
};

const changeEmployeeRole = (employeeId, payload, loading, setIsConfirmModal, setData, dispatch, onRes) => async () => {
    loading(true)

    const res = await api(
        "patch",
        endPoints?.EMP_ROLE_CHANGE + `${employeeId}/`, payload
    );

    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(res);
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    onRes(res);
    loading(false)
    setIsConfirmModal(false)
    setData({ searchEmployee: "" });
};

export {
    addRoleAction,
    getRolesListAction,
    getRoleInfoAction,
    getRoleEmployeesAction,
    updateRoleAction,
    roleDeleteAction,
    changeEmployeeRole
};
