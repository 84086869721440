import React from "react";
import Page from "shared/Page";
import CustomCard from "shared/CustomCard";
import {
  CustomAutoComplete,
  //   CustomCalander,
  //   CustomDropDown,
  CustomForm,
  // CustomInput,
  CustomNumberInput2,
  CustomSizeM2,
} from "shared/AllInputs";
import ContractContainer from "./ContractContainer";
import { TabPanel, TabView } from "primereact/tabview";
import CustomButton, {
  Buttonlayout,
  CustomButtonOutlined,
} from "shared/CustomButton";
import RentAndCost from "../../../CommonComponents/RentAndCost";
import Maturity from "shared/Components/Maturity";
import AddOptionalMaturity from "shared/Components/AddOptionalMaturity";
import Deposits from "shared/Components/Deposits";
import SizeM2 from "assets/images/svg/SizeM2.svg"
const ContractForms = ({ type }) => {
  const {
    data,
    handleChange,
    ContractOptions,
    contractIdChange,
    landlordChange,
    landlordList,
    propertyChange,
    // onLazyLoad,
    propertyList,
    disableTabs,
    //maturity
    maturityData,
    handleMaturityChange,

    depositData,
    handleDepositChange,

    // isContactExist,
    handleTabChange,
    activeIndex,
    skipAble,
    onSubmit,
    loading,

    allExtraCost,
    setAllExtraCost,

    allMaturities,
    setAllMaturities,
    navigate
  } = ContractContainer();

  return (
    <Page
      title={`${type} Contract`}
      description={`Let’s ${type} your Contract Details`}
        navigateLink={"/real-estate/contract/list"}
    >
      <CustomCard>
        <CustomForm title="Contractual Info">
          <CustomAutoComplete
            data={data}
            onChange={handleChange}
            name="contractId"
            suggestions={ContractOptions}
            required
            editable
            label={"Contract ID"}
            placeholder={"Contract ID"}
            search={(e) => contractIdChange.current(e.query)}
            max={9}
          />
          {/* <CustomInput
            data={data}
            onChange={handleChange}
            name="estateCompany"
            required
          /> */}
          <CustomSizeM2
            data={data}
            onChange={handleChange}
            name="contractualSize"
            required
            spanIcon={SizeM2}
          />
          <CustomNumberInput2
            data={data}
            onChange={handleChange}
            name="contractualNo"
            label="Contractual No. Of Room"
            placeholder={"Contractual No. Of Room"}
            required
          />
          <CustomAutoComplete
            field={"landlordName"}
            data={data}
            onChange={handleChange}
            name="landlord"
            suggestions={landlordList}
            required
            editable
            search={(e) => landlordChange.current(e.query)}
            max={9}
            label="Search Existing Landlord ID"
            placeholder="Search landlord ID"
          />
         {type === "Add" && <CustomAutoComplete
            field={"property_id"}
            data={data}
            onChange={handleChange}
            name="property"
            suggestions={propertyList}
            required
            editable
            search={(e) => propertyChange.current(e.query)}
            max={9}
            label="Search Existing Property ID"
            placeholder="Search property ID"
          />}
        </CustomForm>
        <TabView
          className="tabs-design"
          activeIndex={activeIndex}
          onTabChange={(e) => {
            handleTabChange(e?.index);
          }}
        >
          <TabPanel header="Rent & Cost">
            <RentAndCost
              title={"Rent & Cost"}
              data={data}
              handleChange={handleChange}
              allExtraCost={allExtraCost}
              setAllExtraCost={setAllExtraCost}
            />
          </TabPanel>
          <TabPanel header="Maturity" disabled={type !== "Edit" && disableTabs.includes(1)}>
            <Maturity data={maturityData} handleChange={handleMaturityChange} />
            {maturityData?.maturityType === "Optional Renewal" && (
              <AddOptionalMaturity
                allMaturities={allMaturities}
                setAllMaturities={setAllMaturities}
              />
            )}
          </TabPanel>
          <TabPanel header="Deposit" disabled={type !== "Edit" && disableTabs.includes(2)}>
            <Deposits data={depositData} handleChange={handleDepositChange} />
          </TabPanel>
        </TabView>
      </CustomCard>
      <Buttonlayout>
        <CustomButton
          onClick={onSubmit}
          label={type === "Edit" ? "Save Changes" : "Save & Next"}
          loading={loading}
        />
        <CustomButtonOutlined label="Cancel" onClick={() => navigate("/real-estate/contract/list")}/>
        {type === "Add" && skipAble.includes(activeIndex) && (
          <CustomButtonOutlined
            label="Skip"
            onClick={() => handleTabChange(activeIndex + 1)}
          />
        )}
      </Buttonlayout>
    </Page>
  );
};

export default ContractForms;
