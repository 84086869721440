import React from "react";
import {
  CustomDropDown,
  CustomForm,
  CustomNumberInput2,
  CustomSizeM2,
} from "shared/AllInputs";
import {
  HeatingTechOptions,
  UnitLocationOptions,
  UnitTypeOptions,
  WaterHeatingOptions,
} from "shared/AllDropDownOptions";
import SizeM2 from "assets/images/svg/SizeM2.svg"

const BuildingUnit = ({ data, handleChange }) => {
  return (
    <>
      <CustomForm title="Building Unit">
        <CustomDropDown
          data={data}
          onChange={handleChange}
          name="unitType"
          options={UnitTypeOptions}
          required
        />
        <CustomDropDown
          data={data}
          onChange={handleChange}
          name="location"
          options={UnitLocationOptions}
          required
        />
        <CustomNumberInput2
          data={data}
          onChange={handleChange}
          name="floor"
          label="Floor No"
          placeholder="Enter Floor No"
          max={3}
          required
        />
        <CustomSizeM2
          data={data}
          onChange={handleChange}
          name="size"
          max={9}
          required
          spanIcon={SizeM2}
        />
        <CustomDropDown
          data={data}
          onChange={handleChange}
          name="waterHeating"
          options={WaterHeatingOptions}
          required
        />
        <CustomDropDown
          data={data}
          onChange={handleChange}
          name="heatingTechnology"
          options={HeatingTechOptions}
          required
        />
      </CustomForm>
    </>
  );
};

export default React.memo(BuildingUnit);
