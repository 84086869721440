import React, { useEffect, useMemo, useState } from "react";
import formValidation from "utils/validations";
import { handleSetAddress, showFormErrors } from "utils/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addBuildingAction, getBuildingAction } from "store/actions/buildingActions";

const BuildingCotainer = () => {
    const { profile } = useSelector((state) => state.user);
    const [data, setData] = useState({
        building: "",
        buildingType: "",
        buildingMaxFloor: "",
        staircaseAccess: "",
        size: "",
        longitude: "",
        latitude: "",
        address: "",
        street: "",
        houseNo: "",
        zipCode: "",
        country: "",
        city: "",
        cityDivision: "",
    })
    const [selectedAddress, setSelectedAddress] = useState()
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams();
    const { building } = useSelector((state) => state?.building)

    useEffect(() => {
        if(params?.id ) {
            building ? handleBuildingData(building) : getBuildingDetail(params?.id);
        }
    }, [profile, building]);

    const getBuildingDetail = (id) => {
        dispatch(
            getBuildingAction(profile?.company, id, (res) => {
                handleBuildingData(res)
            })
        );
    };

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data, ["building"]);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
        if (name === "address") {
            setSelectedAddress("")
        }
    };

    useMemo(() => {
        if (selectedAddress) {
            handleSetAddress(selectedAddress, data, setData)
            // const { address, lng, lat } = selectedAddress;
            // let newFormErrors = { ...data.formErrors };

            // if (address) {
            //     newFormErrors.address = '';
            // }

            // setData((prev) => ({
            //     ...prev,
            //     ["address"]: address,
            //     ["longitude"]: lng,
            //     ["latitude"]: lat,
            //     formErrors: newFormErrors,
            // }));
        }

    }, [selectedAddress])

    const onSubmit = () => {
        if (showFormErrors(data, setData, ["building"])) {
            dispatch(
                addBuildingAction(
                    profile?.company,
                    data,
                    setLoading,
                    dispatch,
                    (res) => {
                        navigate('/real-estate/building/list')
                    }
                )
            )
        }
    }

    const handleBuildingData = (info) => {
        const buildingInfo = {
            building: info?.id,
            address: info?.address,
            longitude: info?.longitude,
            latitude: info?.latitude,
            buildingType: info?.type,
            buildingMaxFloor: info?.max_floor,
            staircaseAccess: info?.staircase,
            size: info?.size,
            city: info?.city,
            cityDivision: info?.city_division,
            country: info?.country,
            houseNo: info?.house_number,
            street: info?.street,
            zipCode: info?.zipcode
        }
        setData(buildingInfo)
    }

    return {
        data,
        handleChange,
        setSelectedAddress,
        loading,
        onSubmit,
        navigate
    }
}

export default BuildingCotainer