import React, { useEffect, useMemo, useRef, useState } from "react";
import CustomCard from "shared/CustomCard";
import {
  CustomInput,
  CustomNumberInput2,
  CustomSearch,
} from "shared/AllInputs";
import CustomButton from "shared/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import {
  officeDeleteAction,
  officeListAction,
} from "store/actions/officeActions";
import { useDispatch, useSelector } from "react-redux";
import CustomOverlay from "shared/CustomOverlay";
import CustomModal from "shared/Modals/CustomModal";
import { debounce } from "lodash";
import CustomTableContainer from "views/Tables/CustomTableContainer";
import { setOfficeSlice } from "store/slices/officeSlice";
import { canAccess } from "services/auth";
import manageColICon from "assets/images/icons/manage-col-icon.png";

const OfficeList = () => {
  const openAction = useRef(null);
  const [rowClick, setRowClick] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [officeList, setOfficeList] = useState();
  const { profile } = useSelector((state) => state.user);
  // const msgs = useRef(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");
  // const [rows, setRows] = useState(5);
  const [count, setCount] = useState(0);
  // const [page, setPage] = useState(1);
  const [first, setFirst] = useState(0);
  const [sortField, setSortField] = useState({ field: "", order: "" });
  const [search, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const globalFilters = [
    "office_id",
    "office_email",
    "name",
    "address",
    "office_units__name",
    "office_rep__user__first_name",
    "employee_count",
  ];

  const [filters2, setFilters2] = useState({
    office_id: { value: "" },
    office_email: { value: "" },
    name: { value: "" },
    address: { value: "" },
    office_units__name: { value: "" },
    office_rep__user__first_name: { value: "" },
    employee_count: { value: "" },
  });

  const [filters, setFilters] = useState({
    office_id: "",
    office_email: "",
    name: "",
    address: "",
    office_units__name: "",
    office_rep__user__first_name: "",
    employee_count: "",
  });

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = query.get("page") || 1;
  const rows = query.get("rows") || 10;
  const { office } = useSelector((state) => state?.office);
  const [selectedItems, setSelectedItems] = useState();

  const columnOptionsInitial = useMemo(
    () => [
      {
        name: "Office ID",
        accessor: "office_id",
        value: true,
        frozen: true,
        sortable: false,
        filter: true,
        filterElement: (option) => OfficeIDFilter(option),
      },
      {
        name: "Name",
        accessor: "name",
        value: true,
        filter: true,
        filterElement: (option) => NameFilter(option),
      },
      // {
      //   name: "Address",
      //   accessor: "address",
      //   value: true,
      //   filter: true,
      //   body: (options) => AddressBodyTemplate(options),
      //   filterElement: (options) => AddressFilter(options),
      // },
      {
        name: "Unit",
        accessor: "office_units__name",
        sortable: false,
        value: true,
        filter: true,
        filterElement: (options) => UnitFilter(options),
      },
      {
        name: "Rep Name",
        accessor: "office_rep__user__first_name",
        sortable: false,
        value: true,
        filter: true,
        filterElement: (options) => RepNameFilter(options),
      },
      {
        name: "Employee",
        accessor: "employee_count",
        sortable: false,
        value: true,
        filter: true,
        filterElement: (options) => EmployeeFilter(options),
      },
      {
        name: "Email",
        accessor: "office_email",
        sortable: false,
        value: true,
        filter: true,
        filterElement: (option) => EmailFilter(option),
      },
      { name: "Street", accessor: "street", value: true },
      { name: "Zipcode", accessor: "zipcode", value: true },
      { name: "City", accessor: "city", value: false },
      { name: "City Division", accessor: "city_division", value: false },
      { name: "Country", accessor: "country", value: false },
    ],
    [officeList]
  );

  const [columns, setColumns] = useState([]);
  const [columnOptions, setColumnOptions] = useState(columnOptionsInitial);

  useMemo(() => {
    setFirst(page * rows - rows);
  }, []);

  const OfficeIDFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          value={options.value}
          name="office_id"
          onChange={({ name, value }) => {
            handleFilterChange({ name: name, value: value });
            options.filterCallback(value);
          }}
          placeholder="Enter Office ID"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const EmailFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          value={options.value}
          name="office_email"
          onChange={({ name, value }) => {
            handleFilterChange({ name: name, value: value });
            options.filterCallback(value);
          }}
          placeholder="Enter Email"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const NameFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          value={options.value}
          name="name"
          onChange={({ name, value }) => {
            handleFilterChange({ name: name, value: value });
            options.filterCallback(value);
          }}
          className="p-column-filter"
          ignoreLabel
          placeholder="Enter Name"
        />
      </div>
    );
  };

  // const AddressFilter = (options) => {
  //   return (
  //     <div>
  //       <CustomInput
  //         col={12}
  //         data={filters}
  //         name="address"
  //         onChange={handleFilterChange}
  //         placeholder="Address"
  //         className="p-column-filter"
  //         ignoreLabel
  //       />
  //     </div>
  //   );
  // };

  // const AddressBodyTemplate = (options) => {
  //   return (
  //     <div>
  //       <Tooltip
  //         className="tooltip"
  //         target={`.tooltip-${options?.id}`}
  //         content={options?.address}
  //       />
  //       <span
  //         className={`tooltip-${options?.id}`}
  //         data-pr-classname="tooltip"
  //         style={{ cursor: "pointer" }}
  //       >
  //         <span
  //         // style={{
  //         //   color: options?.id === office?.id ? "white" : null,
  //         // }}
  //         >
  //           {truncateText(options?.address, 20)}
  //         </span>
  //       </span>
  //     </div>
  //   );
  // };

  const UnitFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          // data={filters}
          value={options.value}
          name="office_units__name"
          onChange={({ name, value }) => {
            handleFilterChange({ name: name, value: value });
            options.filterCallback(value);
          }}
          placeholder="Enter Unit"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const RepNameFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          value={options.value}
          name="office_rep__user__first_name"
          onChange={({ name, value }) => {
            handleFilterChange({ name: name, value: value });
            options.filterCallback(value);
          }}
          optionLabel="name"
          placeholder="Enter Rep Name"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const EmployeeFilter = (options) => {
    return (
      <div>
        <CustomNumberInput2
          col={12}
          value={options.value}
          name="employee_count"
          onChange={({ name, value }) => {
            handleFilterChange({ name: name, value: value });
            options.filterCallback(value);
          }}
          optionLabel="name"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const handleFilterChange = ({ name, value }) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    setColumnOptions(columnOptionsInitial);
    let filterdCols = columnOptions.filter((col) => col.value === true);
    setColumns(filterdCols);
  }, [columnOptionsInitial]);

  const getOfficeList = (
    company,
    rows,
    page,
    search,
    name,
    address,
    unit,
    rep,
    employee,
    officeId,
    email
  ) => {
    dispatch(
      officeListAction(
        company,
        rows,
        page,
        search,
        name,
        address,
        unit,
        rep,
        employee,
        officeId,
        email,
        (res) => {
          if (res?.success) {
            const offices = res?.data?.results?.map((office) => {
              return {
                ...office,
                office_rep__user__first_name: office?.office_rep,
                office_units__name: office?.office_unit,
                employee_count: office?.employee,
              };
            });
            setOfficeList(offices);
            setCount(res?.data?.count);
            setIsSearch(false);
            navigate(`/administrator/office/list?page=${page}&rows=${rows}`);
          }
        }
      )
    );
  };

  const handleSearch = (
    company,
    rows,
    page,
    search,
    name,
    address,
    unit,
    rep,
    employee,
    officeId,
    email
  ) => {
    setIsSearch(true);
    getOfficeList(
      company,
      rows,
      page,
      search,
      name,
      address,
      unit,
      rep,
      employee,
      officeId,
      email
    );
  };

  const debouncedOnChange = useRef(debounce(handleSearch, 1000));

  const header = () => {
    return (
      <div className="w-12 md:w-7 flex justify-content-end gap-2 flex-wrap">
        <div className="w-12 md:w-8 mr-3">
          <CustomSearch
            name="search"
            value={search}
            onChange={({ value }) => {
              setSearch(value);
              // setRows(5);
              // setPage(1);
              setFirst(0);
              debouncedOnChange.current(
                profile?.company,
                rows,
                1,
                value,
                // sortField?.order + sortField?.field
                filters?.name,
                filters?.address,
                filters?.office_units__name,
                filters?.office_rep__user__first_name,
                filters?.employee_count,
                filters?.office_id,
                filters?.office_email
              );
            }}
            isSearch={isSearch}
          />
        </div>
        {canAccess("add_office") && (
          <CustomButton
            size="small"
            icon="pi pi-plus"
            label="Add Office"
            onClick={() => navigate("/administrator/office/add")}
          />
        )}
      </div>
    );
  };

  const actions = (d) => {
    return (
      <CustomOverlay extraClass={"text-center"}>
        <ul className="no-style p-0">
          <li
            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
            onClick={() => {
              navigate(`/administrator/office/view/${d.id}`);
              dispatch(setOfficeSlice(d));
            }}
          >
            <i className="pi pi-eye cursor-pointer text-dark"></i>
            View
          </li>
          {canAccess("delete_office") && (
            <li className="flex gap-2 text-xs font-medium mb-3 cursor-pointer" onClick={() => {
              setIdToDelete(d?.id);
              setDeleteModal(true);
            }}>
              <i
                className="pi pi-trash  text-dark"
              ></i>{" "}
              Delete
            </li>
          )}
        </ul>
      </CustomOverlay>
    );
  };

  const handleOfficeDelete = () => {
    dispatch(
      officeDeleteAction(
        profile?.company,
        idToDelete,
        setLoading,
        setDeleteModal,
        dispatch,
        (res) => {
          getOfficeList(
            profile?.company,
            rows,
            page,
            search,
            // sortField?.order + sortField?.field
            filters?.name,
            filters?.address,
            filters?.office_units__name,
            filters?.office_rep__user__first_name,
            filters?.employee_count,
            filters?.office_id,
            filters?.office_email
          );
        }
      )
    );
  };

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected office from the list? This
        action cannot be undone.
      </span>
    );
  };

  useEffect(() => {
    if (profile?.company) {
      getOfficeList(
        profile?.company,
        rows,
        page,
        search,
        // sortField?.order + sortField?.field
        filters?.name,
        filters?.address,
        filters?.office_units__name,
        filters?.office_rep__user__first_name,
        filters?.employee_count,
        filters?.office_id,
        filters?.office_email
      );
    } else {
      setOfficeList([]);
    }
  }, [profile?.company]);

  const handleSort = (e) => {
    if (sortField?.order === "-") {
      setSortField({ field: e?.sortField, order: "" });
    } else {
      setSortField({ field: e?.sortField, order: "-" });
    }
  };

  const ActionTemplate = () => {
    return (
      <>
        <p
          className="flex gap-2 align-items-center"
          onClick={(e) => openAction.current.toggle(e)}
        >
          <img src={manageColICon} style={{ width: "27px" }} />
          <i className="pi pi-chevron-down"></i>
          {/* Action */}
        </p>
      </>
    );
  };

  // const onPageChange = (e) => {
  //   setRows(e.rows);
  //   setPage(e.page + 1);
  //   setFirst(e.first);
  // };

  const onPageChange = (e) => {
    // navigate(`/administrator/office/list?page=${e.page + 1}&rows=${e.rows}`);
    getOfficeList(
      profile?.company,
      e.rows,
      e.page + 1,
      search,
      // sortField?.order + sortField?.field
      filters?.name,
      filters?.address,
      filters?.office_units__name,
      filters?.office_rep__user__first_name,
      filters?.employee_count,
      filters?.office_id,
      filters?.office_email
    );
    setFirst(e.first);
  };

  const applyFilters = (options) => {
    options.filterApplyCallback();
    getOfficeList(
      profile?.company,
      rows,
      1,
      "",
      filters?.name,
      filters?.address,
      filters?.office_units__name,
      filters?.office_rep__user__first_name,
      filters?.employee_count,
      filters?.office_id,
      filters?.office_email
    );
  };

  const clearFilter = (options) => {
    const field = options.field;
    setFilters((prev) => ({ ...prev, [field]: "" }));
    getOfficeList(
      profile?.company,
      rows,
      1,
      "",
      field === "name" ? "" : filters?.name,
      field === "address" ? "" : filters?.address,
      field === "office_units__name" ? "" : filters?.office_units__name,
      field === "office_rep__user__first_name"
        ? ""
        : filters?.office_rep__user__first_name,
      field === "employee_count" ? "" : filters?.employee_count,
      field === "office_id" ? "" : filters?.office_id,
      field === "office_email" ? "" : filters?.office_email
    );
    options.filterClearCallback("");
  };

  useEffect(() => {
    let filterdCols = columnOptions.filter((col) => col.value === true);
    setColumns(filterdCols);
  }, []);

  return (
    <div>
      <CustomCard extraClass title="Office List" header={header()}>
        <CustomTableContainer
          list={officeList}
          columns={columns}
          actions={actions}
          first={first}
          rows={rows}
          count={count}
          ActionTemplate={ActionTemplate}
          activeRow={office?.id}
          slice={setOfficeSlice}
          globalFilters={globalFilters}
          // handleSort={""}
          onPageChange={onPageChange}
          filters={filters2}
          // setFilters={setFilters}
          onApplyFilter={applyFilters}
          onClearFilter={clearFilter}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          openAction={openAction}
          columnOptions={columnOptions}
          setColumnOptions={setColumnOptions}
          setColumns={setColumns}
          firstCol="Office ID"
        />
      </CustomCard>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleOfficeDelete}
        loading={loading}
      />
    </div>
  );
};

export default OfficeList;
