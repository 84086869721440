import React from "react";
import { Dialog } from "primereact/dialog";
import CustomButton, { CustomButtonOutlined } from "../CustomButton";
const CustomModal = ({
  isOpen,
  setIsOpen,
  heading,
  body,
  cancelLabel,
  submitLabel,
  loading,
  onSubmit,
  onCancel,
}) => {
  const footerContent = (
    <div className="modal-footer border-0 gap-4 flex justify-content-center pb-4">
      <CustomButtonOutlined
        disabled={loading}
        type="button"
        label={cancelLabel || "Cancel"}
        className="btn m-0 fs-6 p-2 px-5 primary-button-outlined bg-transparent "
        onClick={() => {
          setIsOpen(!isOpen);
          onCancel && onCancel();
        }}
      />
      {onSubmit && (
        <CustomButton
          type="button"
          loading={loading}
          label={submitLabel || "Send"}
          onClick={onSubmit}
          className="btn primary-button fs-6 p-2 text-white px-5"
        />
      )}
    </div>
  );
  return (
    <Dialog
      visible={isOpen}
      className="invitation-modal"
      header={heading}
      footer={footerContent}
      style={{ width: "30vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => {
        !loading && setIsOpen(false);
        onCancel && onCancel();
      }}
    >
      {body()}
    </Dialog>
  );
};

export default CustomModal;
