import React from "react";
import { CustomForm } from "shared/AllInputs";
import { NewCustomImageView } from "shared/AllViewInputs";

const ViewLandlordFiles = ({ title, files }) => {
  return (
    <>
      <CustomForm title={title || "Documents"}>
        <div className="col-12 flex gap-3 flex-wrap align-items-start">
          <NewCustomImageView
            files={files[0]?.contact_person}
            documentType={"contact_person"}
          />
          <NewCustomImageView
            files={files[1]?.change_of_payments}
            documentType={"change_of_payments"}
            documentLabel="Change Of IBAN/Payments"
          />
          <NewCustomImageView
            files={files[2]?.change_of_property_management}
            documentType={"change_of_property_management"}
          />
        </div>
      </CustomForm>
    </>
  );
};

export default ViewLandlordFiles;
