import React from "react";
import { CustomForm } from "../../shared/AllInputs";
import { CustomViewInput } from "../../shared/AllViewInputs";
import CustomLandlordFurnitureTable from "../Tables/CustomLandlordFurnitureTable";

const ViewFurniture = ({ type, data, allFurniture, setAllFurniture}) => {
  return (
    <>
      <CustomForm title="Landlord Furniture">
        <CustomViewInput
          name="furnitureProvided"
          data={data}
          label="Furniture provide by the landlord"
          inputClass="capitalize"
        />
      </CustomForm>
      <CustomLandlordFurnitureTable
        action={type}
        id={data?.id}
        title={"Furniture"}
        allFurniture={allFurniture}
        setAllFurniture={setAllFurniture}
      />
    </>
  );
};

export default ViewFurniture;
