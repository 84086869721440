import React, { useEffect, useRef, useState } from "react";
import CustomCard from "shared/CustomCard";
import CustomButton from "shared/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  changeEmployeeRole,
  getRolesListAction,
  roleDeleteAction,
} from "store/actions/rolesActions";
import { useNavigate } from "react-router-dom";
import RolesListTable from "./RolesListTable";
import CustomModal from "shared/Modals/CustomModal";
import { CustomDropDown } from "shared/AllInputs";
import { searchEmployeesAction } from "store/actions/EmployeeActions";
import { debounce } from "lodash";
import { showFormErrors } from "utils/commonFunctions";
import { canAccess } from "services/auth";

export default function RolesList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profile } = useSelector((state) => state.user);
  const [rolesList, setRolesList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState({
    searchEmployee: "",
  });
  const [isSearch, setIsSearch] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [employeesList, setEmployeesList] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  // const [selectedEmployee, setSelectedEmployee] = useState();

  useEffect(() => {
    if (profile?.company) {
      handleGetList(profile?.company);
    }
  }, [profile]);

  const handleChange = ({ name, value }) => {
    setData((prev) => ({ ...prev, [name]: value }));
    debouncedOnChange.current(profile?.company, data?.searchEmployee);
  };

  const handleSearch = (company, search) => {
    dispatch(
      searchEmployeesAction(company, search, setIsSearch, setEmployeesList)
    );
  };

  const debouncedOnChange = useRef(debounce(handleSearch, 1000));

  const header = () => {
    return (
      <div className="w-12 md:w-8 flex justify-content-end flex-wrap align-items-center">
        <div className="w-12 md:w-9 mr-3"></div>
        <CustomButton
          size="small"
          icon="pi pi-plus"
          label="Add Role"
          onClick={() => navigate("/administrator/roles/add")}
        />
      </div>
    );
  };

  const handleGetList = (company) => {
    dispatch(
      getRolesListAction(company, dispatch, (res) => {
        setRolesList(res?.data?.results);
      })
    );
  };

  const handleRoleDelete = () => {
    dispatch(
      roleDeleteAction(
        profile?.company,
        idToDelete,
        setLoading,
        setDeleteModal,
        dispatch,
        (res) => {
          handleGetList(profile?.company);
        }
      )
    );
  };

  const DeleteModalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected role from the list? This
        action cannot be undone.
      </span>
    );
  };

  const AddEmpModalContent = () => {
    return (
      <div>
        <p className="font-bold mb-3">
          Role : <span className="text-primary">{selectedRole?.name}</span>
        </p>
        <CustomDropDown
          col={12}
          extraClassName="p-0"
          name="searchEmployee"
          data={data}
          onChange={handleChange}
          isSearch={isSearch}
          options={employeesList}
          label={"Employee Name"}
          placeholder="Search Employee"
          editable
        />
      </div>
    );
  };

  const ConfirmModalContent = () => {
    return (
      <span>
        Are you sure you want to add the selected employee to this role? This
        action cannot be undone.
      </span>
    );
  };

  const handleAddEmployee = () => {
    if (showFormErrors(data, setData)) {
      setIsOpen(false);
      setIsConfirmModal(true);
    }
  };

  const onSubmit = () => {
    if (showFormErrors(data, setData)) {
      let payload = {
        role: selectedRole?.id,
      };

      dispatch(
        changeEmployeeRole(
          data?.searchEmployee,
          payload,
          setLoading,
          setIsConfirmModal,
          setData,
          dispatch,
          (res) => {
            handleGetList(profile?.company);
          }
        )
      );
    }
  };

  const handleCancelSubmit = () => {
    setData({ searchEmployee: "" });
  };

  return (
    <div>
      <CustomCard
        title="Role"
        header={canAccess("add_companyrole") && header()}
      >
        <br />
        {rolesList &&
          rolesList?.map((role) => {
            return (
              <React.Fragment key={role.id}>
                <RolesListTable
                  role={role}
                  setIdToDelete={setIdToDelete}
                  setDeleteModal={setDeleteModal}
                  setSelectedRole={setSelectedRole}
                  setIsOpen={setIsOpen}
                />
                <br />
              </React.Fragment>
            );
          })}
      </CustomCard>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={DeleteModalContent}
        submitLabel={"Confirm"}
        onSubmit={handleRoleDelete}
        loading={loading}
      />
      <CustomModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        heading={"Add Employee"}
        body={AddEmpModalContent}
        submitLabel={"Add"}
        onSubmit={handleAddEmployee}
        loading={loading}
        onCancel={handleCancelSubmit}
      />
      <CustomModal
        isOpen={isConfirmModal}
        setIsOpen={setIsConfirmModal}
        heading={"Add Employee"}
        body={ConfirmModalContent}
        submitLabel={"Confirm"}
        onSubmit={onSubmit}
        loading={loading}
        onCancel={handleCancelSubmit}
      />
    </div>
  );
}
