import React from "react";
import Page from "shared/Page";
import CustomCard from "shared/CustomCard";
import {
  CustomAmountInput,
  CustomDropDown,
  CustomForm,
} from "shared/AllInputs";
import CustomButton, {
  Buttonlayout,
  CustomButtonOutlined,
} from "shared/CustomButton";
import { CustomDateViewInput, CustomViewInput } from "shared/AllViewInputs";
import { TabPanel, TabView } from "primereact/tabview";
import AddDamageFrom from "views/CommonComponents/AddDamageForm";
import { maintenanceReqType } from "shared/AllDropDownOptions";
import MaintenanceContainer from "./MaintenanceContainer";
import MaintenanceLandlordView from "./MaintenanceLandlordView";
import CustomModal from "shared/Modals/CustomModal";
import HandleDamageView from "./HandleDamageView";
import DamageFeedback from "./DamageFeedback";
import DamageFeedbackView from "./DamageFeedbackView";
import CommentsTable from "views/Tables/CommentsTable";
import DamageView from "views/CommonViewComponents/DamageView";
import CustomTableContainer from "views/Tables/CustomTableContainer";

const MaintenanceForms = ({ type }) => {
  const {
    damageData,
    companyColumns,
    isResponseModal,
    setIsResponseModal,
    responseModalContent,
    handleResponse,
    whoHandleOpt,
    propertyData,
    setPropertyData,
    onConfirm,
    landlordData,
    selectedItem,
    setSelectedItem,
    handymanList,
    handleResponseChange,
    responseData,
    loading,
    navigate,
    isAddDamage,
    setIsAddDamage,
    allDamages,
    setAllDamages,
    reportId,
    updateDamageStatus,
    commentData,
    setCommentData,
    isAddFeedback,
    onFixed,
    comments,
    addComment,
    updateComment,
    deleteComment,
  } = MaintenanceContainer();

  return (
    <>
      {isAddDamage ? (
        <>
          <AddDamageFrom
            reportId={reportId}
            allDamages={allDamages}
            setAllDamages={setAllDamages}
            setIsAddDamage={setIsAddDamage}
          />
        </>
      ) : (
        <>
          <Page
            title={`View Maintenance Request`}
            description={`Let’s ${type} your Maintenance Req Details`}
            navigateLink={"/real-estate/maintenance/list"}
          >
            <CustomCard>
              <CustomForm title={`Damage`}>
                <CustomViewInput data={propertyData} name="propertyID" />
                <CustomViewInput data={propertyData} name="address" />
                <CustomViewInput data={propertyData} name="landlordName" />
                <CustomDateViewInput
                  data={propertyData}
                  name="movingDate"
                  label={"Move in Date"}
                />
                <CustomViewInput data={propertyData} name="maturityType" />
                <CustomViewInput data={propertyData} name="rent" />
                <CustomViewInput data={propertyData} name="tenantName" />
                <CustomDropDown
                  data={propertyData}
                  onChange={({ value }) => {
                    setPropertyData({ ...propertyData, status: value });
                    updateDamageStatus(value);
                  }}
                  name="status"
                  options={maintenanceReqType}
                  colors
                  required
                />
              </CustomForm>
              <TabView className="tabs-design">
                <TabPanel header="Damages">
                  <DamageView damage={damageData} />
                  {!propertyData.damageHandle ? (
                    <>
                      <div className="flex flex-wrap">
                        <CustomDropDown
                          data={responseData}
                          options={whoHandleOpt}
                          name="whoHandle"
                          label="Who will Handle it?"
                          onChange={handleResponseChange}
                          required
                        />
                        {/* <CustomAmountInput
                          data={responseData}
                          name="cost"
                          onChange={handleResponseChange}
                          required
                        /> */}
                      </div>

                      {responseData?.whoHandle === "Landlord" ? (
                        <MaintenanceLandlordView
                          data={landlordData}
                          selectedItem={selectedItem}
                          setSelectedItem={setSelectedItem}
                        />
                      ) : responseData?.whoHandle === "Company" ? (
                        <CustomTableContainer
                          list={handymanList}
                          columns={companyColumns}
                          selectedItems={selectedItem}
                          setSelectedItems={setSelectedItem}
                          selectionMode={"single"}
                        />
                      ) : null}
                    </>
                  ) : (
                    <>
                      <HandleDamageView data={propertyData?.damageHandle} />
                      {isAddFeedback && (
                        <DamageFeedback
                          data={commentData}
                          setData={setCommentData}
                          isRating={
                            propertyData?.damageHandle?.handyman_service
                              ?.feedback || false
                          }
                        />
                      )}
                      {propertyData?.damageFeedback && (
                        <DamageFeedbackView data={propertyData} />
                      )}
                    </>
                  )}
                </TabPanel>
                <TabPanel header="Comments">
                  <CommentsTable
                    comments={comments}
                    onAdd={addComment}
                    onUpdate={updateComment}
                    onDelete={deleteComment}
                  />
                </TabPanel>
              </TabView>
            </CustomCard>
            <Buttonlayout>
              {!propertyData.damageHandle || isAddFeedback ? (
                <>
                  <CustomButton
                    label="Confirm"
                    onClick={onConfirm}
                    loading={loading}
                  />
                  <CustomButtonOutlined
                    label="Cancel"
                    onClick={() => navigate(`/real-estate/maintenance/list`)}
                  />
                </>
              ) : null}
              {!propertyData?.damageFeedback &&
                !isAddFeedback &&
                propertyData?.damageHandle && (
                  <CustomButtonOutlined
                    label="Fixed"
                    onClick={onFixed}
                    loading={loading}
                  />
                )}
            </Buttonlayout>
          </Page>
        </>
      )}

      <CustomModal
        isOpen={isResponseModal}
        setIsOpen={setIsResponseModal}
        heading={"Response"}
        body={responseModalContent}
        submitLabel={"Confirm"}
        onSubmit={handleResponse}
        loading={loading}
      />
    </>
  );
};

export default MaintenanceForms;
