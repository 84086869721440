import React from "react";
import { CustomForm, NewCustomFileInput } from "shared/AllInputs";

const ContractAndUnitFilesComponent = ({
  title,
  handleChange,
  data,
  fileDelete,
}) => {

  return (
    <>
      <CustomForm title={title || "Upload Document"}>
        <div className="col-12 flex gap-3 flex-wrap align-items-start">
          <NewCustomFileInput
            col={4}
            onChange={handleChange}
            // data={data}
            selectedFiles={data?.[0]?.handover_protocol}
            type="file"
            name="document"
            ignoreLabel
            documentType="handover_protocol"
            onFileDelete={(index) => fileDelete(index, "handover_protocol")}
          />
          <NewCustomFileInput
            col={4}
            onChange={handleChange}
            // data={data}
            selectedFiles={data?.[1]?.key_protocol}
            type="file"
            name="document"
            ignoreLabel
            documentType="key_protocol"
            onFileDelete={(index) => fileDelete(index, "key_protocol")}
          />
          <NewCustomFileInput
            col={4}
            onChange={handleChange}
            // data={data}
            selectedFiles={data?.[2]?.floor_plan}
            type="file"
            name="document"
            ignoreLabel
            documentType="floor_plan"
            onFileDelete={(index) => fileDelete(index, "floor_plan")}
          />
          <NewCustomFileInput
            col={4}
            onChange={handleChange}
            // data={data}
            selectedFiles={data?.[3]?.photos}
            type="file"
            name="document"
            ignoreLabel
            documentType="photos"
            onFileDelete={(index) => fileDelete(index, "photos")}
          />
          <NewCustomFileInput
            col={4}
            onChange={handleChange}
            // data={data}
            selectedFiles={data?.[4]?.energy_bill}
            type="file"
            name="document"
            ignoreLabel
            documentType="energy_bill"
            onFileDelete={(index) => fileDelete(index, "energy_bill")}
          />
          <NewCustomFileInput
            col={4}
            onChange={handleChange}
            // data={data}
            selectedFiles={data?.[5]?.energy_contract}
            type="file"
            name="document"
            ignoreLabel
            documentType="energy_contract"
            onFileDelete={(index) => fileDelete(index, "energy_contract")}
          />
          <NewCustomFileInput
            col={4}
            onChange={handleChange}
            // data={data}
            selectedFiles={
              data?.[6]?.energymeter_replacement
            }
            type="file"
            name="document"
            ignoreLabel
            documentType="energymeter_replacement"
            documentLabel={"Certificate Of Energy Meter Replacement"}
            onFileDelete={(index) =>
              fileDelete(index, "energymeter_replacement")
            }
          />
        </div>
      </CustomForm>
    </>
  );
};

export default ContractAndUnitFilesComponent;
