import React, { useState } from "react";
import { CustomDropDown, CustomForm } from "shared/AllInputs";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import formValidation from "utils/validations";
import CustomOverlay from "shared/CustomOverlay";
import CustomModal from "shared/Modals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { handleDelete } from "shared/Components/commonFunctions";
import {
  FurnitureBathopt,
  FurnitureKitchenOpt,
  FurnitureOtheropt,
  FurnitureType,
} from "shared/AllDropDownOptions";
import {
  deleteFurnitureAction,
  getLandlordAction,
  getPropertyLandlordAction,
  updateFurnitureAction,
} from "store/actions/landlordActions";
import { useParams } from "react-router-dom";
import CrossIcon from "assets/images/svg/cross-icon.svg";
import SaveIcon from "assets/images/svg/Save-icon.svg";
import { showToastAction } from "store/slices/commonSlice";

export default function CustomLandlordFurnitureTable({
  action,
  id,
  title,
  allFurniture,
  setAllFurniture,
}) {
  const params = useParams();
  const dispatch = useDispatch();
  const [editingRows, setEditingRows] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });
  const [furniture, setFurniture] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const { profile } = useSelector((state) => state?.user);

  const rowEditValidator = async (rowData, options) => {
    const { type, item } = rowData;
    const typeError = formValidation("type", type, allFurniture);
    const itemError = formValidation("item", item, allFurniture);

    let isError = false;

    if (type === "Kitchen") {
      if (!FurnitureKitchenOpt.some((option) => option.value === item)) {
        isError = true;
      }
    } else if (type === "Bath") {
      if (!FurnitureBathopt.some((option) => option.value === item)) {
        isError = true;
      }
    } else if (type === "Other") {
      if (!FurnitureOtheropt.some((option) => option.value === item)) {
        isError = true;
      }
    }

    if (!typeError["type"] && !itemError["item"] && !isError) {
      let payload = {
        type: type,
        item: item,
        landlord: id,
      };
      dispatch(
        updateFurnitureAction(rowData?.id, payload, dispatch, (res) => {
          let rooms = [...allFurniture];
          rooms[options.rowIndex] = rowData;
          setAllFurniture(rooms);
          setEditingRows({});
          handleGetInfo();
        })
      );
    } else {
      return false;
    }
  };

  const handleEditRow = (rowData) => {
    const val = { ...{ [`${rowData?.id}`]: true } };
    setEditingRows(val);
  };

  const ItemEditor = (options) => {
    const formErrors = formValidation("item", options.value, allFurniture);
    if (
      furniture === "Kitchen" &&
      !FurnitureKitchenOpt.some((option) => option.value === options.value)
    ) {
      formErrors["item"] = "Item is required.";
    } else if (
      furniture === "Bath" &&
      !FurnitureBathopt.some((option) => option.value === options.value)
    ) {
      formErrors["item"] = "Item is required.";
    } else if (
      furniture === "Other" &&
      !FurnitureOtheropt.some((option) => option.value === options.value)
    ) {
      formErrors["item"] = "Item is required.";
    }

    return (
      <CustomDropDown
        type="text"
        col={12}
        value={options.value}
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
        name="item"
        options={
          furniture === "Kitchen"
            ? FurnitureKitchenOpt
            : furniture === "Bath"
              ? FurnitureBathopt
              : FurnitureOtheropt
        }
        ignoreLabel
        errorMessage={formErrors["item"]}
      />
    );
  };

  const TypeEditor = (options) => {
    const formErrors = formValidation("type", options.value, allFurniture);
    setFurniture(options.value);
    setSelectedItem("");
    return (
      <CustomDropDown
        value={options.value}
        col={12}
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
        options={FurnitureType}
        name="type"
        ignoreLabel
        errorMessage={formErrors["type"]}
      />
    );
  };

  const handleSave = async (rowData, options, e) => {
    options?.rowEditor?.onSaveClick(e);
    const val = { ...{ [`${rowData?.id}`]: true } };
    setEditingRows(val);
  };

  const actions = (rowData, options) => {
    return (
      <>
        {options.rowEditor?.editing ? (
          <>
            <a
              style={{ cursor: "pointer" }}
              onClick={(e) => handleSave(rowData, options, e)}
            >
              <img src={SaveIcon} />
            </a>
            <a
              onClick={(e) =>
                options.rowEditor?.onCancelClick &&
                options.rowEditor?.onCancelClick(e)
              }
            >
              {" "}
              <img src={CrossIcon} />
            </a>
          </>
        ) : (
          <>
            <CustomOverlay>
              <ul className="no-style p-0">
                <li
                  className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                  onClick={() => handleEditRow(rowData)}
                >
                  <i className="pi pi-file-edit text-dark"></i>
                  Edit
                </li>
                <li className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                  onClick={() => {
                    if(allFurniture.length < 2){
                      dispatch(showToastAction({
                        type: "error",
                        detail: "Minimum one furniture is required",
                      }))
                    }else {
                      setIdToDelete({
                        id: rowData?.id,
                        index: options?.rowIndex,
                      });
                      setDeleteModal(true);
                    }}
                    }
                   
                >
                  <i
                    className="pi pi-trash  text-dark"

                  ></i>{" "}
                  Delete
                </li>
              </ul>
            </CustomOverlay>
          </>
        )}
      </>
    );
  };

  const onRowEditChange = (e) => {
    setEditingRows(e.data);
  };

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected furniture from the list?
        This action cannot be undone.
      </span>
    );
  };

  const handleDeleteRoom = () => {
    dispatch(
      deleteFurnitureAction(idToDelete?.id, setLoading, dispatch, (res) => {
        handleDelete(allFurniture, setAllFurniture, "", "", idToDelete?.index);
        setIdToDelete({ id: "", index: "" });
        setDeleteModal(false);
        handleGetInfo();
      })
    );
  };

  const handleGetInfo = () => {
    if (action === "property") {
      dispatch(getPropertyLandlordAction(params?.id, dispatch));
    } else if (action === "landlord") {
      dispatch(getLandlordAction(profile?.company, params?.id, dispatch));
    }
  };

  return (
    <CustomForm title={title}>
      <DataTable
        className="col-12 table-error-align"
        value={allFurniture}
        editMode="row"
        dataKey="id"
        editingRows={editingRows}
        onRowEditChange={onRowEditChange}
        rowEditValidator={rowEditValidator}
        onRowEditSave={handleSave}
        tableStyle={{ minWidth: "50rem" }}
      >
        <Column
          headerClassName="custom-header"
          field="type"
          header="Type"
          editor={(options) => TypeEditor(options)}
          style={{ width: "45%" }}
        ></Column>
        <Column
          headerClassName="custom-header"
          field="item"
          header="Item"
          editor={(options) => ItemEditor(options)}
          body={(options) => options?.item === "Not Supported" ? "Not supported in this version" : options?.item}
          style={{ width: "45%" }}
        ></Column>
        <Column
          headerClassName="custom-header"
          header="Action"
          rowEditor
          body={(rowData, options) => actions(rowData, options)}
        ></Column>
      </DataTable>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleDeleteRoom}
        loading={loading}
      />
    </CustomForm>
  );
}
