import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addDamageReportAction, addReportCommentAction, deleteReportCommentAction, getDamageReportDetailAction, getReportCommentsListAction, processDamageAction, updateDamageReportAction, updateReportCommentAction } from "store/actions/damageActions";
import { showToastAction } from "store/slices/commonSlice";
import CustomButton from "shared/CustomButton";
import { setDamageReportSlice } from "store/slices/damageSlice";

const DamageContainer = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()
    const { profile } = useSelector((state) => state?.user)
    const { damageReport } = useSelector((state) => state?.damage)
    const [propertyData, setPropertyData] = useState({
        id: "", propertyID: "", landlordName: "", tenantsName: "", address: "",
    })
    const [allDamages, setAllDamages] = useState([])
    const [loading, setLoading] = useState(false)
    const [isPropertyModal, setIsPropertyModal] = useState(false)
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [isAddDamage, setIsAddDamage] = useState(false)
    // const [deleteDamageModal, setDeleteDamageModal] = useState(false);
    const [comments, setComments] = useState([])

    useEffect(() => {
        if(profile?.company){
            getDamageReportDetails(profile?.company, params?.id);
        }
    }, [])

    useEffect(() => {
        if (params?.id && profile?.company && damageReport?.id) {
            handleSetDamageReportData(damageReport)
        } else if (params?.id && profile?.company && !damageReport?.id) {
            getDamageReportDetails(profile?.company, params?.id);
        }
    }, [params?.id, profile, damageReport]);

    useEffect(() => {
        if (profile?.company && params?.id) {
            getReportComments()
        }
    }, [profile, params?.id])

    const getDamageReportDetails = () => {
        dispatch(
            getDamageReportDetailAction(profile?.company, params?.id, dispatch, (res) => {
                handleSetDamageReportData(res)
            })
        )
    }

    const handleSetDamageReportData = (details) => {
        setPropertyData({
            id: details?.property?.id,
            propertyID: details?.property?.property_id,
            landlordName: details?.landlord_name,
            tenantsName: "",
            address: details?.property?.address,
            status: details?.status
        })

        const damages = details?.damages?.map((damage) => {
            return {
                id: damage?.id,
                damageCause: damage?.damage_cause,
                damageType: { name: damage?.damage_type?.name, value: damage?.damage_type?.id },
                damageDescription: damage?.description,
                document: damage?.images?.map((image) => {
                    return image?.image
                }),
                is_requested: damage?.is_requested,
                processedDate: damage?.updated_at
            }
        })
        setAllDamages(damages)
    }

    const damagesheader = () => {
        return (
            <div className="flex justify-content-end cursor-pointer">
                <CustomButton
                    type="button"
                    size="small"
                    label="Add Damage"
                    onClick={() => {
                        if (selectedProperty || params?.id) {
                            setIsAddDamage(true)
                            // navigate("/real-estate/maintenance/damage-report/damage/add")
                        } else {
                            dispatch(showToastAction({
                                type: "error",
                                detail: "Please select property first.",
                            }))
                        }
                    }}
                />
            </div>
        );
    };

    const onSubmit = () => {
        if (!selectedProperty) {
            dispatch(showToastAction({
                type: "error",
                detail: "Please select property.",
            }))
        } else if (!allDamages.length) {
            dispatch(showToastAction({
                type: "error",
                detail: "Please add damage.",
            }))
        } else {
            dispatch(addDamageReportAction(profile?.company, selectedProperty?.id, allDamages, setLoading, dispatch, (res) => {
                dispatch(setDamageReportSlice(res))
                navigate("/real-estate/maintenance/damage/list")
            }))
        }
    }

    const updateDamageReport = (status) => {
        dispatch(updateDamageReportAction(profile?.company, params?.id, status, setLoading, dispatch, (res) => {
            // navigate("/real-estate/maintenance/damage/list")
        }))
    }

    const onConfirm = () => {
        setPropertyData({
            id: selectedProperty?.id,
            propertyID: selectedProperty?.property_id,
            landlordName: selectedProperty?.landlord_name,
            tenantsName: "",
            address: selectedProperty?.address
        })
        setIsPropertyModal(false)
    }

    // const handleDeleteDamage = (id, index) => {
    //     handleDeleteNew(allDamages, setAllDamages, index)
    //     setDeleteDamageModal(false)
    // }

    const handleEditDamage = (id, index) => {
        // if (index != -1) {
        //     const damage = allDamages?.find((item, itemIndex) => itemIndex === index)
        //     setData({
        //         index: index, damageCause: damage?.damageCause, damageType: damage?.damageType, damageDescription: damage?.damageDescription, document: damage?.document
        //     })
        //     setDamageDocuments(damage?.document)
        //     setIsAddDamage(true)
        // }
        navigate(`/real-estate/maintenance/damage-report/damage/edit/${id}`);
    }

    const handleProcessDamage = (id, index) => {
        dispatch(
            processDamageAction(profile?.company, id, dispatch, (res) => {
                const damages = [...allDamages];
                const indexToUpdate = damages.findIndex((item) => item?.id === res?.id)
                const damage = damages.find((item) => item?.id === res?.id)
                if (indexToUpdate === 0 || indexToUpdate > 0) {
                    damages[indexToUpdate] = {
                        ...damage,
                        is_requested: res?.is_requested,
                        processedDate: res?.updated_at,
                    };
                    setAllDamages(damages)
                }
            })
        );
    };

    const getReportComments = () => {
        dispatch(getReportCommentsListAction(profile?.company, params?.id, (res) => {
            setComments(res)
        }))
    }

    const addComment = (comment, loading) => {
        dispatch(addReportCommentAction(profile?.company, params?.id, comment, loading, dispatch, (res) => {
            const allComments = [...comments]
            allComments.push(res)
            setComments(allComments)
        }))
    }

    const updateComment = (commentId, commentIndex, comment, loading) => {
        dispatch(updateReportCommentAction(profile?.company, params?.id, commentId, comment, loading, dispatch, (res) => {
            const allComments = [...comments]
            allComments[commentIndex] = {...res} 
            setComments(allComments)
        }))
    }

    const deleteComment = (commentId, loading, onRes) => {
        dispatch(deleteReportCommentAction(profile?.company, commentId, loading, dispatch, (res) => {
            if(res.success){
                const allComments = [...comments]
                const updatedComments = allComments.filter((item) => item.id !== commentId);
                setComments(updatedComments)
            }
            onRes(res)
        }))
    }

    return {
        propertyData,
        setPropertyData,
        loading,
        onSubmit,
        navigate,
        isPropertyModal,
        setIsPropertyModal,
        onConfirm,
        selectedProperty,
        setSelectedProperty,
        isAddDamage,
        setIsAddDamage,
        allDamages,
        setAllDamages,
        // handleDeleteDamage,
        handleEditDamage,
        // deleteDamageModal,
        // setDeleteDamageModal,
        damagesheader,
        reportId: params?.id,
        handleProcessDamage,
        updateDamageReport,
        comments,
        addComment,
        updateComment,
        deleteComment
    }
}

export default DamageContainer